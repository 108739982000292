import { useQuery } from "@tanstack/react-query"

import { Property } from "@/models/property_models";

import { get } from '@/services/api_service';
import { getTokens } from '@/services/auth_service';
import { basePropertyUrl } from '@/services/property_service';

import { useDatabaseStore } from "@/state/database_store";
import { useConfigStore } from "@/state/config_store";



// Note: This are polyfills for AbortSignal timeout and any until happy-dom supports them.
// TODO: remove this once happy-dom supports AbortSignal.timeout and AbortSignal.any
// see PR's https://github.com/capricorn86/happy-dom/pull/1471
// and https://github.com/capricorn86/happy-dom/pull/1469
if (!AbortSignal.timeout) {
  AbortSignal.timeout = (ms) => {
    const controller = new AbortController();
    setTimeout(() => controller.abort(new DOMException("TimeoutError")), ms);
    return controller.signal;
  };
}

if (!AbortSignal.any) {
  AbortSignal.any = (signals: AbortSignal[]): AbortSignal => {
    // if any of the signals is already aborted, return an aborted signal
    for (const signal of signals) {
      if (signal.aborted) {
        return AbortSignal.abort(signal.reason);
      }
    }

    // N.B. this is slightly different from the PR to happy-dom (which didn't work) - but behaviour should be in line with the spec
    // see https://developer.mozilla.org/en-US/docs/Web/API/AbortSignal/abort_event
    const anyController = new AbortController();
    const anySignal = anyController.signal;
    const handlers = new Map<AbortSignal, () => void>();
  
    const stopListening = (): void => {
      for (const signal of signals) {
        // @ts-ignore: typing of handlers.get(signal) is incorrect
        signal.removeEventListener('abort', handlers.get(signal));
      }
    };
  
    for (const signal of signals) {
      const handler = (): void => {
        stopListening();
        anyController.abort(signal.reason);
      };
      handlers.set(signal, handler);
      signal.addEventListener('abort', handler);
    }
  
    return anySignal;
  }
}


export function useProperties() {
  const setProperties = useDatabaseStore((state) => state.setProperties)
  const propertiesQueryTimeout = useConfigStore((state) => state.queryTimeouts.properties)

  return useQuery({
    // TODO: constants for query keys
    queryKey: ['properties'],
    queryFn: ({ signal: querySignal }) => new Promise<Array<Property>>((resolve, reject) => {
      const timeoutSignal = AbortSignal.timeout(propertiesQueryTimeout)
      // allow the request to be cancelled by the query signal or the timeout signal (may be cancelled by query signal if the calling component unmounts)
      // see https://tanstack.com/query/latest/docs/framework/react/guides/query-cancellation#manual-cancellation
      const allSignals = AbortSignal.any([querySignal, timeoutSignal])

      get(`${basePropertyUrl}/`, getTokens().token, { signal: allSignals })
      .then((result) => {
        // TODO: for now, also setting properties in zustand to facilitate other app flows
        const propertiesAsClass: Property[] = []
        result.properties.forEach((p: any) => {
          const property = Property.fromJSON(p)
          if (property) propertiesAsClass.push(property)
        });
        setProperties(propertiesAsClass)
        resolve(result.properties)
      })
      .catch(err => {
        reject(err)
      })
    }),
  })
}