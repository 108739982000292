import { fertiliserOptions } from "@/models/form_fertiliser_lookup"
import { FertiliserUse } from "@/models/report"
import { trimDecimalPlaces } from "./numbers"

export function calculateActiveIngredients(fertilisers: FertiliserUse[]) {
  let nonUreaNitrogen = 0
  let phosphorus = 0
  let potassium = 0
  let sulfur = 0
  fertilisers.forEach((f) => {
    const fert = fertiliserOptions.find(fo => fo.id === f.id)
    if (fert) {
      nonUreaNitrogen = trimDecimalPlaces(nonUreaNitrogen + (fert.nonUreaNitrogen * f.rateApplied))
      phosphorus = trimDecimalPlaces(phosphorus + (fert.pPercent * f.rateApplied))
      potassium = trimDecimalPlaces(potassium + (fert.kPercent * f.rateApplied))
      sulfur = trimDecimalPlaces(sulfur + (fert.sPercent * f.rateApplied))
    }
  })
  return {
    nonUreaNitrogen,
    phosphorus,
    potassium,
    sulfur
  }
}