import { PropertyType } from "../models/property_models";
import { ruminatiColors } from "../utilities/colors";
import styled from "styled-components";
import { BaseText } from "./styled_text";

export type EnterpriseTypeTagProps = {
    text: string;
    color: string;
    marginRight?: string
};

export type EnterpriseTypeTagSize = "small" | "large";

export default function EnterpriseTypeTag({ data, size }: { data: EnterpriseTypeTagProps, size: EnterpriseTypeTagSize }) {
    return <div style={{
        margin: `0 0 0 ${data.marginRight ?? '12px'}`,
        padding: size === "large" ? "2.5px 8px" : "3px 7px",
        borderRadius: "4px",
        border: size === "large" ? `1.5px solid ${data.color}` : `1px solid ${data.color}`,
        backgroundColor: `${data.color}`,
        color: ruminatiColors.bone,
        height: "fit-content"
    }}>
        {size === "large" ? <LargeTagText>{data.text}</LargeTagText> : <SmallTagText>{data.text}</SmallTagText>}
    </div>;
}

export function getEnterpriseTypeTag(type: PropertyType, size: EnterpriseTypeTagSize, marginRight?: string) {
    let text = "";
    let color = ruminatiColors.data_green;
    switch (type) {
        case PropertyType.Farm:
            text = "Producer";
            color = ruminatiColors.data_green;
            break;
        case PropertyType.Dairy:
            text = "Dairy";
            color = ruminatiColors.data_green;
            break;
        case PropertyType.Feedlot:
            text = "Feedlot";
            color = ruminatiColors.blue;
            break;
        case PropertyType.Processor:
        case PropertyType.FurtherProcessor:
            text = "Processor";
            color = ruminatiColors.data_yellow;
            break;
    }
    return <EnterpriseTypeTag data={{ text, color, marginRight }} size={size} />;
}

const SmallTagText = styled(BaseText)`
    font-family: "Approach", sans-serif;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    `;

const LargeTagText = styled(SmallTagText)`
    font-size: 16px;
    line-height: 20px;
`;