import { usePopupStore } from "@/state/popup_store"
import { FertiliserUserPopupId } from "../popup/fertiliser-popup"
import { FertiliserUse, ReportGrains_Post3_3Form } from "@/models/report"
import { State } from "@/models/form/form_fields/form_field"
import SmallButton from "./small_button"
import Icon from "../icon"
import { ruminatiColors } from "@/utilities/colors"
import { calculateActiveIngredients } from "@/utilities/fertilisers"

export default function FertiliserCalculator({
  state
}: {
  state: State<ReportGrains_Post3_3Form>
}) {
  const popupStore = usePopupStore()

  const fertCount = state.get.fertilisers?.length ?? 0

  return <SmallButton
    colorScheme='green'
    onClick={() => {
      popupStore.addPopup(
        FertiliserUserPopupId,
        (fertilisers: FertiliserUse[]) => {
          const activeIngredients = calculateActiveIngredients(fertilisers)
          state.set({
                ...state.get,
              fertilisers: fertilisers,
              activeIngredients
          })
        },
        {
          fertilisers: state.get.fertilisers
        }
      )
    }}
  >
    <span style={{marginRight: '8px'}}>
      <Icon icon="plus-small" fillColor={ruminatiColors.bone}/>
    </span>
    {fertCount > 0 ? 'Edit Fertilisers' : 'Add Fertilisers'}
  </SmallButton>
}