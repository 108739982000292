import { MultiPolygon, Position } from "geojson";
import { LivestockClassAUS, LivestockCattleMiscAUS, LivestockSheepMiscAUS, ReportCattlePurchases, ReportConsumables, ReportFeedlotCohorts, ReportGrains, ReportLivestock, ReportLivestockNZ, ReportMethane, ReportPasture, ReportProcessorProduction, ReportProduction, ReportRations, ReportRefrigerants, PurchasedGoodsTonnesCO2E, ReportSheepPurchases, ReportSupplmentaryFeed, ReportTrees, ReportMineralSupplements, ReportEffluentManagement, ReportProcessorWaterUse, ReportGrains_Post3_3, ReportDairyTransport, ReportManureManagement, ReportDairyLivestock, ReportDairyProductionSystemInputsInformation, ReportDairyProduction  } from "./report";
import { LOOCExistingSoilResponse } from './looc'
import { CountryCode } from "../utilities/countries";
import { EmissionsGas, ScopeData } from "../screens/report/detailed_tab";
import { formatReportNumber } from "../utilities/functions";
import { calculateSumOfArray } from "../utilities/maths";

export enum PropertyType {
    Farm = "farm",
    Feedlot = "feedlot",
    Processor = "processor",
    FurtherProcessor = "further-processor",
    Dairy = "dairy"
}

export type FeedlotSystemType = "drylot" | "solid-storage" | "composting" | "anaerobic-lagoon";
export type DairyProductionSystemType = "irrigated-pasture" | "non-irrigated-pasture" | "irrigated-crop" | "non-irrigated-crop"

export type CreatePropertyDTO = {
    name: string;
    propertyType: PropertyType;
    geom: MultiPolygon;
    pic?: string;
    country?: CountryCode;
}

export type CreateFarmDTO = CreatePropertyDTO & {
    propertyType: PropertyType.Farm,
    cattleFarm: boolean,
    sheepFarm: boolean,
    grainFarm: boolean,
    agriwebbFarmId?: string,
    ngr?: string,
}

export type CreateFeedlotDTO = CreatePropertyDTO & {
    propertyType: PropertyType.Feedlot,
    productionSystem: FeedlotSystemType,
}

export type CreateProcessorDTO = CreatePropertyDTO & {
    propertyType: PropertyType.Processor | PropertyType.FurtherProcessor,
    beefProcessor: boolean,
    sheepProcessor: boolean,
}

export interface CreateDairyDTO extends CreatePropertyDTO {
    dairyProductionSystem: DairyProductionSystemType
}

export class Property {
    id: string;
    name: string;
    propertyType: PropertyType;
    geom: MultiPolygon;
    centroid: Position;
    area: number;
    rainfall: number;
    cattleFarm: boolean;
    grainFarm: boolean;
    sheepFarm: boolean;
    stateId: number;
    region: number;
    pic: string | undefined;
    ngr: string | undefined;
    agriwebbFarmId: string | undefined;
    soilCarbonData: LOOCExistingSoilResponse | undefined;
    productionSystem: FeedlotSystemType | undefined;
    dairyProductionSystem: DairyProductionSystemType | undefined
    beefProcessor: boolean;
    sheepProcessor: boolean;
    country: CountryCode;
    _farmingType: string[];

    constructor(
        id: string,
        name: string,
        propertyType: PropertyType,
        geom: MultiPolygon,
        centroid: Position,
        area: number,
        rainfall: number,
        cattleFarm: boolean,
        grainFarm: boolean,
        sheepFarm: boolean,
        stateId: number,
        region: number,
        pic: string | null,
        ngr: string | null,
        agriwebbFarmId: string | null,
        soilCarbonData: LOOCExistingSoilResponse | null,
        productionSystem: FeedlotSystemType | undefined,
        dairyProductionSystem: DairyProductionSystemType | undefined,
        beefProcessor: boolean,
        sheepProcessor: boolean,
        country: CountryCode,
    ) {
        this.id = id;
        this.name = name;
        this.propertyType = propertyType;
        this.geom = geom;
        this.centroid = centroid;
        this.area = area;
        this.rainfall = rainfall;
        this.cattleFarm = cattleFarm;
        this.grainFarm = grainFarm;
        this.sheepFarm = sheepFarm;
        this.stateId = stateId;
        this.region = region;
        this.pic = pic ?? undefined;
        this.ngr = ngr ?? undefined;
        this.agriwebbFarmId = agriwebbFarmId ?? undefined;
        this.soilCarbonData = soilCarbonData ?? undefined;
        this.productionSystem = productionSystem ?? undefined;
        this.dairyProductionSystem = dairyProductionSystem ?? undefined
        this.beefProcessor = beefProcessor;
        this.sheepProcessor = sheepProcessor;
        this.country = country
        this._farmingType = [];
        this.country = country;
    }

    public static fromJSON(json: any) {
        if (!json || !("id" in json)) return null;

        return new Property(
            json.id,
            json.name ?? "",
            json.propertyType ?? PropertyType.Farm,
            json.geom ?? {},
            json.centroid ?? [],
            json.area ?? -1,
            json.rainfall ?? -1,
            !!json.cattleFarm,
            !!json.grainFarm,
            !!json.sheepFarm,
            json.stateId,
            json.region,
            json.pic ? json.pic : null,
            json.ngr ? json.ngr : null,
            json.agriwebbFarmId ? json.agriwebbFarmId : null,
            json.soilCarbonData ? json.soilCarbonData : null,
            json.productionSystem ? json.productionSystem : null,
            json.dairyProductionSystem ? json.dairyProductionSystem : null,
            !!json.beefProcessor,
            !!json.sheepProcessor,
            json.country,
        );
    }

    // This patches in a farmingType property so the farm
    // can be edited using the same form
    // Note the array can't be regenerated each time hence or the form
    // gets confused, hence the slightly complicated logic
    get farmingType(): string[] {
        if (this.grainFarm && this._farmingType.indexOf("grain") === -1)
            this._farmingType.push("grain");
        if (this.sheepFarm && this._farmingType.indexOf("sheep") === -1)
            this._farmingType.push("sheep");
        if (this.cattleFarm && this._farmingType.indexOf("cattle") === -1)
            this._farmingType.push("cattle");
        return this._farmingType;
    }
}

export interface ReportResults {
    totals: {
        total: number;
        sequestration: number
        net: number
    };
    sequestration: {
        trees: number;
        soil: number;
    };
    byScopes: {
        scope1: number;
        scope2: number;
        scope3: number;
    };
    scope1ByGas: {
        carbonDioxide: number;
        methane: number;
        nitrousOxide: number;
    };
    emissions: {
        sheepBeef?: number;
        grains?: number;
        shared?: number;
    };
    intensity: {
        netPerDSE?: number;
        kgCo2PerKgBeefMeatSold?: number | null;
        kgCo2PerKgGrainProduced?: number | null;
        kgCo2PerKgGreasyWool?: number | null;
        kgCo2PerKgSheepMeatSold?: number | null;
        netEmissionsBeef?: number;
        netEmissionsSheep?: number;
        netGrainsEmissions?: number;
        
        // Feedlot related
        kgCo2PerKgMeatFed?: number | null;
        kgCo2PerKgMeatFedScope1and2?: number | null;
        netFeedlotEmissions?: number

        // Processor related
        kgCO2ePerTonneHCSWScope1and2?: number
        kgCO2ePerTonneHCSW?: number
        kgCO2ePerTonneByProductScope1and2?: number
        kgCO2ePerTonneByProduct?: number
        energyUseMJPerTonneHSCW?: number
        netProcessorEmissions?: number

        // Dairy related
        kgCo2PerKgMilkFPCM?: number
        kgCo2PerKgMilk?: number
        kgCo2PerKgDairyMeat?: number
        netDairyMilkEmissions?: number
        netDairyMeatEmissions?: number
    }
}

type Scope1OutputAus = {
    lime: number
    urea: number
    entericMethaneSheep: number
    entericMethaneBeef: number
    manureMethane: number
    savannahBurningCh4: number
    fertiliser: number
    urineDung: number
    atmosphericDeposition: number
    leachingRunoff: number
    savannahBurningN2O: number
}

type SheepBeefScope3Output = {
    fertiliser: number
    purchasedMineralSupplementation?: number
    purchasedFeed: number
    pesticides: number
    lime: number
    purchasedLivestock?: number
    purchasedLivestockSheep?: number
    purchasedLivestockBeef?: number
    livestockOnAgistment: number
}

type SbGAFAlgorithmOutput = {
    scope1: Scope1OutputAus,
    scope2: Record<string, number>,
    scope3: SheepBeefScope3Output
}

type DairyScope1OutputAus = {
    lime: number
    urea: number
    entericFermentation: number
    manureManagementCh4: number
    manureManagementN20: number
    animalWaste: number
    fertiliser: number
    urineDung: number
    atmosphericDeposition: number
    leachingRunoff: number
    fuelCo2: number
    transportCo2: number
    fuelCh4: number
    transportCh4: number
    fuelN2O: number
    transportN2O: number
}

type DairyScope3OutputAus = {
    fuel: number
    fertiliser: number
    purchasedFeed: number
    pesticides: number
    lime: number
    electricity: number
}

export type DairyAlgorithmOutput = {
    scope1: DairyScope1OutputAus
    scope2: {
        electricity: number
    },
    scope3: DairyScope3OutputAus
}

type FeedlotScope1Output = {
    lime: number
    urea: number
    entericMethaneBeef: number
    manureMethane: number
    manureMethaneN20Direct: number
    manureMethaneN20InDirect: number
    manureAppliedToSoil: number
    atmosphericDeposition: number
    fuelCo2: number
    transportCo2: number
    fuelCh4: number
    transportCh4: number
    fuelN2O: number
    transportN2O: number
}

type FeedlotScope3Output = {
    fuel: number
    fertiliser: number
    purchasedFeed: number
    pesticides: number
    lime: number
    purchasedLivestock: number
    electricity: number
}

export type FeedlotAlgorithmOutput = {
    scope1: FeedlotScope1Output,
    scope2: {
    electricity: number
    },
    scope3: FeedlotScope3Output 
}

type Scope1OutputNZ = {
    lime: number
    urea: number
    entericMethaneSheep: number
    entericMethaneBeef: number
    manureMethane: number
    fertiliser: number
    agSoils: number
}

type AlgorithmOutputNZ = {
    scope1: Scope1OutputNZ,
    scope2: Record<string, number>,
    scope3: SheepBeefScope3Output
}

type LabelAndGas = {
    label: string
    gas: EmissionsGas
}

const scope1LabelsGasLookup: {
    [Property in (keyof Scope1OutputAus | keyof Scope1OutputNZ | keyof SharedSbGAFScope1 | keyof CropResultScope1 | keyof DairyScope1OutputAus | keyof FeedlotScope1Output)]: LabelAndGas
} = {
    lime: { label: 'Lime', gas: EmissionsGas.CO2 },
    dieselN2O: { label: 'Diesel', gas: EmissionsGas.N20 },
    petrolN2O: { label: 'Petrol', gas: EmissionsGas.N20 },
    dieselCh4: { label: 'Diesel', gas: EmissionsGas.CH4 },
    petrolCh4: { label: 'Petrol', gas: EmissionsGas.CH4 },
    dieselCO2: { label: 'Diesel', gas: EmissionsGas.CO2 },
    petrolCO2: { label: 'Petrol', gas: EmissionsGas.CO2 },
    urea: { label: 'Urea', gas: EmissionsGas.CO2 },
    entericMethaneSheep: { label: 'Enteric Methane - Sheep', gas: EmissionsGas.CH4 },
    entericMethaneBeef: { label: 'Enteric Methane - Cattle', gas: EmissionsGas.CH4 },
    manureMethane: { label: 'Methane in Manure', gas: EmissionsGas.CH4 },
    savannahBurningCh4: { label: 'Savannah burning', gas: EmissionsGas.CH4 },
    savannahBurningN2O: { label: 'Savannah burning', gas: EmissionsGas.N20 },
    fertiliser: { label: 'Fertiliser', gas: EmissionsGas.N20 },
    urineDung: { label: 'Urine & dung', gas: EmissionsGas.N20 },
    atmosphericDeposition: { label: 'Atmospheric deposition', gas: EmissionsGas.N20 },
    leachingRunoff: { label: 'Leaching and runoff', gas: EmissionsGas.N20 },

    // NZ
    agSoils: { label: 'Ag Soils', gas: EmissionsGas.N20 },

    // Crops
    fieldBurningCh4: { label: 'Field burning', gas: EmissionsGas.CH4 },
    fieldBurningN2O: { label: 'Field burning', gas: EmissionsGas.N20 },
    residuesN2O: { label: 'Crop residues', gas: EmissionsGas.N20 },

    // Dairy
    entericFermentation: { label: 'Enteric Fermentation', gas: EmissionsGas.CH4 },
    manureManagementCh4: { label: 'Manure Management', gas: EmissionsGas.CH4 },
    manureManagementN20: { label: 'Manure Management', gas: EmissionsGas.N20 },
    animalWaste: { label: 'Animal Waste', gas: EmissionsGas.N20 },
    transportCo2: { label: 'Transport', gas: EmissionsGas.CO2 },
    transportCh4: { label: 'Transport', gas: EmissionsGas.CH4 },
    transportN2O: { label: 'Transport', gas: EmissionsGas.N20 },
    fuelCo2: { label: 'Fuel', gas: EmissionsGas.CO2 },
    fuelCh4: { label: 'Fuel', gas: EmissionsGas.CH4 },
    fuelN2O: { label: 'Fuel', gas: EmissionsGas.N20 },

    // Feedlot
    manureMethaneN20Direct: { label: 'Manure (Direct)', gas: EmissionsGas.N20 },
    manureMethaneN20InDirect: { label: 'Manure (Indirect)', gas: EmissionsGas.N20 },
    manureAppliedToSoil: { label: 'Manure applied to soil', gas: EmissionsGas.N20 }
}

const scope3LabelsLookup: {
    [Property in (keyof SheepBeefScope3Output | keyof SharedSbGAFScope3 | keyof CropResultScope3 | keyof DairyScope3OutputAus | keyof FeedlotScope3Output)]: string
} = {
    fertiliser: 'Fertiliser',
    purchasedMineralSupplementation: 'Purchased Mineral Supplementation',
    purchasedFeed: 'Purchased Feed',
    pesticides: 'Pesticides',
    lime: 'Lime',
    purchasedLivestock: 'Purchased Livestock',
    purchasedLivestockSheep: 'Purchased Livestock - Sheep',
    purchasedLivestockBeef: 'Purchased Livestock - Cattle',
    livestockOnAgistment: 'Livestock On Agistment',
    diesel: 'Diesel',
    petrol: 'Petrol',
    electricity: 'Electricity',
    fuel: 'Fuel'
}

export enum EmissionsCategory {
    Electricity = 'Electricity',
    Fuels = 'Fuels',
    Livestock = 'Livestock',
    Fertilisers = 'Fertilisers',
    Other = 'Other'
}

const scopesEmissionsCategoryLookup: {
    scope1: {
        [Property in (
            keyof Scope1OutputAus |
            keyof Scope1OutputNZ |
            keyof SharedSbGAFScope1 |
            keyof CropResultScope1 | 
            keyof DairyScope1OutputAus | 
            keyof FeedlotScope1Output
        )]: EmissionsCategory
    },
    scope2: {
        electricity: EmissionsCategory
    },
    scope3: {
        [Property in (
            keyof SheepBeefScope3Output |
            keyof SharedSbGAFScope3 |
            keyof CropResultScope3 | 
            keyof DairyScope3OutputAus |
            keyof FeedlotScope3Output
        )]: EmissionsCategory
    }
} = {
    scope1: {
        lime: EmissionsCategory.Fertilisers,
        dieselN2O: EmissionsCategory.Fuels,
        petrolN2O: EmissionsCategory.Fuels,
        dieselCh4: EmissionsCategory.Fuels,
        petrolCh4: EmissionsCategory.Fuels,
        dieselCO2: EmissionsCategory.Fuels,
        petrolCO2: EmissionsCategory.Fuels,
        urea: EmissionsCategory.Fertilisers,
        entericMethaneSheep: EmissionsCategory.Livestock,
        entericMethaneBeef: EmissionsCategory.Livestock,
        manureMethane: EmissionsCategory.Livestock,
        savannahBurningCh4: EmissionsCategory.Other,
        savannahBurningN2O: EmissionsCategory.Other,
        fertiliser: EmissionsCategory.Fertilisers,
        urineDung: EmissionsCategory.Livestock,
        atmosphericDeposition: EmissionsCategory.Fertilisers,
        leachingRunoff: EmissionsCategory.Fertilisers,

        // NZ
        agSoils: EmissionsCategory.Other,

        // Crops
        fieldBurningCh4: EmissionsCategory.Other,
        fieldBurningN2O: EmissionsCategory.Other,
        residuesN2O: EmissionsCategory.Fertilisers,

        // Dairy
        entericFermentation: EmissionsCategory.Livestock,
        manureManagementCh4: EmissionsCategory.Livestock,
        manureManagementN20: EmissionsCategory.Livestock,
        animalWaste: EmissionsCategory.Livestock,
        transportCo2: EmissionsCategory.Fuels,
        transportCh4: EmissionsCategory.Fuels,
        transportN2O: EmissionsCategory.Fuels,
        fuelCo2: EmissionsCategory.Fuels,
        fuelCh4: EmissionsCategory.Fuels,
        fuelN2O: EmissionsCategory.Fuels,

        // Feedlot
        manureMethaneN20Direct: EmissionsCategory.Livestock,
        manureMethaneN20InDirect: EmissionsCategory.Livestock,
        manureAppliedToSoil: EmissionsCategory.Livestock
    },
    scope2: {
        electricity: EmissionsCategory.Electricity
    },
    scope3: {
        fertiliser: EmissionsCategory.Fertilisers,
        purchasedMineralSupplementation: EmissionsCategory.Other,
        purchasedFeed: EmissionsCategory.Other,
        pesticides: EmissionsCategory.Fertilisers,
        lime: EmissionsCategory.Fertilisers,
        purchasedLivestock: EmissionsCategory.Livestock,
        purchasedLivestockSheep: EmissionsCategory.Livestock,
        purchasedLivestockBeef: EmissionsCategory.Livestock,
        livestockOnAgistment: EmissionsCategory.Livestock,
        diesel: EmissionsCategory.Fuels,
        petrol: EmissionsCategory.Fuels,
        electricity: EmissionsCategory.Electricity,
        // Dairy
        fuel: EmissionsCategory.Fuels,
    }
}

export type FullSheepOrBeefResults = (SbGAFAlgorithmOutput | AlgorithmOutputNZ) & SharedSBGrainsAlgorithmOutput

export type SheepOrBeefProductSummary = {
    totalEmissions: number
    // intensity?: number
    breakdown: FullSheepOrBeefResults
}

export type DairyProductSummary = {
    totalEmissions: number
    breakdown: DairyAlgorithmOutput
}

export type FeedlotBeefProductSummary = {
    totalEmissions: number
    breakdown: FeedlotAlgorithmOutput
}

type SharedSbGAFScope1 = {
    dieselN2O: number,
    petrolN2O: number,
    dieselCh4: number,
    petrolCh4: number,
    dieselCO2: number,
    petrolCO2: number
}

type SharedSbGAFScope3 = {
    diesel: number,
    petrol: number,
    electricity: number
}

export type SharedSBGrainsAlgorithmOutput = {
    scope1: SharedSbGAFScope1,
    scope2: {
        electricity: number,
    },
    scope3: SharedSbGAFScope3
}

type CropResultScope1 = {
    lime: number
    urea: number
    fieldBurningCh4: number
    fertiliser: number
    atmosphericDeposition: number
    fieldBurningN2O: number
    residuesN2O: number
    leachingRunoff: number
}

type CropResultScope3 = {
    fertiliser: number
    pesticides: number
    lime: number
}

export type CropResult = {
    scope1: CropResultScope1,
    scope2: {
        electricity: number,
    },
    scope3: CropResultScope3
}

export type FullGrainsResults = CropResult & SharedSBGrainsAlgorithmOutput

export type GrainProductSummary = {
    grainRecordStableId: string
    totalEmissions: number
    intensity: number
    breakdown: FullGrainsResults
}

export type AllocatedSummary = {
    sheep?: SheepOrBeefProductSummary
    beef?: SheepOrBeefProductSummary
    grains?: GrainProductSummary[]
    dairyMilk?: DairyProductSummary 
    dairyMeat?: DairyProductSummary
    feedlotBeef?: FeedlotBeefProductSummary
}

export function pluckScope1s(data: FullSheepOrBeefResults | FullGrainsResults | DairyAlgorithmOutput | FeedlotAlgorithmOutput): ScopeData[] {
    const out: ScopeData[] = []
    for (const key in data.scope1) {
        const typedKey = key as keyof (Scope1OutputAus | Scope1OutputNZ | CropResultScope1 | DairyScope1OutputAus | FeedlotScope1Output)
        const labelAndGas: LabelAndGas | undefined = scope1LabelsGasLookup[typedKey]
        if (labelAndGas) {
            out.push({
                label: labelAndGas.label,
                value: formatReportNumber(data.scope1[typedKey]),
                gas: labelAndGas.gas
            })
        }
    }
    return out
}

export function pluckScope2(data: FullGrainsResults | FullSheepOrBeefResults | DairyAlgorithmOutput | FeedlotAlgorithmOutput): ScopeData[] {
    return [
        { label: 'Electricity', value: formatReportNumber(data.scope2.electricity) }
    ]
}

export function pluckScope3s(data: FullSheepOrBeefResults | FullGrainsResults | DairyAlgorithmOutput | FeedlotAlgorithmOutput): ScopeData[] {
    const out: ScopeData[] = []
    for (const key in data.scope3) {
        const typedKey = key as keyof (SheepBeefScope3Output | SharedSbGAFScope3 | CropResultScope3 | DairyScope3OutputAus | FeedlotScope3Output)
        out.push({
            label: scope3LabelsLookup[typedKey],
            value: formatReportNumber(data.scope3[typedKey])
        })
    }
    return out
}

export function getCategoryTotals(data: AllocatedSummary, category: EmissionsCategory): number {
    const contributors: number[] = []
    function processResults(results: FullSheepOrBeefResults | FullGrainsResults | DairyAlgorithmOutput | FeedlotAlgorithmOutput) {
        for (const key in results.scope1) {
            const typedKey = key as keyof (Scope1OutputAus | Scope1OutputNZ | CropResultScope1 | FeedlotScope1Output)
            const dataCategory: EmissionsCategory | undefined = scopesEmissionsCategoryLookup.scope1[typedKey]
            if (dataCategory === category) {
                contributors.push(results.scope1[typedKey])
            }
        }
        if (category === EmissionsCategory.Electricity) contributors.push(results.scope2.electricity)
        for (const key in results.scope3) {
            const typedKey = key as keyof (SheepBeefScope3Output | SharedSbGAFScope3 | CropResultScope3 | FeedlotScope3Output)
            const dataCategory: EmissionsCategory | undefined = scopesEmissionsCategoryLookup.scope3[typedKey]
            if (dataCategory === category) {
                contributors.push(results.scope3[typedKey])
            }
        }
    }

    for (const resultKey in data) {
        const typedResultKey = resultKey as keyof AllocatedSummary
        const results = data[typedResultKey]
        // Grains will be an array
        if (Array.isArray(results)) results.forEach(r => processResults(r.breakdown))
        else if (results !== undefined) processResults(results.breakdown)
    }

    return calculateSumOfArray(contributors)
}


export class Report {
    id: string;
    propertyId: string;
    financialYear: number;
    algorithmVersion: number;
    results: ReportResults | undefined;
    resultsByProduct: AllocatedSummary | undefined

    complete: boolean;
    resultsReadyForGeneration: boolean;
    completedSections: string[];
    completionDate: number | undefined;
    createdDate: number;

    // Farm data
    consumablesInformation: ReportConsumables | undefined;
    grainInformation: ReportGrains[] | undefined;
    grainInformationPostV3_3: ReportGrains_Post3_3[] | undefined;

    livestockInformation: ReportLivestock | undefined; // Aus user.

    livestockInformationCattleClassAus: LivestockClassAUS[] | undefined // V3 reports
    livestockInformationCattleMiscAus: LivestockCattleMiscAUS | undefined // V3 reports

    livestockInformationSheepClassAus: LivestockClassAUS[] | undefined // V3 reports
    livestockInformationSheepMiscAus: LivestockSheepMiscAUS | undefined // V3 reports

    livestockInformationCattleNZ: ReportLivestockNZ | undefined; // NZ user.
    livestockInformationSheepNZ: ReportLivestockNZ | undefined; // NZ user.

    pastureInformation: ReportPasture | undefined;
    sheepPurchasesInformation: ReportSheepPurchases[] | undefined;
    supplementaryFeedInformation: ReportSupplmentaryFeed | undefined;
    cattlePurchasesInformation: ReportCattlePurchases[];
    treesInformation: ReportTrees[];
    productionInformation: ReportProduction | undefined;

    // Feedlot data
    rationsInformation: ReportRations[] | undefined;
    feedlotCohortsInformation: ReportFeedlotCohorts[] | undefined;

    // Processor Properties
    refrigerantsInformation: ReportRefrigerants | undefined;
    methaneInformation: ReportMethane | undefined;
    processorProductionInformation: ReportProcessorProduction | undefined;
    purchasedGoodsTonnesCO2EInformation: PurchasedGoodsTonnesCO2E | undefined;
    mineralSupplementsInformation: ReportMineralSupplements[] | undefined;
    effluentManagementInformation: ReportEffluentManagement | undefined;
    processorWaterUseInformation: ReportProcessorWaterUse | undefined;

    // Dairy Properties
    dairyTransportInformation?: ReportDairyTransport[]
    dairyManureManagementInformation?: ReportManureManagement
    dairyCattleClassInformation?: ReportDairyLivestock[]
    dairyProductionSystemInputsInformation?: ReportDairyProductionSystemInputsInformation
    dairyProductionInformation?: ReportDairyProduction | undefined

    constructor(
        id: string,
        propertyId: string,
        financialYear: number,
        algorithmVersion: number,
        complete: boolean,
        resultsReadyForGeneration: boolean,
        completedSections: string[],
        completionDate: number | undefined,
        createdDate: number,
        results: ReportResults | undefined,
        resultsByProduct: AllocatedSummary | undefined,
        consumablesInformation: ReportConsumables | undefined,
        grainInformation: ReportGrains[] | undefined,
        grainInformationPostV3_3: ReportGrains_Post3_3[] | undefined,

        livestockInformation: ReportLivestock | undefined,
        livestockInformationCattleClassAus: LivestockClassAUS[] | undefined, // V3 reports
        livestockInformationCattleMiscAus: LivestockCattleMiscAUS | undefined, // V3 reports
        livestockInformationSheepClassAus: LivestockClassAUS[] | undefined, // V3 reports
        livestockInformationSheepMiscAus: LivestockSheepMiscAUS | undefined, // V3 reports

        livestockInformationCattleNZ: ReportLivestockNZ | undefined, // NZ user
        livestockInformationSheepNZ: ReportLivestockNZ | undefined, // NZ user

        pastureInformation: ReportPasture | undefined,
        sheepPurchasesInformation: ReportSheepPurchases[] | undefined,
        supplementaryFeedInformation: ReportSupplmentaryFeed | undefined,
        cattlePurchasesInformation: ReportCattlePurchases[],
        treesInformation: ReportTrees[],
        productionInformation: ReportProduction | undefined,
        // Feedlot properties
        rationsInformation: ReportRations[] | undefined,
        feedlotCohortsInformation: ReportFeedlotCohorts[] | undefined,
        // Processor Properties
        refrigerantsInformation: ReportRefrigerants | undefined,
        purchasedGoodsTonnesCO2E: PurchasedGoodsTonnesCO2E | undefined,
        methaneInformation: ReportMethane | undefined,
        processorProductionInformation: ReportProcessorProduction | undefined,
        mineralSupplementsInformation: ReportMineralSupplements[] | undefined,
        effluentManagementInformation: ReportEffluentManagement | undefined,
        processorWaterUseInformation: ReportProcessorWaterUse | undefined,
        
        dairyTransportInformation?: ReportDairyTransport[] | undefined,
        dairyManureManagementInformation?: ReportManureManagement | undefined,
        dairyCattleClassInformation?: ReportDairyLivestock[] | undefined,
        dairyProductionSystemInputsInformation?: ReportDairyProductionSystemInputsInformation | undefined,
        dairyProductionInformation?: ReportDairyProduction | undefined,
    ) {
        this.id = id;
        this.propertyId = propertyId;
        this.financialYear = financialYear;
        this.algorithmVersion = algorithmVersion;

        this.complete = complete;
        this.resultsReadyForGeneration = resultsReadyForGeneration;
        this.completedSections = completedSections;
        this.completionDate = completionDate;
        this.createdDate = createdDate;

        this.results = results;
        this.resultsByProduct = resultsByProduct

        this.consumablesInformation = consumablesInformation;
        this.grainInformation = grainInformation;
        this.grainInformationPostV3_3 = grainInformationPostV3_3;

        this.livestockInformation = livestockInformation;
        this.livestockInformationCattleClassAus = livestockInformationCattleClassAus; // V3 reports
        this.livestockInformationCattleMiscAus = livestockInformationCattleMiscAus; // V3 reports
        this.livestockInformationSheepClassAus = livestockInformationSheepClassAus; // V3 reports
        this.livestockInformationSheepMiscAus = livestockInformationSheepMiscAus; // V3 reports

        this.livestockInformationCattleNZ = livestockInformationCattleNZ; // NZ user
        this.livestockInformationSheepNZ = livestockInformationSheepNZ; // NZ user
        this.pastureInformation = pastureInformation;
        this.sheepPurchasesInformation = sheepPurchasesInformation;
        this.supplementaryFeedInformation = supplementaryFeedInformation;
        this.cattlePurchasesInformation = cattlePurchasesInformation;
        this.treesInformation = treesInformation;
        this.productionInformation = productionInformation;

        // Feedlot properties
        this.rationsInformation = rationsInformation;
        this.feedlotCohortsInformation = feedlotCohortsInformation;

        // Processor properties
        this.refrigerantsInformation = refrigerantsInformation;
        this.purchasedGoodsTonnesCO2EInformation = purchasedGoodsTonnesCO2E;
        this.methaneInformation = methaneInformation;
        this.processorProductionInformation = processorProductionInformation;
        this.mineralSupplementsInformation = mineralSupplementsInformation;
        this.effluentManagementInformation = effluentManagementInformation;
        this.processorWaterUseInformation = processorWaterUseInformation;
        
        // Dairy properties
        this.dairyTransportInformation = dairyTransportInformation
        this.dairyManureManagementInformation = dairyManureManagementInformation
        this.dairyProductionSystemInputsInformation = dairyProductionSystemInputsInformation
        this.dairyCattleClassInformation = dairyCattleClassInformation
        this.dairyProductionInformation = dairyProductionInformation
    }

    public static fromJSON(json: any) {
        if (!json || !("id" in json)) return null;

        return new Report(
            json.id ?? "",
            json.propertyId ?? "",
            json.financialYear ?? -1,
            json.algorithmVersion ?? -1,
            !!json.complete,
            !!json.resultsReadyForGeneration,
            json.completedSections ?? [],
            json.completionDate ?? undefined,
            json.createdDate ?? undefined,
            json.results ?? undefined,
            json.resultsByProduct ?? undefined,
            json.consumablesInformation ?? undefined,
            json.grainInformation ?? undefined,
            json.grainInformationPostV3_3 ?? undefined,
            json.livestockInformation ?? undefined,
            json.livestockInformationCattleClassAus ?? undefined, // V3 reports
            json.livestockInformationCattleMiscAus ?? undefined, // V3 reports
            json.livestockInformationSheepClassAus ?? undefined, // V3 reports
            json.livestockInformationSheepMiscAus ?? undefined, // V3 reports
            json.livestockInformationCattleNZ ?? undefined, // NZ user 
            json.livestockInformationSheepNZ ?? undefined, // NZ user
            json.pastureInformation ?? undefined,
            json.sheepPurchasesInformation ?? undefined,
            json.supplementaryFeedInformation ?? undefined,
            json.cattlePurchasesInformation ?? [],
            json.treesInformation ?? [],
            json.productionInformation ?? undefined,
            json.rationsInformation ?? [],
            json.feedlotCohortsInformation ?? [],
            json.refrigerantsInformation ?? undefined,
            json.purchasedGoodsTonnesCO2EInformation ?? undefined,
            json.methaneInformation ?? undefined,
            json.processorProductionInformation ?? undefined,
            json.mineralSupplementsInformation ?? [],
            json.effluentManagementInformation ?? undefined,
            json.processorWaterUseInformation ?? undefined,
            
            json.dairyTransportInformation ?? [],
            json.dairyManureManagementInformation ?? undefined,
            json.dairyCattleClassInformation ?? [],
            json.dairyProductionSystemInputsInformation ?? undefined,
            json.dairyProductionInformation ?? undefined
        );
    }
}
