import { usePopupStore } from "../../state/popup_store";
import { ruminatiColors } from "../../utilities/colors";
import MainButton from "../buttons/main_button";
import { Row } from "../styled_layout";
import { BodyText } from "../styled_text";

export default function ConfirmationPopup({
    text,
    buttonText,
    onConfirm,
}: {
    text: string;
    buttonText: string;
    onConfirm?: () => void;
}) {
    const popupStore = usePopupStore();
    
    function addLineBreak (str: string) {
        return str.split('\n').map((subStr, idx) => {
          return (
            <span key={idx}>
              {subStr}
              <br />
            </span>
          )})
    }
      
    return (
        <div
            style={{
                width: "464px",
                padding: "20px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                gap: "20px",
            }}
        >
            <BodyText style={{
                color: ruminatiColors.green_3,
                textAlign: "center",
                letterSpacing: "0.28px",
                padding:"0 24px",
            }}>
                {addLineBreak(text)}
            </BodyText>
            <Row>
                <MainButton
                    size="small"
                    onClick={() => {
                        if (onConfirm) onConfirm();
                        popupStore.closePopup();
                    }}
                >
                    {buttonText}
                </MainButton>
            </Row>
        </div>
    );
}
