export const fertiliserOptions = [
  {
    "id": 1,
    "name": "42% Gran-Am 58% Urea",
    "unit": "T/ha",
    "totalNitrogenPercent": 0.35,
    "nitrogenControl": 0.38,
    "nonUreaNitrogen": 0.11,
    "nonUreaNitrogenPercent": 0.42,
    "urea": 0.27,
    "ureaPercent": 0.58,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0.1
  },
  {
    "id": 2,
    "name": "43% MAP TE 57% Urea",
    "unit": "T/ha",
    "totalNitrogenPercent": 0.31,
    "nitrogenControl": 0.31,
    "nonUreaNitrogen": 0.05,
    "nonUreaNitrogenPercent": 0.43,
    "urea": 0.26,
    "ureaPercent": 0.57,
    "pPercent": 0.09,
    "kPercent": 0,
    "sPercent": 0.01
  },
  {
    "id": 3,
    "name": "50% Granulock Z 50% MOP - Ameliorant",
    "unit": "T/ha",
    "totalNitrogenPercent": 0.06,
    "nitrogenControl": 0.06,
    "nonUreaNitrogen": 0.06,
    "nonUreaNitrogenPercent": 0.5,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.11,
    "kPercent": 0.25,
    "sPercent": 0.02
  },
  {
    "id": 4,
    "name": "50% Granulock Z 50% Muriate of Potash",
    "unit": "T/ha",
    "totalNitrogenPercent": 0.06,
    "nitrogenControl": 0.06,
    "nonUreaNitrogen": 0.06,
    "nonUreaNitrogenPercent": 0.58,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.11,
    "kPercent": 0.25,
    "sPercent": 0.02
  },
  {
    "id": 5,
    "name": "50% MAP 50% SOA",
    "unit": "T/ha",
    "totalNitrogenPercent": 0.16,
    "nitrogenControl": 0.16,
    "nonUreaNitrogen": 0.16,
    "nonUreaNitrogenPercent": 0.5,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.11,
    "kPercent": 0,
    "sPercent": 0.13
  },
  {
    "id": 6,
    "name": "50% MAP TE 50% Urea",
    "unit": "T/ha",
    "totalNitrogenPercent": 0.28,
    "nitrogenControl": 0.29,
    "nonUreaNitrogen": 0.06,
    "nonUreaNitrogenPercent": 0.5,
    "urea": 0.23,
    "ureaPercent": 0.5,
    "pPercent": 0.11,
    "kPercent": 0,
    "sPercent": 0.01
  },
  {
    "id": 7,
    "name": "50% Urea & 50% SOA",
    "unit": "T/ha",
    "totalNitrogenPercent": 0.34,
    "nitrogenControl": 0.34,
    "nonUreaNitrogen": 0.11,
    "nonUreaNitrogenPercent": 0.5,
    "urea": 0.23,
    "ureaPercent": 0.5,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0.12
  },
  {
    "id": 8,
    "name": "50% Urea 50% GranAm",
    "unit": "T/ha",
    "totalNitrogenPercent": 0.33,
    "nitrogenControl": 0.36,
    "nonUreaNitrogen": 0.13,
    "nonUreaNitrogenPercent": 0.5,
    "urea": 0.23,
    "ureaPercent": 0.5,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0.12
  },
  {
    "id": 9,
    "name": "50% Urea 50% MOP",
    "unit": "T/ha",
    "totalNitrogenPercent": 0.23,
    "nitrogenControl": 0.23,
    "nonUreaNitrogen": 0,
    "nonUreaNitrogenPercent": 0,
    "urea": 0.23,
    "ureaPercent": 0.5,
    "pPercent": 0,
    "kPercent": 0.25,
    "sPercent": 0
  },
  {
    "id": 10,
    "name": "60% MAP TE 40% Urea",
    "unit": "T/ha",
    "totalNitrogenPercent": 0.24,
    "nitrogenControl": 0.25,
    "nonUreaNitrogen": 0.07,
    "nonUreaNitrogenPercent": 0.6,
    "urea": 0.18,
    "ureaPercent": 0.4,
    "pPercent": 0.13,
    "kPercent": 0,
    "sPercent": 0.02
  },
  {
    "id": 11,
    "name": "60% Urea 40% SOA",
    "unit": "T/ha",
    "totalNitrogenPercent": 0.36,
    "nitrogenControl": 0.36,
    "nonUreaNitrogen": 0.08,
    "nonUreaNitrogenPercent": 0.4,
    "urea": 0.28,
    "ureaPercent": 0.6,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0.1
  },
  {
    "id": 12,
    "name": "63% UreaPlus 37% MOP",
    "unit": "T/ha",
    "totalNitrogenPercent": 0.29,
    "nitrogenControl": 0.29,
    "nonUreaNitrogen": 0,
    "nonUreaNitrogenPercent": 0,
    "urea": 0.29,
    "ureaPercent": 0.63,
    "pPercent": 0,
    "kPercent": 0.19,
    "sPercent": 0.06
  },
  {
    "id": 13,
    "name": "66% Urea 12% MAP 22% MOP",
    "unit": "T/ha",
    "totalNitrogenPercent": 0.32,
    "nitrogenControl": 0.32,
    "nonUreaNitrogen": 0.01,
    "nonUreaNitrogenPercent": 0.12,
    "urea": 0.3,
    "ureaPercent": 0.66,
    "pPercent": 0.03,
    "kPercent": 0.11,
    "sPercent": 0
  },
  {
    "id": 14,
    "name": "66% Urea 21% Maxam 13% MOP",
    "unit": "T/ha",
    "totalNitrogenPercent": 0.35,
    "nitrogenControl": 0.36,
    "nonUreaNitrogen": 0.05,
    "nonUreaNitrogenPercent": 0.21,
    "urea": 0.3,
    "ureaPercent": 0.66,
    "pPercent": 0,
    "kPercent": 0.07,
    "sPercent": 0.05
  },
  {
    "id": 15,
    "name": "67% MAPZ2 33% SOP",
    "unit": "Kg/ha",
    "totalNitrogenPercent": 0.07,
    "nitrogenControl": 0.07,
    "nonUreaNitrogen": 0.07,
    "nonUreaNitrogenPercent": 0.67,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.15,
    "kPercent": 0.14,
    "sPercent": 0.07
  },
  {
    "id": 16,
    "name": "70% MAP 30% MOP",
    "unit": "T/ha",
    "totalNitrogenPercent": 0.07,
    "nitrogenControl": 0.08,
    "nonUreaNitrogen": 0.08,
    "nonUreaNitrogenPercent": 0.7,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.15,
    "kPercent": 0.15,
    "sPercent": 0
  },
  {
    "id": 17,
    "name": "70% MAP TE 30% MOP",
    "unit": "T/ha",
    "totalNitrogenPercent": 0.08,
    "nitrogenControl": 0.08,
    "nonUreaNitrogen": 0.08,
    "nonUreaNitrogenPercent": 0.7,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.15,
    "kPercent": 0.15,
    "sPercent": 0.02
  },
  {
    "id": 18,
    "name": "70% Urea 30% SOA",
    "unit": "T/ha",
    "totalNitrogenPercent": 0.39,
    "nitrogenControl": 0.39,
    "nonUreaNitrogen": 0.06,
    "nonUreaNitrogenPercent": 0.3,
    "urea": 0.32,
    "ureaPercent": 0.7,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0.07
  },
  {
    "id": 19,
    "name": "73% UreaPlus 27% Muriate Of Potash",
    "unit": "T/ha",
    "totalNitrogenPercent": 0.34,
    "nitrogenControl": 0.34,
    "nonUreaNitrogen": 0,
    "nonUreaNitrogenPercent": 0,
    "urea": 0.34,
    "ureaPercent": 0.73,
    "pPercent": 0,
    "kPercent": 0.14,
    "sPercent": 0
  },
  {
    "id": 20,
    "name": "75% MAP 25% MOP",
    "unit": "T/ha",
    "totalNitrogenPercent": 0.08,
    "nitrogenControl": 0.08,
    "nonUreaNitrogen": 0.08,
    "nonUreaNitrogenPercent": 0.75,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.17,
    "kPercent": 0.13,
    "sPercent": 0
  },
  {
    "id": 21,
    "name": "75% Urea 25% MOP",
    "unit": "T/ha",
    "totalNitrogenPercent": 0.35,
    "nitrogenControl": 0.35,
    "nonUreaNitrogen": 0,
    "nonUreaNitrogenPercent": 0,
    "urea": 0.35,
    "ureaPercent": 0.75,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0
  },
  {
    "id": 22,
    "name": "80% Urea 20% MAP",
    "unit": "T/ha",
    "totalNitrogenPercent": 0.39,
    "nitrogenControl": 0.39,
    "nonUreaNitrogen": 0.02,
    "nonUreaNitrogenPercent": 0.2,
    "urea": 0.37,
    "ureaPercent": 0.8,
    "pPercent": 0.04,
    "kPercent": 0,
    "sPercent": 0
  },
  {
    "id": 23,
    "name": "Activate N",
    "unit": "T/ha",
    "totalNitrogenPercent": 0.18,
    "nitrogenControl": 0.18,
    "nonUreaNitrogen": 0.08,
    "nonUreaNitrogenPercent": 0.28,
    "urea": 0.1,
    "ureaPercent": 0.21,
    "pPercent": 0.21,
    "kPercent": 0,
    "sPercent": 0.05
  },
  {
    "id": 24,
    "name": "Activist Max Zinc",
    "unit": "L/ha",
    "totalNitrogenPercent": 0.07,
    "nitrogenControl": 0.09,
    "nonUreaNitrogen": 0.09,
    "nonUreaNitrogenPercent": 0.35,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0
  },
  {
    "id": 25,
    "name": "Agflow",
    "unit": "T/ha",
    "totalNitrogenPercent": 0.13,
    "nitrogenControl": 0.13,
    "nonUreaNitrogen": 0.13,
    "nonUreaNitrogenPercent": 0.38,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.17,
    "kPercent": 0,
    "sPercent": 0.06
  },
  {
    "id": 26,
    "name": "Agflow Boost",
    "unit": "T/ha",
    "totalNitrogenPercent": 0.12,
    "nitrogenControl": 0.12,
    "nonUreaNitrogen": 0.12,
    "nonUreaNitrogenPercent": 0.35,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.2,
    "kPercent": 0,
    "sPercent": 0.04
  },
  {
    "id": 27,
    "name": "Agflow Extra",
    "unit": "T/ha",
    "totalNitrogenPercent": 0.13,
    "nitrogenControl": 0.13,
    "nonUreaNitrogen": 0.13,
    "nonUreaNitrogenPercent": 0.38,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.18,
    "kPercent": 0,
    "sPercent": 0.06
  },
  {
    "id": 28,
    "name": "AgNP",
    "unit": "T/ha",
    "totalNitrogenPercent": 0.11,
    "nitrogenControl": 0.11,
    "nonUreaNitrogen": 0.11,
    "nonUreaNitrogenPercent": 0.32,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.23,
    "kPercent": 0,
    "sPercent": 0.01
  },
  {
    "id": 29,
    "name": "AllStar",
    "unit": "T/ha",
    "totalNitrogenPercent": 0.13,
    "nitrogenControl": 0.13,
    "nonUreaNitrogen": 0.13,
    "nonUreaNitrogenPercent": 0.38,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.14,
    "kPercent": 0,
    "sPercent": 0.09
  },
  {
    "id": 30,
    "name": "Ammo 36",
    "unit": "T/ha",
    "totalNitrogenPercent": 0.36,
    "nitrogenControl": 1.09,
    "nonUreaNitrogen": 0.49,
    "nonUreaNitrogenPercent": 1.9,
    "urea": 0.6,
    "ureaPercent": 1.3,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0.09
  },
  {
    "id": 31,
    "name": "Apex GaIXe Low P 21-1-6",
    "unit": "kg/ha",
    "totalNitrogenPercent": 0.21,
    "nitrogenControl": 0.21,
    "nonUreaNitrogen": 0.07,
    "nonUreaNitrogenPercent": 0.2,
    "urea": 0.14,
    "ureaPercent": 0.32,
    "pPercent": 0.01,
    "kPercent": 0.05,
    "sPercent": 0.06
  },
  {
    "id": 32,
    "name": "Big N",
    "unit": "T/ha",
    "totalNitrogenPercent": 0.82,
    "nitrogenControl": 0.82,
    "nonUreaNitrogen": 0.82,
    "nonUreaNitrogenPercent": 1,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0
  },
  {
    "id": 33,
    "name": "Big Phos",
    "unit": "T/ha",
    "totalNitrogenPercent": 0,
    "nitrogenControl": 0,
    "nonUreaNitrogen": 0,
    "nonUreaNitrogenPercent": 0,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.14,
    "kPercent": 0,
    "sPercent": 0.08
  },
  {
    "id": 34,
    "name": "Campbells Quick-N",
    "unit": "T/ha",
    "totalNitrogenPercent": 0.26,
    "nitrogenControl": 0.26,
    "nonUreaNitrogen": 0.26,
    "nonUreaNitrogenPercent": 0.76,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0.13
  },
  {
    "id": 35,
    "name": "CK 55 Ameliorant",
    "unit": "T/ha",
    "totalNitrogenPercent": 0.14,
    "nitrogenControl": 0.14,
    "nonUreaNitrogen": 0.14,
    "nonUreaNitrogenPercent": 0.4,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.15,
    "kPercent": 0.13,
    "sPercent": 0.01
  },
  {
    "id": 36,
    "name": "Crop Booster Plus",
    "unit": "L/ha",
    "totalNitrogenPercent": 0.05,
    "nitrogenControl": 0.05,
    "nonUreaNitrogen": 0.05,
    "nonUreaNitrogenPercent": 0.14,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.15,
    "kPercent": 0.21,
    "sPercent": 0
  },
  {
    "id": 37,
    "name": "DAP",
    "unit": "T/ha",
    "totalNitrogenPercent": 0.18,
    "nitrogenControl": 0.18,
    "nonUreaNitrogen": 0.18,
    "nonUreaNitrogenPercent": 1,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.2,
    "kPercent": 0,
    "sPercent": 0.02
  },
  {
    "id": 38,
    "name": "DAPSZC",
    "unit": "T/ha",
    "totalNitrogenPercent": 0.16,
    "nitrogenControl": 0.16,
    "nonUreaNitrogen": 0.16,
    "nonUreaNitrogenPercent": 0.88,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.18,
    "kPercent": 0,
    "sPercent": 0.08
  },
  {
    "id": 39,
    "name": "Easy N Bulk",
    "unit": "L/ha",
    "totalNitrogenPercent": 0.43,
    "nitrogenControl": 0.43,
    "nonUreaNitrogen": 0.23,
    "nonUreaNitrogenPercent": 0.67,
    "urea": 0.21,
    "ureaPercent": 0.45,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0
  },
  {
    "id": 40,
    "name": "Entec Urea",
    "unit": "kg/ha",
    "totalNitrogenPercent": 0.46,
    "nitrogenControl": 0.46,
    "nonUreaNitrogen": 0,
    "nonUreaNitrogenPercent": 0,
    "urea": 0.46,
    "ureaPercent": 1,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0
  },
  {
    "id": 41,
    "name": "Ezyflow Nano Calbud",
    "unit": "L/ha",
    "totalNitrogenPercent": 0.05,
    "nitrogenControl": 0.05,
    "nonUreaNitrogen": 0.05,
    "nonUreaNitrogenPercent": 0.41,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0
  },
  {
    "id": 42,
    "name": "Flexi-N NI only",
    "unit": "L/ha",
    "totalNitrogenPercent": 0.45,
    "nitrogenControl": 0.45,
    "nonUreaNitrogen": 0,
    "nonUreaNitrogenPercent": 0,
    "urea": 0.45,
    "ureaPercent": 0.98,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0
  },
  {
    "id": 43,
    "name": "Glytrel ZnP 0-7-0",
    "unit": "L/ha",
    "totalNitrogenPercent": 0,
    "nitrogenControl": 0,
    "nonUreaNitrogen": 0,
    "nonUreaNitrogenPercent": 0,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.07,
    "kPercent": 0,
    "sPercent": 0
  },
  {
    "id": 44,
    "name": "Gran-Am",
    "unit": "T/ha",
    "totalNitrogenPercent": 0.2,
    "nitrogenControl": 0.2,
    "nonUreaNitrogen": 0.2,
    "nonUreaNitrogenPercent": 0.76,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0.24
  },
  {
    "id": 45,
    "name": "Granulock SS",
    "unit": "T/ha",
    "totalNitrogenPercent": 0.1,
    "nitrogenControl": 0.11,
    "nonUreaNitrogen": 0.11,
    "nonUreaNitrogenPercent": 1,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.18,
    "kPercent": 0,
    "sPercent": 0.12
  },
  {
    "id": 46,
    "name": "Granulock Z",
    "unit": "T/ha",
    "totalNitrogenPercent": 0.11,
    "nitrogenControl": 0.12,
    "nonUreaNitrogen": 0.12,
    "nonUreaNitrogenPercent": 1.1,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.22,
    "kPercent": 0,
    "sPercent": 0.04
  },
  {
    "id": 47,
    "name": "Green Urea NV",
    "unit": "kg/ha",
    "totalNitrogenPercent": 0.46,
    "nitrogenControl": 0.46,
    "nonUreaNitrogen": 0,
    "nonUreaNitrogenPercent": 0,
    "urea": 0.46,
    "ureaPercent": 1,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0
  },
  {
    "id": 48,
    "name": "Gypsum Sulphur",
    "unit": "kg/ha",
    "totalNitrogenPercent": 0,
    "nitrogenControl": 0,
    "nonUreaNitrogen": 0,
    "nonUreaNitrogenPercent": 0,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0.19
  },
  {
    "id": 49,
    "name": "Invigor8 0-37-37",
    "unit": "kg/ha",
    "totalNitrogenPercent": 0,
    "nitrogenControl": 0,
    "nonUreaNitrogen": 0,
    "nonUreaNitrogenPercent": 0,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.37,
    "kPercent": 0,
    "sPercent": 0
  },
  {
    "id": 50,
    "name": "Manganese Sulphate",
    "unit": "T/ha",
    "totalNitrogenPercent": 0,
    "nitrogenControl": 0,
    "nonUreaNitrogen": 0,
    "nonUreaNitrogenPercent": 0,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0.18
  },
  {
    "id": 51,
    "name": "MAP",
    "unit": "T/ha",
    "totalNitrogenPercent": 0.1,
    "nitrogenControl": 0.11,
    "nonUreaNitrogen": 0.11,
    "nonUreaNitrogenPercent": 1,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.22,
    "kPercent": 0,
    "sPercent": 0
  },
  {
    "id": 52,
    "name": "MAP Impact",
    "unit": "T/ha",
    "totalNitrogenPercent": 0.1,
    "nitrogenControl": 0.11,
    "nonUreaNitrogen": 0.11,
    "nonUreaNitrogenPercent": 1,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.22,
    "kPercent": 0,
    "sPercent": 0.02
  },
  {
    "id": 53,
    "name": "MAPSZC",
    "unit": "T/ha",
    "totalNitrogenPercent": 0.12,
    "nitrogenControl": 0.13,
    "nonUreaNitrogen": 0.13,
    "nonUreaNitrogenPercent": 1.2,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.21,
    "kPercent": 0,
    "sPercent": 0.04
  },
  {
    "id": 54,
    "name": "Maximum N-Pact 29-0-0",
    "unit": "T/ha",
    "totalNitrogenPercent": 0.29,
    "nitrogenControl": 0.29,
    "nonUreaNitrogen": 0,
    "nonUreaNitrogenPercent": 0,
    "urea": 0.29,
    "ureaPercent": 0.63,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0
  },
  {
    "id": 55,
    "name": "Microsol 12-6-44",
    "unit": "kg/ha",
    "totalNitrogenPercent": 0.12,
    "nitrogenControl": 0.12,
    "nonUreaNitrogen": 0.12,
    "nonUreaNitrogenPercent": 0.35,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.06,
    "kPercent": 0.44,
    "sPercent": 0.07
  },
  {
    "id": 56,
    "name": "Microsol 16-32-16",
    "unit": "kg/ha",
    "totalNitrogenPercent": 0.16,
    "nitrogenControl": 0.16,
    "nonUreaNitrogen": 0.02,
    "nonUreaNitrogenPercent": 0.07,
    "urea": 0.13,
    "ureaPercent": 0.29,
    "pPercent": 0.32,
    "kPercent": 0.16,
    "sPercent": 0.07
  },
  {
    "id": 57,
    "name": "Microsol 25-5-10",
    "unit": "kg/ha",
    "totalNitrogenPercent": 0.25,
    "nitrogenControl": 0.25,
    "nonUreaNitrogen": 0.12,
    "nonUreaNitrogenPercent": 0.35,
    "urea": 0.13,
    "ureaPercent": 0.28,
    "pPercent": 0.05,
    "kPercent": 0.1,
    "sPercent": 0.07
  },
  {
    "id": 58,
    "name": "Microsol 28-0-14",
    "unit": "kg/ha",
    "totalNitrogenPercent": 0.28,
    "nitrogenControl": 0.28,
    "nonUreaNitrogen": 0.04,
    "nonUreaNitrogenPercent": 0.12,
    "urea": 0.24,
    "ureaPercent": 0.52,
    "pPercent": 0,
    "kPercent": 0.14,
    "sPercent": 0.07
  },
  {
    "id": 59,
    "name": "Mono Potassium Phosphate MKP",
    "unit": "kg/ha",
    "totalNitrogenPercent": 0,
    "nitrogenControl": 0,
    "nonUreaNitrogen": 0,
    "nonUreaNitrogenPercent": 0,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.23,
    "kPercent": 0.28,
    "sPercent": 0
  },
  {
    "id": 60,
    "name": "Muriate Of Potash MOP",
    "unit": "T/ha",
    "totalNitrogenPercent": 0,
    "nitrogenControl": 0,
    "nonUreaNitrogen": 0,
    "nonUreaNitrogenPercent": 0,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0,
    "kPercent": 0.5,
    "sPercent": 0
  },
  {
    "id": 61,
    "name": "NitroPlus",
    "unit": "L/ha",
    "totalNitrogenPercent": 0.18,
    "nitrogenControl": 0.18,
    "nonUreaNitrogen": 0.03,
    "nonUreaNitrogenPercent": 0.09,
    "urea": 0.15,
    "ureaPercent": 0.32,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0.05
  },
  {
    "id": 62,
    "name": "NutraGold",
    "unit": "T/ha",
    "totalNitrogenPercent": 0,
    "nitrogenControl": 0,
    "nonUreaNitrogen": 0,
    "nonUreaNitrogenPercent": 0,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0.9
  },
  {
    "id": 63,
    "name": "Pro Performance 18-3-6",
    "unit": "L/ha",
    "totalNitrogenPercent": 0.18,
    "nitrogenControl": 0.18,
    "nonUreaNitrogen": 0.1,
    "nonUreaNitrogenPercent": 0.29,
    "urea": 0.08,
    "ureaPercent": 0.17,
    "pPercent": 0.03,
    "kPercent": 0.5,
    "sPercent": 0
  },
  {
    "id": 64,
    "name": "SOA",
    "unit": "T/ha",
    "totalNitrogenPercent": 0.21,
    "nitrogenControl": 0.21,
    "nonUreaNitrogen": 0.21,
    "nonUreaNitrogenPercent": 1,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0.24
  },
  {
    "id": 65,
    "name": "SSP",
    "unit": "T/ha",
    "totalNitrogenPercent": 0,
    "nitrogenControl": 0,
    "nonUreaNitrogen": 0,
    "nonUreaNitrogenPercent": 0,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.09,
    "kPercent": 0,
    "sPercent": 0.11
  },
  {
    "id": 66,
    "name": "Super Phos",
    "unit": "kg/ha",
    "totalNitrogenPercent": 0,
    "nitrogenControl": 0,
    "nonUreaNitrogen": 0,
    "nonUreaNitrogenPercent": 0,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.09,
    "kPercent": 0,
    "sPercent": 0.11
  },
  {
    "id": 67,
    "name": "Super SR Extra",
    "unit": "T/ha",
    "totalNitrogenPercent": 0,
    "nitrogenControl": 0,
    "nonUreaNitrogen": 0,
    "nonUreaNitrogenPercent": 0,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.1,
    "kPercent": 0,
    "sPercent": 0.09
  },
  {
    "id": 68,
    "name": "SuPerfect",
    "unit": "kg/ha",
    "totalNitrogenPercent": 0,
    "nitrogenControl": 0,
    "nonUreaNitrogen": 0,
    "nonUreaNitrogenPercent": 0,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.09,
    "kPercent": 0,
    "sPercent": 0.11
  },
  {
    "id": 69,
    "name": "SuperPasture Potash 5:1",
    "unit": "T/ha",
    "totalNitrogenPercent": 0,
    "nitrogenControl": 0,
    "nonUreaNitrogen": 0,
    "nonUreaNitrogenPercent": 0,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.08,
    "kPercent": 0.08,
    "sPercent": 0.09
  },
  {
    "id": 70,
    "name": "Trace-it Mn",
    "unit": "L/ha",
    "totalNitrogenPercent": 0,
    "nitrogenControl": 0,
    "nonUreaNitrogen": 0,
    "nonUreaNitrogenPercent": 0,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0.11
  },
  {
    "id": 71,
    "name": "UniSZon",
    "unit": "kg/ha",
    "totalNitrogenPercent": 0.12,
    "nitrogenControl": 0.12,
    "nonUreaNitrogen": 0.12,
    "nonUreaNitrogenPercent": 0.35,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.21,
    "kPercent": 0,
    "sPercent": 0.05
  },
  {
    "id": 72,
    "name": "Urea",
    "unit": "T/ha",
    "totalNitrogenPercent": 0.46,
    "nitrogenControl": 0.46,
    "nonUreaNitrogen": 0,
    "nonUreaNitrogenPercent": 0,
    "urea": 0.46,
    "ureaPercent": 1,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0
  },
  {
    "id": 73,
    "name": "Wengfu Slam Granular",
    "unit": "kg/ha",
    "totalNitrogenPercent": 0.19,
    "nitrogenControl": 0.19,
    "nonUreaNitrogen": 0.19,
    "nonUreaNitrogenPercent": 0.55,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.01,
    "kPercent": 0,
    "sPercent": 0.23
  },
  {
    "id": 74,
    "name": "Yara FlowPhos 10",
    "unit": "L/ha",
    "totalNitrogenPercent": 0.13,
    "nitrogenControl": 0.13,
    "nonUreaNitrogen": 0.05,
    "nonUreaNitrogenPercent": 0.15,
    "urea": 0.08,
    "ureaPercent": 0.17,
    "pPercent": 0.1,
    "kPercent": 0.05,
    "sPercent": 0
  },
  {
    "id": 75,
    "name": "Yara FlowPhos 13Z",
    "unit": "L/ha",
    "totalNitrogenPercent": 0.09,
    "nitrogenControl": 0.09,
    "nonUreaNitrogen": 0.09,
    "nonUreaNitrogenPercent": 0.26,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.14,
    "kPercent": 0.01,
    "sPercent": 0
  },
  {
    "id": 76,
    "name": "Yara FlowPhos 15",
    "unit": "L/ha",
    "totalNitrogenPercent": 0.1,
    "nitrogenControl": 0.1,
    "nonUreaNitrogen": 0.1,
    "nonUreaNitrogenPercent": 0.29,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.15,
    "kPercent": 0,
    "sPercent": 0
  },
  {
    "id": 77,
    "name": "Yara FlowPhos KZ",
    "unit": "L/ha",
    "totalNitrogenPercent": 0.08,
    "nitrogenControl": 0.08,
    "nonUreaNitrogen": 0.08,
    "nonUreaNitrogenPercent": 0.24,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.14,
    "kPercent": 0,
    "sPercent": 0
  },
  {
    "id": 78,
    "name": "Yara KeyPro Trace",
    "unit": "L/ha",
    "totalNitrogenPercent": 0,
    "nitrogenControl": 0,
    "nonUreaNitrogen": 0,
    "nonUreaNitrogenPercent": 0,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0,
    "kPercent": 0.04,
    "sPercent": 0
  },
  {
    "id": 79,
    "name": "Yara KeyPro Zinc",
    "unit": "L/ha",
    "totalNitrogenPercent": 0,
    "nitrogenControl": 0,
    "nonUreaNitrogen": 0,
    "nonUreaNitrogenPercent": 0,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0,
    "kPercent": 0.09,
    "sPercent": 0
  },
  {
    "id": 80,
    "name": "Yara K-Flow 0-0-15",
    "unit": "L/ha",
    "totalNitrogenPercent": 0,
    "nitrogenControl": 0,
    "nonUreaNitrogen": 0,
    "nonUreaNitrogenPercent": 0,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0,
    "kPercent": 0.15,
    "sPercent": 0
  },
  {
    "id": 81,
    "name": "Yara K-Flow 0-14-30",
    "unit": "L/ha",
    "totalNitrogenPercent": 0,
    "nitrogenControl": 0,
    "nonUreaNitrogen": 0,
    "nonUreaNitrogenPercent": 0,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.14,
    "kPercent": 0.3,
    "sPercent": 0
  },
  {
    "id": 82,
    "name": "Yara K-Flow 0-7-36",
    "unit": "L/ha",
    "totalNitrogenPercent": 0,
    "nitrogenControl": 0,
    "nonUreaNitrogen": 0,
    "nonUreaNitrogenPercent": 0,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.07,
    "kPercent": 0.36,
    "sPercent": 0
  },
  {
    "id": 83,
    "name": "Yara K-Flow 13-0-7",
    "unit": "L/ha",
    "totalNitrogenPercent": 0.13,
    "nitrogenControl": 0.13,
    "nonUreaNitrogen": 0,
    "nonUreaNitrogenPercent": 0,
    "urea": 0.13,
    "ureaPercent": 0.29,
    "pPercent": 0,
    "kPercent": 0.07,
    "sPercent": 0
  },
  {
    "id": 84,
    "name": "Yara K-Flow 4-0-12",
    "unit": "L/ha",
    "totalNitrogenPercent": 0.04,
    "nitrogenControl": 0.04,
    "nonUreaNitrogen": 0.04,
    "nonUreaNitrogenPercent": 0.13,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0,
    "kPercent": 0.12,
    "sPercent": 0
  },
  {
    "id": 85,
    "name": "Yara Liquids MICRO-SUL",
    "unit": "L/ha",
    "totalNitrogenPercent": 0.07,
    "nitrogenControl": 0.07,
    "nonUreaNitrogen": 0,
    "nonUreaNitrogenPercent": 0,
    "urea": 0.07,
    "ureaPercent": 0.15,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0.07
  },
  {
    "id": 86,
    "name": "Yara Liquids N24",
    "unit": "L/ha",
    "totalNitrogenPercent": 0.24,
    "nitrogenControl": 0.24,
    "nonUreaNitrogen": 0,
    "nonUreaNitrogenPercent": 0,
    "urea": 0.24,
    "ureaPercent": 0.52,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0
  },
  {
    "id": 87,
    "name": "Yara Liquids N25",
    "unit": "L/ha",
    "totalNitrogenPercent": 0.25,
    "nitrogenControl": 0.25,
    "nonUreaNitrogen": 0.25,
    "nonUreaNitrogenPercent": 0.89,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0
  },
  {
    "id": 88,
    "name": "Yara Liquids N26",
    "unit": "L/ha",
    "totalNitrogenPercent": 0.26,
    "nitrogenControl": 0.26,
    "nonUreaNitrogen": 0,
    "nonUreaNitrogenPercent": 0,
    "urea": 0.26,
    "ureaPercent": 0.57,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0
  },
  {
    "id": 89,
    "name": "Yara Liquids N42",
    "unit": "L/ha",
    "totalNitrogenPercent": 0.42,
    "nitrogenControl": 0.38,
    "nonUreaNitrogen": 0.17,
    "nonUreaNitrogenPercent": 0.6,
    "urea": 0.21,
    "ureaPercent": 0.47,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0
  },
  {
    "id": 90,
    "name": "Yara Liquids N-CAL 15+18Ca",
    "unit": "L/ha",
    "totalNitrogenPercent": 0.15,
    "nitrogenControl": 0.15,
    "nonUreaNitrogen": 0.15,
    "nonUreaNitrogenPercent": 0.44,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0
  },
  {
    "id": 91,
    "name": "Yara Liquids N-CAL 20+10Ca+B",
    "unit": "L/ha",
    "totalNitrogenPercent": 0.2,
    "nitrogenControl": 0.2,
    "nonUreaNitrogen": 0.2,
    "nonUreaNitrogenPercent": 0.58,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0
  },
  {
    "id": 92,
    "name": "Yara Liquids N-CAL 35+6Ca",
    "unit": "L/ha",
    "totalNitrogenPercent": 0.35,
    "nitrogenControl": 0.35,
    "nonUreaNitrogen": 0.05,
    "nonUreaNitrogenPercent": 0.16,
    "urea": 0.3,
    "ureaPercent": 0.65,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0
  },
  {
    "id": 93,
    "name": "Yara Liquids N-FOL 24",
    "unit": "L/ha",
    "totalNitrogenPercent": 0.24,
    "nitrogenControl": 0.24,
    "nonUreaNitrogen": 0,
    "nonUreaNitrogenPercent": 0,
    "urea": 0.24,
    "ureaPercent": 0.52,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0
  },
  {
    "id": 94,
    "name": "Yara Liquids N-FOL 24+TE",
    "unit": "L/ha",
    "totalNitrogenPercent": 0.25,
    "nitrogenControl": 0.25,
    "nonUreaNitrogen": 0,
    "nonUreaNitrogenPercent": 0,
    "urea": 0.25,
    "ureaPercent": 0.53,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0.01
  },
  {
    "id": 95,
    "name": "Yara Liquids P-FOL Zinc",
    "unit": "L/ha",
    "totalNitrogenPercent": 0,
    "nitrogenControl": 0,
    "nonUreaNitrogen": 0,
    "nonUreaNitrogenPercent": 0,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.18,
    "kPercent": 0.02,
    "sPercent": 0
  },
  {
    "id": 96,
    "name": "Yara Liquids SULSA 11-0-0+12S",
    "unit": "L/ha",
    "totalNitrogenPercent": 0.11,
    "nitrogenControl": 0.08,
    "nonUreaNitrogen": 0.08,
    "nonUreaNitrogenPercent": 0.31,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0.12
  },
  {
    "id": 97,
    "name": "Yara Liquids SULSA 24-3-0+6S",
    "unit": "L/ha",
    "totalNitrogenPercent": 0.24,
    "nitrogenControl": 0.22,
    "nonUreaNitrogen": 0.05,
    "nonUreaNitrogenPercent": 0.2,
    "urea": 0.17,
    "ureaPercent": 0.37,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0.05
  },
  {
    "id": 98,
    "name": "Yara Liquids SULSA 27-0-0+7S",
    "unit": "L/ha",
    "totalNitrogenPercent": 0.27,
    "nitrogenControl": 0.25,
    "nonUreaNitrogen": 0.05,
    "nonUreaNitrogenPercent": 0.18,
    "urea": 0.21,
    "ureaPercent": 0.45,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0.07
  },
  {
    "id": 99,
    "name": "Yara Liquids SULSA 36-0-0+3S",
    "unit": "L/ha",
    "totalNitrogenPercent": 0.36,
    "nitrogenControl": 0.34,
    "nonUreaNitrogen": 0.13,
    "nonUreaNitrogenPercent": 0.44,
    "urea": 0.21,
    "ureaPercent": 0.46,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0.03
  },
  {
    "id": 100,
    "name": "YaraLiva CALCINIT B",
    "unit": "L/ha",
    "totalNitrogenPercent": 0.16,
    "nitrogenControl": 0.16,
    "nonUreaNitrogen": 0.16,
    "nonUreaNitrogenPercent": 0.52,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0
  },
  {
    "id": 101,
    "name": "YaraLiva NITRABOR",
    "unit": "L/ha",
    "totalNitrogenPercent": 0.15,
    "nitrogenControl": 0.15,
    "nonUreaNitrogen": 0.15,
    "nonUreaNitrogenPercent": 0.5,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0
  },
  {
    "id": 102,
    "name": "YaraLiva TROPICOTE",
    "unit": "L/ha",
    "totalNitrogenPercent": 0.15,
    "nitrogenControl": 0.15,
    "nonUreaNitrogen": 0.15,
    "nonUreaNitrogenPercent": 0.49,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0
  },
  {
    "id": 103,
    "name": "YaraMila Complex",
    "unit": "L/ha",
    "totalNitrogenPercent": 0.12,
    "nitrogenControl": 0.11,
    "nonUreaNitrogen": 0.11,
    "nonUreaNitrogenPercent": 0.36,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.05,
    "kPercent": 0.15,
    "sPercent": 0.08
  },
  {
    "id": 104,
    "name": "YaraMila Complex 17-5-10(4)",
    "unit": "L/ha",
    "totalNitrogenPercent": 0.17,
    "nitrogenControl": 0.15,
    "nonUreaNitrogen": 0.15,
    "nonUreaNitrogenPercent": 0.5,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.05,
    "kPercent": 0.1,
    "sPercent": 0.04
  },
  {
    "id": 105,
    "name": "YaraMila Complex 21-7-3(4)",
    "unit": "L/ha",
    "totalNitrogenPercent": 0.21,
    "nitrogenControl": 0.19,
    "nonUreaNitrogen": 0.19,
    "nonUreaNitrogenPercent": 0.62,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.07,
    "kPercent": 0.03,
    "sPercent": 0.04
  },
  {
    "id": 106,
    "name": "YaraMila UNIVERSAL",
    "unit": "L/ha",
    "totalNitrogenPercent": 0.15,
    "nitrogenControl": 0.13,
    "nonUreaNitrogen": 0.13,
    "nonUreaNitrogenPercent": 0.44,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.07,
    "kPercent": 0.13,
    "sPercent": 0.05
  },
  {
    "id": 107,
    "name": "YaraRega 13-2-21",
    "unit": "L/ha",
    "totalNitrogenPercent": 0.13,
    "nitrogenControl": 0.13,
    "nonUreaNitrogen": 0.13,
    "nonUreaNitrogenPercent": 0.42,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.02,
    "kPercent": 0.21,
    "sPercent": 0.09
  },
  {
    "id": 108,
    "name": "YaraVita BORTRAC",
    "unit": "L/ha",
    "totalNitrogenPercent": 0.07,
    "nitrogenControl": 0.07,
    "nonUreaNitrogen": 0.07,
    "nonUreaNitrogenPercent": 0.22,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0
  },
  {
    "id": 109,
    "name": "YaraVita BRASSITREL DF",
    "unit": "kg/ha",
    "totalNitrogenPercent": 0.01,
    "nitrogenControl": 0.01,
    "nonUreaNitrogen": 0.01,
    "nonUreaNitrogenPercent": 0.03,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0.04
  },
  {
    "id": 110,
    "name": "YaraVita BRASSITREL PRO",
    "unit": "L/ha",
    "totalNitrogenPercent": 0.07,
    "nitrogenControl": 0.07,
    "nonUreaNitrogen": 0.07,
    "nonUreaNitrogenPercent": 0.22,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0
  },
  {
    "id": 111,
    "name": "YaraVita BUD BUILDER FL",
    "unit": "L/ha",
    "totalNitrogenPercent": 0.07,
    "nitrogenControl": 0.07,
    "nonUreaNitrogen": 0.07,
    "nonUreaNitrogenPercent": 0.22,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.02,
    "kPercent": 0,
    "sPercent": 0
  },
  {
    "id": 112,
    "name": "YaraVita FRUTREL",
    "unit": "L/ha",
    "totalNitrogenPercent": 0.07,
    "nitrogenControl": 0.07,
    "nonUreaNitrogen": 0.07,
    "nonUreaNitrogenPercent": 0.22,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.11,
    "kPercent": 0,
    "sPercent": 0
  },
  {
    "id": 113,
    "name": "YaraVita GLYTREL ZnP",
    "unit": "L/ha",
    "totalNitrogenPercent": 0,
    "nitrogenControl": 0,
    "nonUreaNitrogen": 0,
    "nonUreaNitrogenPercent": 0,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.04,
    "kPercent": 0,
    "sPercent": 0
  },
  {
    "id": 114,
    "name": "YaraVita GRAMITREL",
    "unit": "L/ha",
    "totalNitrogenPercent": 0.06,
    "nitrogenControl": 0.06,
    "nonUreaNitrogen": 0.06,
    "nonUreaNitrogenPercent": 0.19,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0
  },
  {
    "id": 115,
    "name": "YaraVita KOMBIPHOS",
    "unit": "L/ha",
    "totalNitrogenPercent": 0,
    "nitrogenControl": 0,
    "nonUreaNitrogen": 0,
    "nonUreaNitrogenPercent": 0,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.19,
    "kPercent": 0.06,
    "sPercent": 0
  },
  {
    "id": 116,
    "name": "YaraVita MANGZINC",
    "unit": "L/ha",
    "totalNitrogenPercent": 0.04,
    "nitrogenControl": 0.04,
    "nonUreaNitrogen": 0.04,
    "nonUreaNitrogenPercent": 0.12,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0
  },
  {
    "id": 117,
    "name": "YaraVita MOLYTRAC",
    "unit": "L/ha",
    "totalNitrogenPercent": 0,
    "nitrogenControl": 0,
    "nonUreaNitrogen": 0,
    "nonUreaNitrogenPercent": 0,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.11,
    "kPercent": 0,
    "sPercent": 0
  },
  {
    "id": 118,
    "name": "YaraVita SENIPHOS",
    "unit": "L/ha",
    "totalNitrogenPercent": 0,
    "nitrogenControl": 0,
    "nonUreaNitrogen": 0,
    "nonUreaNitrogenPercent": 0,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.14,
    "kPercent": 0,
    "sPercent": 0
  },
  {
    "id": 119,
    "name": "YaraVita ZINTRAC",
    "unit": "L/ha",
    "totalNitrogenPercent": 0.02,
    "nitrogenControl": 0.02,
    "nonUreaNitrogen": 0.02,
    "nonUreaNitrogenPercent": 0.05,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0
  },
  {
    "id": 120,
    "name": "Zinc Sulphate Monohydrate",
    "unit": "T/ha",
    "totalNitrogenPercent": 0,
    "nitrogenControl": 0,
    "nonUreaNitrogen": 0,
    "nonUreaNitrogenPercent": 0,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0.17
  },
  {
    "id": 121,
    "name": "Zincsol",
    "unit": "L/ha",
    "totalNitrogenPercent": 0,
    "nitrogenControl": 0,
    "nonUreaNitrogen": 0,
    "nonUreaNitrogenPercent": 0,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0.08
  },
  {
    "id": 122,
    "name": "ZincStar",
    "unit": "T/ha",
    "totalNitrogenPercent": 0.1,
    "nitrogenControl": 0.11,
    "nonUreaNitrogen": 0.11,
    "nonUreaNitrogenPercent": 1,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0.22,
    "kPercent": 0,
    "sPercent": 0.02
  },
  {
    "id": 123,
    "name": "ZMC-1",
    "unit": "L/ha",
    "totalNitrogenPercent": 0,
    "nitrogenControl": 0,
    "nonUreaNitrogen": 0,
    "nonUreaNitrogenPercent": 0,
    "urea": 0,
    "ureaPercent": 0,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0.08
  },
  {
    "id": 124,
    "name": "Zn 7000",
    "unit": "L/ha",
    "totalNitrogenPercent": 0.07,
    "nitrogenControl": 0.07,
    "nonUreaNitrogen": 0,
    "nonUreaNitrogenPercent": 0,
    "urea": 0.07,
    "ureaPercent": 0.15,
    "pPercent": 0,
    "kPercent": 0,
    "sPercent": 0
  }
]