import SubButton from "./sub_button"
import { State } from "@/models/form/form_fields/form_field"
import { LivestockClassAUS, ReportDairyLivestock } from "@/models/report"
import { calculateLiveweightGain } from "@/utilities/livestock"

export default function LiveweightGainCalculator<T extends LivestockClassAUS | ReportDairyLivestock> ({
  state
}: {
  state: State<T>
}) {
  return <SubButton 
  padding={"0px 16px"}
  onClick={() => {
    const liveweight = state.get.liveweight
    state.set({
        ...state.get,
        liveweightGain: {
            spring: calculateLiveweightGain(liveweight?.summer, liveweight?.spring),
            summer: calculateLiveweightGain(liveweight?.autumn, liveweight?.summer),
            autumn: calculateLiveweightGain(liveweight?.winter, liveweight?.autumn),
            winter: 0
        }
    })
  }}
>
    Calculate
</SubButton>
}