import styled from "styled-components";
import { ruminatiColors } from "../../utilities/colors";
import Heading from "../heading";
import { BaseText } from "../styled_text";
import { Column, Row } from "../styled_layout";
import Icon from "../icon"

/**
 * A notification card for displaying various content.
 */
export default function NotificationCard({
    title,
    description,
    contentArea,
    footerArea,
    onClick,
}: {
    title: string;
    description?: string | JSX.Element;
    contentArea?: string | JSX.Element;
    footerArea?: string | JSX.Element;
    onClick?: () => void ;
}) {
    return (
        <DisplayCardContainer borderColor={ruminatiColors.bone_0} key={title}>
            <Column style={{width: '100%', padding: "24px"}}>
                <Row style={{ width: "100%", justifyContent: "space-between", marginBottom: '10px' }}>
                <Column>
                    <Heading level={4} textAlign="start" color={ruminatiColors.green_3}>
                        {title}
                    </Heading>
                </Column>
                <Column style={{
                    cursor: "pointer"
                }}>
                    <div onClick={() => onClick ? onClick() : null }>
                            <Icon icon="x" fillColor={ruminatiColors.green_3_50}/>
                    </div>
                    </Column>
                </Row>
                {description && (
                    <Row style={{marginBottom: '12px',  width: "100%", justifyContent: 'flex-start'}}>
                        {typeof description === 'string' ? 
                        <CardBodyText>
                            {description}
                        </CardBodyText> 
                        : description
                        }
                    </Row>
                )}
                
                {contentArea && (
                    <Row style={{ width: "100%", justifyContent: 'flex-start'}}>
                        {typeof contentArea === 'string' ? 
                        <CardBodyText>
                            {contentArea}
                        </CardBodyText> 
                        : contentArea
                        }
                    </Row>
                )}

                {footerArea && (
                    <Row style={{ width: "100%", justifyContent: 'flex-start'}}>
                        {footerArea}
                    </Row>                    
                )}
            </Column>
        </DisplayCardContainer>
    );
}

const DisplayCardContainer = styled.div.attrs(
    (props: { borderColor: string }) => props
)`
    display: flex;
    width: 100%;
    border: 1px solid ${(props) => props.borderColor ?? ruminatiColors.green_3};
    background-color: ${ruminatiColors.green_3_5};
    border-radius: 8px;
`;

const CardBodyText = styled(BaseText).attrs(
    (props: { color: string }) => props
)`
    color: ${(props) => props.color ?? ruminatiColors.green_3};
    
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.02em;
`;
