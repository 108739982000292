import { Link, useLocation, matchRoutes } from "react-router-dom";
import styled from "styled-components";

import { BodyText } from "./styled_text";

import { useConfigStore } from "@/state/config_store";

import { ruminatiColors } from "@/utilities/colors";

// TODO: probably not ideal to maintain like this
// could do a useEffect to set some zustand state when the FAQ drawer renders and manage the button visibility that way... though we generally try to avoid useEffect unless absolutely necessary
// something else?
const FAQS_ROUTES = [
  { path: '/getstarted' },
  { path: "/farm/:propertyId" },
  { path: "/form/:reportId/pastureInformation" },
  { path: "/form/:reportId/consumablesInformation" },
  { path: "/form/:reportId/livestockInformationCattleClassAus" },
  { path: "/form/:reportId/cattlePurchasesInformation" },
  { path: "/form/:reportId/livestockInformationSheepClassAus" },
  { path: "/form/:reportId/livestockInformationSheepMiscAus" },
  { path: "/form/:reportId/sheepPurchasesInformation" },
  { path: "/form/:reportId/all-supplementary-feed" },
  { path: "/form/:reportId/grainInformationPostV3_3" },
  { path: "/form/:reportId/treesInformation" },
  { path: "/form/:reportId/productionInformation" },
  { path: "/form/:reportId/summary" },
  { path: "/report/:reportId" },
]

export function Footer() {
  const location = useLocation()
  const matchingFAQRoutes = matchRoutes(FAQS_ROUTES, location.pathname)
  const isFAQRoute = matchingFAQRoutes?.length && matchingFAQRoutes.length > 0
  const { setIsHelpDrawerOpen } = useConfigStore()

  return (
    <FooterOuterContainer>
      <FooterContainer>
        <div style={{ display: "flex", alignItems: "center", gap: isFAQRoute ? "12px" : "0px" }}>
          <BodyText>
            Need help or have a question?{" "}
            {isFAQRoute && (
              <>
                <FooterButton
                  onClick={() => {
                    setIsHelpDrawerOpen(true)
                  }}
                >View our FAQ's</FooterButton>
                {" or "}
              </>
            )}
            <FooterLink to="mailto:hello@ruminati.com.au">Get in touch.</FooterLink>
          </BodyText>
        </div>
        <BodyText>We’ve always got a farmer one step away.</BodyText>
      </FooterContainer>
    </FooterOuterContainer>
  )
}


const FooterOuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 48px;
  background-color: ${ruminatiColors.green_3_5};
  border-top: 1px solid ${ruminatiColors.green_3_30};
`

const FooterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "TTInterfaces", sans-serif;
  align-items: center;
  width: 100%;
  max-width: 936px;
  height: 100%;
`

const FooterLink = styled(Link)`
  display: inline;
  color: ${ruminatiColors.green_3};
  text-decoration: underline;
`

const FooterButton = styled.button`
  font-family: "TTInterfaces", sans-serif;
  color: ${ruminatiColors.green_3};
  font-size: 14px;
  font-weight: 500;
  text-decoration: underline;
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
`