import { logOut, refreshToken, tokenKey } from './auth_service';

export const baseUrl = import.meta.env.REACT_APP_API_BASE_URL;


const formatStatus = (statusText: string) => statusText.replace(" ", "_").toUpperCase();

/**
 * General use get request function
 * @param {string} url the url to request from
 * @param {string} [token] the auth token to send with the request
 * @param {RequestInit} [opts] the request options
 * @returns the response in JSON
 */
export async function get(url: string, token?: string, opts?: RequestInit) {
    return requestWithRetries({
        url,
        opts: {
            ...opts,
            method: "GET",
        },
        retries: 3,
        token,
    });
}

/**
 * General use delete request function
 * @param {string} url the url to request from
 * @param {string} [token] the auth token to send with the request
 * @param {RequestInit} [opts] the request options
 * @returns the response in JSON
 */
export async function deleteHttp(url: string, token?: string, opts?: RequestInit) {
    return requestWithRetries({
        url,
        opts: {
            ...opts,
            method: "DELETE",
        },
        retries: 3,
        token,
    });
}

/**
 * General use patch request function
 * @param {string} url the url to request from
 * @param {any} data the data to send to the request
 * @param {string} [token] the auth token to send with the request
 * @param {RequestInit} [opts] the request options
 * @returns the response in JSON
 */
export async function patch(url: string, data: any, token?: string, opts?: RequestInit) {
    return requestWithRetries({
        url,
        opts: {
            ...opts,
            method: "PATCH",
        },
        retries: 3,
        token,
        data,
    });
}


/**
 * General use post request function
 * @param {string} url the url to request from
 * @param {any} data the data to send to the request
 * @param {string} [token] the auth token to send with the request
 * @param {RequestInit} [opts] the request options
 * @returns the response in JSON
 */
export async function post(url: string, data: any, token?: string, opts?: RequestInit) {
    return requestWithRetries({
        url,
        opts: {
            ...opts,
            method: "POST",
        },
        retries: 3,
        token,
        data,
    });
}

interface RequestWithRetries {
    url: string;
    opts: RequestInit;
    retries: number;
    token?: string;
    data?: any;
}

/**
 * A wrapper for the request function which handles token failure and attempts
 * to refresh token and try again
 * @param {RequestWithRetries} params the request parameters
 * @returns the response in JSON
 */
async function requestWithRetries({
    url,
    opts,
    retries = 3,
    token,
    data,
}: RequestWithRetries) {
    let tries = 0;
    let currentToken = token;

    while (tries < retries) {
        try {
            // Attempt normal request, if successful return data
            const requestData = await request(opts, url, currentToken, data);
            return requestData;
        } catch (err) {
            if (err instanceof Error) {
                if (err.message === "INVALID_TOKEN") {
                    // If the token is in local storage, the updated value should
                    // also be put in local storage.
                    const remember = localStorage.getItem(tokenKey) !== null;

                    // Attempt to retrieve new token from refresh
                    try {
                        currentToken = await refreshToken(remember);
                    } catch (refreshErr) {
                        if (refreshErr instanceof Error) {
                            // Catch unauthorised errors 
                            if (refreshErr.message !== "UNAUTHORISED") {
                                throw Error(refreshErr.message);
                            }
                        }
                    }
                    await new Promise(r => setTimeout(r, 1000))
                    tries++;
                } else {
                    if (err.name === 'TimeoutError') {
                        // re-throw timeout errors so react-query can handle them correctly
                        // TODO: throwing here in the same way as other errors below, but we should probably move to just re-throwing the entire error object, rather than a new Error with just the message - allows more flexibility in handling the error. See below in request() too
                        throw Error(err.name)
                    } else {
                        // Otherwise different error, so re-emit
                        throw Error(err.message);
                    }
                }
            }
        }
    }

    // If it reaches this point, the number of retries have been attempted
    // with an INVALID_TOKEN every time, hence we sign out the user
    logOut();
}

/**
 * General use request function
 * @param {RequestInit} opts the request options
 * @param {string} url the url to request from
 * @param {string} [token] the auth token to send with the request
 * @param {any} [data] optional. The data to send to the request
 * @returns the response in JSON
 */
function request(opts: RequestInit, url: string, token: string | undefined, data?: any) {
    // The fetch request options
    const requestOptions: RequestInit = {
        ...opts,
        headers: {
            "Content-Type": "application/json",
            ...opts.headers,
            ...(token ? {
                "Authorization": `Bearer ${token}`
            } : {})
        },
        cache: 'no-store',
        credentials: 'include'
    };


    // Only add body field on POST and PATCH requests
    if (["POST", "PATCH"].includes(opts.method || ''))
        requestOptions['body'] = JSON.stringify(data);

    // Fetch url with given data as body
    return fetch(url, requestOptions).catch(err => {
        if (err.name === 'TimeoutError') {
            // re-throw timeout errors so react-query can handle them correctly
            // see https://developer.mozilla.org/en-US/docs/Web/API/AbortSignal/timeout_static
            throw err
        } else {
            // If fetch fails, most likely network error
            // TODO: this is a new Error with just the message, rather than the entire error object. Consider changing this to just re-throw the error object (and below cases too)
            throw Error(formatStatus(err.statusText ?? "NETWORK_ERROR"));
        }
    }).then(response => {
        if (!response.ok) {
            return response.text().then((data) => {
                let json;
                if (data) {
                    json = JSON.parse(data);
                }
                // If given extra information in json body, throw error
                if (json && "error" in json && "code" in json.error) {
                    throw Error(json["error"]["code"]);
                } else {
                    // Otherwise, return general exception
                    throw Error(formatStatus(response.statusText));
                }
            });
        }

        // Otherwise, convert response to text
        return response.text();
    }).then((data) => {
        // If there was data in the body, parse to JSON
        if (data) return JSON.parse(data);
        // Otherwise, don't have a body, so return empty object
        return {};
    });
}