import { TypeOrFunctionOfState, InputFormFieldArgs, NumberType, Validator } from "./form_field"

import { MultiInputFormField } from "./multi_input_form_field";

export const seasonLabels = [
    { key: "spring", label: "Spring" },
    { key: "summer", label: "Summer" },
    { key: "autumn", label: "Autumn" },
    { key: "winter", label: "Winter" },
];

type SeasonInformation = { [key: string]: number | undefined };

function getSeasonYear (seasonKey: string, financialYear: number) {
    if (seasonKey === 'spring' || seasonKey === 'summer') return financialYear - 1
    return financialYear
}

function makeSeasonLabels(financialYear?: number) {
    if (!financialYear) return seasonLabels
    return seasonLabels.map(sl => {
        return {
            key: sl.key,
            label: `${sl.label} ${getSeasonYear(sl.key, financialYear)}`
        }
    })
}

export class SeasonalInputFormField extends MultiInputFormField {
    constructor(args: InputFormFieldArgs<SeasonInformation> & {
        title: string,
        allUnits?: string,
        singleValidator?: Validator<number>,
        numberType?: NumberType,
        financialYear?: number,
        additionalAction?: TypeOrFunctionOfState<JSX.Element>
    }) {
        super({
            ...args,
            unit: args.allUnits,
            required: args.required ?? true,
            useError: false,
            useLabelsAbove: true,
            options: makeSeasonLabels(args.financialYear),
            additionalAction: args.additionalAction
        });
    }
}