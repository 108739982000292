import { MapProvider } from 'react-map-gl';
import BasicMap from "../maps/basic_map";
import getPropertyLayerInformation from "../maps/layer_config/property_layer";
import {
  createEmptyFeatureCollection,
  convertFeatureCollectionToMultiPolygon,
  createFeatureCollectionFromMultiPolygon,
} from "../../utilities/geojson-helpers";
import { useState } from "react";
import { FeatureCollection, MultiPolygon, Polygon } from "geojson";
import EditBoundsMenu, { EditBoundsTools } from "../property/edit_bounds_menu";
import calculateArea from '@turf/area'
import { convertArea } from '@turf/helpers'
import Legend from '../maps/legend'

export type DrawAndCalculateAreaPopupReturns = {
  area: number;
  geom: MultiPolygon | null;
}

/**
 * The Draw and Area Calculator form, intended to be shown inside a popup
 */
export default function DrawAndCalculateAreaCalculator({
  onSubmit,
  metadata
}: {
  onSubmit: (obj: DrawAndCalculateAreaPopupReturns) => void;
  metadata: {
    propertyBoundary: MultiPolygon
    geom: MultiPolygon
    footerText: string
    additionalLayers: JSX.Element[]
    additionalLegendItems: JSX.Element[]
  }
}) {

  const propertyBoundary = metadata.propertyBoundary

  const propertyLayerInfo = getPropertyLayerInformation({ 
    gj: propertyBoundary,
    hidePropertyFill: true
  });

  const [mapData, setMapData] = useState(metadata?.geom ? createFeatureCollectionFromMultiPolygon(metadata.geom) : createEmptyFeatureCollection());

  const [tool, setTool] = useState<EditBoundsTools>(EditBoundsTools.None);

  const editingFinished = (newMapData: FeatureCollection<Polygon|MultiPolygon>) => {
    setMapData(newMapData)
    emitDrawnArea(newMapData)
  }

  const emitDrawnArea = (drawnFeatureCollection: FeatureCollection<Polygon|MultiPolygon>) => {
    if (drawnFeatureCollection.features.length === 0) return

    let area = calculateArea(drawnFeatureCollection)
    if (area) area = convertArea(area, 'meters', 'hectares')
    if (onSubmit && area) {
      const multipoly = convertFeatureCollectionToMultiPolygon(drawnFeatureCollection)
      onSubmit({
        area,
        geom: multipoly
      })
    }
  }

  const footerText = metadata.footerText ? metadata.footerText : 'Use the tools above to draw around plantings.'

  return (
    <div>
      <MapProvider>
        <BasicMap
          height={`500px`}
          bounds={propertyLayerInfo.bounds}
          layers={[propertyLayerInfo.layer, ...metadata.additionalLayers ?? []]}
          fitToBoundsChangeOnBoundsChange={false}
          zoomPadding={80}
        />
        <EditBoundsMenu
          geojsonToEdit={mapData}
          selectedTool={tool}
          setTool={setTool}
          finishEditing={editingFinished}
          footerText={footerText}
          footerBtnText={'Finish and set area'}
          drawingColor='#F3DA06'
          drawnColor='#F3DA06'
        />

        <Legend children={metadata.additionalLegendItems ?? undefined } />
     </MapProvider>
    </div>
  )
}
