import { formatReportNumber } from "../utilities/functions";
import { ruminatiColors } from "../utilities/colors";
import LabelledValue from "./form/labelled_value";
import { BaseText } from "./styled_text";

export interface FieldValue {
    name: string;
    value: string | number | undefined;
}

export default function ValuesList (props: { 
    values: FieldValue[],
    makeNumericValuePrettyString?: boolean;
}) {
    const _makeNumericValuePrettyString = props.makeNumericValuePrettyString ?? false

    function getFormattedValue (field: FieldValue) {
        if (typeof field.value === 'number' && _makeNumericValuePrettyString) {
            return formatReportNumber(field.value)
        }
        return field.value
    }

    return (
        <div style={{ width: '100%' }}>
            {props.values.map((field, fieldIndex) => (
                <LabelledValue key={fieldIndex} dashStyle="none" label={field.name} labelColor={ruminatiColors.light_green}>
                    <BaseText style={{ color: ruminatiColors.green_3, whiteSpace: "pre-line", textAlign: "right" }}>
                        {getFormattedValue(field)}
                    </BaseText>
                </LabelledValue>
            ))}
        </div>
    );
}
