import { useRef, RefObject, useState, useEffect } from "react";

import { ReportFormPage } from "./form_page";
import { FormState, FormRef, ForwardedRefForm } from "./form";
import ArrayForm, { ArrayFormRef } from "./array_form";
import { Row } from "@/components/styled_layout";
import { BodyText } from "@/components/styled_text";
import LoadingButton from "@/components/buttons/loading_button";
import { ReportPageMultipleForms } from './form_page'
import HorizontalDivider from "@/components/horizontal_divider";
import { Report } from "../property_models";
import { ReportFormPageId } from "@/utilities/forms";

export type MultFormPageProps = {
  report: Report
  reportPage: ReportPageMultipleForms
  initialData: Record<string, (Partial<FormState> | Partial<FormState>[])>
  onSubmit: (value: FormState, useRawValue?: boolean) => Promise<void>;
  onStandardFormChange: (formState: FormState) => Promise<void>;
  onArrayFormChange: (formPage: ReportFormPage<any, any>, formState: FormState[]) => Promise<void>;
  output?: {
    callback: (formComponents: Record<ReportFormPageId, FormState | FormState[]>) => FormState
  }
}

export default function MultiFormPage(
  props: MultFormPageProps
) {
  const out: JSX.Element[] = []
  const refs: RefObject<FormRef | ArrayFormRef<any>>[] = []
  
  function checkAndSetAllFormsAreValid () {
    setAllFormsValid(
      props.reportPage.formComponents.every((_r, i) => {
        return refs[i].current?.validate()
      })
    )
  }

  const [allFormsValid, setAllFormsValid] = useState(false)
  useEffect(() => {
    checkAndSetAllFormsAreValid()
  })

  props.reportPage.formComponents.forEach((formComponent, index) => {
      out.push(
          <Row style={{justifyContent: 'flex-start', width: '100%', marginBottom: '24px'}} key={`${index}_title`}>
              <BodyText style={{fontWeight: 500, fontSize: '16px'}}>{formComponent.title}</BodyText>                    
          </Row>
      )
      if (formComponent.repeatableConfig === undefined) {
          const formRef = useRef<FormRef>(null);
          refs.push(formRef)
          out.push(<ForwardedRefForm
              key={index}
              ref={formRef}
              data={formComponent.formData}
              initialValue={props.initialData[formComponent.id]}
              onSubmit={props.onSubmit}
              onChange={(formState) => {
                checkAndSetAllFormsAreValid()
                return props.onStandardFormChange(formState)
              }}
              hideSubmitButton={true}
              fieldSize="small"
          />)
      } else if (formComponent.repeatableConfig) {
          const arrayFormRef = useRef<ArrayFormRef<FormState>>(null);
          refs.push(arrayFormRef)
          out.push(<ArrayForm
              key={index}
              ref={arrayFormRef}
              data={formComponent.formData}
              initialValue={props.initialData[formComponent.id] as Partial<FormState>[]}
              onChange={(formState) => {
                return new Promise(async (resolve) => {
                  await props.onArrayFormChange(formComponent, formState)
                  checkAndSetAllFormsAreValid()
                  resolve()
                })
              }}
              sectionName={formComponent.repeatableConfig?.repeatableSectionName}
              sectionNameCallback={formComponent.repeatableConfig.repeatableSectionCallback}
              onSubmit={async () => undefined}
              fieldSize="small"
              hideSubmitButton={true}
              hideAddButton={formComponent.hideActionButtons}
              maximumAllowed={formComponent.repeatableConfig.maximumAllowed ?? undefined}
          />)
      }

      if (index !== props.reportPage.formComponents.length - 1 && !props.reportPage.hideSeperator) {
          out.push(<Row style={{justifyContent: 'flex-start', width: '100%'}} key={`${index}_seperator`}>
              <div style={{width: '100%'}}>
                  <HorizontalDivider padding={"40px 120px"}/>
              </div>
          </Row>)
      } else if (index !== props.reportPage.formComponents.length - 1 && props.reportPage.hideSeperator) {
        out.push(<Row style={{justifyContent: 'flex-start', width: '100%', marginTop: '40px'}} key={`${index}_seperator`} />)
    }
  })
  out.push(<LoadingButton
        key="submit_multi"
        type="submit"
        size="medium"
        disabled={!allFormsValid}
        onClick={async () => {
          const completeData: Partial<Record<ReportFormPageId, FormState>> = {}
          props.reportPage.formComponents.forEach((r, i) => {
              completeData[r.id as ReportFormPageId] = refs[i].current?.getState()
          })

          if (props.output && completeData) {
              const compiledData = props.output.callback(completeData as Record<ReportFormPageId, FormState>)
              await props.onSubmit(compiledData, true)
              return
          }
          await props.onSubmit(completeData, true)
        }}
    >
    Continue
  </LoadingButton>)
  return out
}
