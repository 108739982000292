import { CodeResponse, useGoogleLogin, TokenResponse } from "@react-oauth/google";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { signInWithGoogle } from "../../services/auth_service";
import { useAuthStore } from "../../state/auth_store";
import { ruminatiColors } from "../../utilities/colors";
import { RotatingIcon } from "../buttons/loading_button";
import MainButton from "../buttons/main_button";
import OutlineButton from "../buttons/outline_button";
import HorizontalDivider from "../horizontal_divider";
import Icon from "../icon";
import { Wrapper } from "../styled_layout";
import { BaseText, TextLink } from "../styled_text";


type AuthFooterProps = {
    type: "login" | "signup",
    shareAllVisionOrgId?: string,
    voucherPoolId?: string
    promoCode?: string
    partnerRequiresReferenceId?: boolean
    partnerReferenceId?: string
}

/**
 * The bottom section of the authentication pages
 * @param type either "login" or "signup" indicating the version to display
 */
export default function AuthFooter(props: AuthFooterProps) {
    const authStore = useAuthStore();
    const navigate = useNavigate();

    const [googleLoading, setGoogleLoading] = useState<boolean>(false);

    const onSuccess = async (
        codeResponse: Omit<
            CodeResponse,
            "error" | "error_description" | "error_uri"
        >
    ) => {
        if ("code" in codeResponse && codeResponse.code) {
            setGoogleLoading(true);
            try {
                const user = await signInWithGoogle(codeResponse.code, {
                    shareAllVisionOrgId: props.shareAllVisionOrgId,
                    voucherPoolId: props.voucherPoolId, 
                    promoCode: props.promoCode,
                    partnerReferenceId: props.partnerReferenceId
                });
                if (!user) throw Error("INVALID_RESPONSE");

                authStore.signIn(user);
                navigate("/dashboard");
            } catch (err) { 
                console.log(err)
            }
        }
        setGoogleLoading(false);
    };

    const onFailure = (response: Pick<
        TokenResponse,
        'error' | 'error_description' | 'error_uri'
      >) => {
        setGoogleLoading(false);
        console.log("Google Auth Failed", response);
    };

    const googleSignIn = useGoogleLogin({
        onSuccess: onSuccess,
        onError: onFailure,
        flow: "auth-code",
        scope: "https://www.googleapis.com/auth/userinfo.profile",
    });

    return (
        <>
            <Wrapper style={{ padding: "16px 0 16px 0" }}>
                <HorizontalDivider>
                    <p style={{ padding: "0 0 3px 0", margin: 0 }}>Or</p>
                </HorizontalDivider>
            </Wrapper>

            <Wrapper>
                <OutlineButton
                    disabled={props.partnerRequiresReferenceId && props.partnerReferenceId === undefined}
                    width="100%"
                    onClick={() => {
                        if (!googleLoading) {
                            googleSignIn();
                        }
                    }}
                >
                    <div style={{ padding: "0 8px 0 0", height: "32px" }}>
                        {googleLoading ? (
                            <RotatingIcon>
                                <Icon icon="loading" />
                            </RotatingIcon>
                        ) : (
                            <Icon icon="google" />
                        )}
                    </div>
                    Continue with Google
                </OutlineButton>
            </Wrapper>
            <Wrapper style={{ padding: "8px 0" }}>
                <BaseText
                    style={{
                        textAlign: "center",
                        color: ruminatiColors.green_3,
                    }}
                >
                    By {props.type === "login" ? "logging in" : "signing up"} you
                    agree to our
                    <br />
                    <a
                        href="https://ruminati.com.au/terms-of-use/"
                        target="_blank"
                        rel="noreferrer"
                        style={{
                            display: "inline-block",
                        }}
                    >
                        <TextLink>Terms of Use</TextLink>
                    </a>{" "}
                    and{" "}
                    <a
                        href="https://ruminati.com.au/privacy/"
                        target="_blank"
                        rel="noreferrer"
                        style={{
                            display: "inline-block",
                        }}
                    >
                        <TextLink>Privacy Statement</TextLink>
                    </a>
                </BaseText>
            </Wrapper>

            <Wrapper style={{ padding: "4.5px 0" }}>
                <HorizontalDivider />
            </Wrapper>

            <Wrapper
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <BaseText
                    style={{
                        padding: "0 8px 0 0",
                        fontSize: "18px",
                        lineHeight: "1em",
                        color: ruminatiColors.green_3,
                    }}
                >
                    {props.type === "login"
                        ? "Don't have an account?"
                        : "Already have an account?"}
                </BaseText>
                <Link to={props.type === "login" ? "/register" : "/login"}>
                    <MainButton size="small" colorScheme="green">
                        {props.type === "login" ? "Sign up" : "Log in"}
                    </MainButton>
                </Link>
            </Wrapper>
        </>
    );
}
