import React from "react";
import styled from "styled-components";

import { FieldSize, InputFormField, State } from "./form_field";
import { CustomField, CustomFieldWithState, InfoField, LabelField, SeparatorField } from "./decoration_form_fields";
import { FormState, FormData, FieldID } from "../form";
import { CollapsableFieldWrapper, FormFieldWrapper, RepeatableArrayFieldWrapper } from "./form_field_wrappers";
import CollapsableWrapper from "../../../components/collapsable_wrapper";
import HiddenFormField from "./hidden_form_field";
import ArrayForm from "../array_form";
import { BodyText } from "@/components/styled_text";
import { Column, Row } from "@/components/styled_layout";
import HorizontalDivider from "@/components/horizontal_divider";

const emptyValues = [undefined, NaN];

type FormFieldsProps<T extends FormState, U = T> = {
    data: FormData<T, U>;
    getValue: (field: FieldID) => T[string] | undefined;
    onChange: (field: FieldID, value?: T[string] | undefined) => void;
    state: State<any>;
    isArrayForm?: boolean;
    fieldSize?: "small" | "large";
};

function FormFieldsInner<T extends FormState, U = T>(props: FormFieldsProps<T, U>, _ref: React.ForwardedRef<null>) {

    return <>
        {Object.keys(props.data.fields).map((field) => {
            const currentElement = props.data.fields[field];

            if (currentElement instanceof FormFieldWrapper) {
                if (currentElement instanceof CollapsableFieldWrapper) {
                    return (
                        <React.Fragment key={field}>
                            <FieldColumn width={FieldSize.toWidth(FieldSize.Full)}>
                                <CollapsableWrapper
                                    id={field}
                                    startCollapsed={currentElement.startCollapsed ?? false}
                                    label={currentElement.label}
                                    border={currentElement.showBorder}
                                    showDelete={false}
                                >
                                  <Row
                                    style={{
                                        padding: currentElement.showBorder ? '24px' : '0px',
                                        width: '100%',
                                        rowGap: "12px",
                                        flexWrap: 'wrap',
                                        justifyContent: 'space-between'
                                    }}>
                                        <FormFields
                                        data={currentElement.data}
                                        getValue={(subField) => {
                                            const currValue: any = props.getValue(field);
                                            return currValue !== undefined ? currValue[subField] : undefined;
                                        }}
                                        onChange={(subField, value) => {
                                            const currValue = props.getValue(field);
                                            const newValue: any = {
                                                ...currValue,
                                                [subField]: value
                                            };
                                            props.onChange(field, newValue);
                                        }}
                                        state={props.state}
                                        isArrayForm={props.isArrayForm}
                                        fieldSize={props.fieldSize} />
                                    </Row>
                                </CollapsableWrapper>
                            </FieldColumn>
                        </React.Fragment>
                    );
                }
                throw new Error('[form_fields.tsx] Unimplemented FormFieldWrapper type');
            } else if (currentElement instanceof RepeatableArrayFieldWrapper) {
                const initialValue = props.getValue(field) ?? []
                return (
                    <React.Fragment key={field}>
                        {currentElement.useSeperatorAbove && 
                            <Row style={{justifyContent: 'flex-start', width: '100%', rowGap: '0px'}}>
                                <div style={{width: '100%'}}>
                                    <HorizontalDivider padding={"10px 120px 0px 120px"} height="10px"/>
                                </div>
                            </Row>
                        }
                        <Row style={{rowGap: '0px', width: '100%'}}>
                            <Column style={{width: '100%'}}>
                                {currentElement.label ? <Row style={{
                                    margin: '16px 0px',
                                    justifyContent: 'flex-start',
                                    width: '100%'
                                 }}>
                                    <BodyText style={{fontSize: '15px'}}>{currentElement.label}</BodyText>
                                </Row> : undefined
                                }
                                <ArrayForm
                                    data={currentElement.data}
                                    initialValue={initialValue}
                                    onChange={async (formState) => {                                        
                                        props.state.set({
                                            ...props.state.get,
                                            [field]: formState
                                        })
                                    }}
                                    onSubmit={async (_formState) => undefined}
                                    sectionName={currentElement.repeatableConfig.repeatableSectionName}
                                    sectionNameCallback={currentElement.repeatableConfig.repeatableSectionCallback ?? undefined}
                                    fieldSize="small"
                                    minimumRequired={currentElement.repeatableConfig.minimumRequired}
                                    maximumAllowed={currentElement.repeatableConfig.maximumAllowed}
                                    hideSubmitButton={true}
                                    hideAddButton={false}
                                    addMarginUnderneath={false}
                                />
                            </Column>
                        </Row>
                    </React.Fragment>
                )
            } else if (currentElement instanceof InputFormField ||
                currentElement instanceof LabelField ||
                currentElement instanceof SeparatorField ||
                currentElement instanceof CustomField ||
                currentElement instanceof CustomFieldWithState ||
                currentElement instanceof InfoField ||
                currentElement instanceof HiddenFormField) {
                return (
                    <React.Fragment key={field}>
                        <FieldColumn
                            style={{
                                display: currentElement instanceof HiddenFormField ? 'none' : undefined
                            }}
                            width={`calc(${FieldSize.toWidth(currentElement.size)} - 4px)`}
                        >
                            {currentElement.render(
                                field,
                                (value) => props.onChange(field, value),
                                props.state,
                                props.getValue(field),
                                !currentElement.validate(props.getValue(field), props.state) &&
                                !emptyValues.includes(props.getValue(field)),
                                props.fieldSize ?? "large")
                            }
                        </FieldColumn>
                    </React.Fragment>
                );
            } else {
                throw new Error('[form_fields.tsx] FieldElement object type not implemented');
            }
        })}
    </>;
}

export const FormFields = React.forwardRef(FormFieldsInner) as <T extends FormState, U = T>(
    props: FormFieldsProps<T, U> & { ref?: React.ForwardedRef<null> }
) => ReturnType<typeof FormFieldsInner>;

const FieldColumn = styled.div<{ width: string }>`
    display: flex;
    flex-direction: column;
    width: ${(props) => props.width};
    > * {
        width: 100% !important;
    }
`;