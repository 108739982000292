import { useRef, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import ReductionGraph, { EmissionDataOption} from "../../components/graph/reduction_graph";
import Screen from "../screen";
import { useDatabaseStore } from "../../state/database_store";
import { FarmPageContainer, FarmPageWrapper } from "../wrapper/farm_wrapper";
import { Column, Row } from "../../components/styled_layout";
import HorizontalDivider from "../../components/horizontal_divider";
import Heading from "../../components/heading";
import { ruminatiColors } from "../../utilities/colors";
import Tabs from "../../components/tabs";
import MainButton from "../../components/buttons/main_button";
import { BodyText } from "../../components/styled_text";
import { CardContainer } from "../../components/styled_container";
import LoadingPlaceholder from "../../components/loading_placeholder";
import {
    Initiative,
    InitiativeCategory,
    InitiativeType,
    ReductionPlan,
    ReductionPlanInputDTO,
    initiativeCategoriesMap,
} from "../../models/reduction_plans_models";

import DropdownSelect from "../../components/dropdown_select";
import LargeTextStat from "../../components/graph/large_text_stat";
import {
    formatFinancialYearStringToShortPrettyFinancialYearString,
    getCurrentFinancialYear,
} from "../../utilities/dates";
import InitiativesTab from "./initiatives_tab";
import { calculatePercentageDifferenceFromBaseline, calculateProjectedResultsForYear, filterAvoidReductionInitiativesByLastCompleteReport, getAvailableChartOptions } from "../../utilities/reduction_plans";
import { getBaseline, getBaselineUnit, getCompletedReports, getEarliestCompletedReport, getLatestCompletedReport } from "../../utilities/reports";
import { formatReportNumber } from "../../utilities/functions";
import { usePopupStore } from "../../state/popup_store";
import { updateReductionPlan } from "../../services/reduction_plans_service";
import EmissionUnit from "../../models/emission-unit";
import GraphTag from "../../components/graph/graph_tag";
import PropertyHeader from "../../components/headers/property_header";

export type DisplayInitiativePopupFunction = (
    initiativeType: InitiativeType,
    onSubmit: (value: ReductionPlanInputDTO) => Promise<void>,
    initialData?: ReductionPlanInputDTO
) => void;

/**
 * The Reduction Planer screen for seeing the reduction planner of
 * a particular farm.
 * @returns the Reduction Planer screen
 */
export default function ReductionPlannerScreen() {
    const navigate = useNavigate();
    const { propertyId } = useParams();
    
    const popupStore = usePopupStore();
    const databaseStore = useDatabaseStore()

    const property = databaseStore.properties?.find((p) => p.id === propertyId!)!
    const reports = databaseStore.reports?.filter((report) => report.propertyId === propertyId) ?? []

    const propertyTrees = databaseStore.trees[propertyId!]
    const availableInitiativesOnProperty = databaseStore.availableInitiatives![propertyId!]
    const reductionPlans = databaseStore.reductionPlans![propertyId!]

    const [toggledPlans, setToggledPlans] = useState<ReductionPlan[]>(reductionPlans ?? [])
    const [selectedYear, setSelectedYear] = useState<number>(getCurrentFinancialYear());
    const [selectedTab, setSelectedTab] = useState<number>(0);
    const [selectedData, setSelectedData] = useState<EmissionDataOption>(EmissionDataOption.EmissionsData);

    const initativesRef = useRef<HTMLDivElement>(null);

    function goToFarmOverviewPage () {
        navigate(`/farm/${propertyId}`)
    }

    if (reports.length === 0) goToFarmOverviewPage()

    const earliestReport = getEarliestCompletedReport(reports)!;
    const latestReport = getLatestCompletedReport(reports)!;
    const completedReports = getCompletedReports(reports)
    const hasCompletedReports = completedReports.length > 0

    function initiativesForCategory (category: InitiativeCategory): Initiative[] {
        const allCategoryInitiatives = (availableInitiativesOnProperty ?? []).filter((i) => i.category === category);
        if (category === InitiativeCategory.AbsorbEmissions) return allCategoryInitiatives
        return filterAvoidReductionInitiativesByLastCompleteReport(allCategoryInitiatives, latestReport)
    }

    function plansForCategory(category: InitiativeCategory): ReductionPlan[] {
        const initiatives = initiativesForCategory(category);
        return reductionPlans!.filter((plan) => initiatives.find((i) => i.id === plan.initiativeType))
    }

    function displayPopup (initiativeType: InitiativeType, onSubmit: (value: ReductionPlanInputDTO) => Promise<void>, initialData?: ReductionPlanInputDTO) {
        let repeatedData: any | undefined
        let otherReductionPlans: ReductionPlan[] | undefined

        const aborbInitiatives = [InitiativeType.ImproveSoilCarbon, InitiativeType.PlantTrees, InitiativeType.PlantTreesNZ, InitiativeType.AllowRevegetation]
        if (aborbInitiatives.indexOf(initiativeType) > -1) {
            otherReductionPlans = reductionPlans!.filter(p => p.initiativeType !== initiativeType && aborbInitiatives.indexOf(p.initiativeType) > -1)
        }

        if (initiativeType === InitiativeType.ImproveSoilCarbon && property?.soilCarbonData) {
            repeatedData = {
                currentSOC: property.soilCarbonData.polygonOCPercAverage,
                bulkDensity: parseFloat(property.soilCarbonData.polygonBDAverage.toFixed(2)),
                soilDepth: parseFloat((property.soilCarbonData.soilDepth * 100).toFixed(2))
            }
            if (initialData === undefined) initialData = [repeatedData]
        }

        popupStore.addPopup(initiativeType, undefined, {
            initial: initialData,
            repeatedInitial: repeatedData,
            report: latestReport,
            propertyBounds: property?.geom,
            maxAllowableOrganicCarbonValue: property.soilCarbonData ? Math.max(...property.soilCarbonData.surroundingOCPercHistogram.map(o => o.interval)) : 10,
            propertyTrees,
            otherReductionPlans,
            onSubmit: async (value: ReductionPlanInputDTO) => {
                await onSubmit(value);
                popupStore.closePopup();
            }
        })
    }

    const tabContent: JSX.Element[] = [
        InitiativeCategory.AvoidEmissions,
        InitiativeCategory.AbsorbEmissions,
        InitiativeCategory.FutureSolutions,
    ].map((category) => {
        return <InitiativesTab
            initiatives={initiativesForCategory(category)}
            reductionPlans={plansForCategory(category)}
            previousReport={latestReport}
            property={property!}
            key={category}
            displayPopup={displayPopup}
        />
    });

    const graphOptions = getAvailableChartOptions(property, (option) => setSelectedData(option))

    function getTextStat (label: string, unit: string, color: string, value: number, isPercentage: boolean, className: string) {
        return (
          <LargeTextStat
            className={className}
            key={label}
            label={label}
            subLabel={latestReport.financialYear < selectedYear ? `${selectedYear} Estimate` : ""}
            unit={earliestReport.financialYear <= selectedYear ? unit : ""}
            smallerUnit={isPercentage}
            statColor={color}
            value={
                earliestReport.financialYear <= selectedYear ? 
                // TO DO - should support decimal places for intensity metrics
                `${formatReportNumber(value, { maxDecimalPlaces: 0 })}${isPercentage ? "%" : ""}`
                : "-" 
            }
        />)
    } 

    const baseline = getBaseline(reports, selectedData)
    const baselineUnit = getBaselineUnit(selectedData)
    const projectedResults = calculateProjectedResultsForYear(selectedYear, completedReports, toggledPlans)

    function createSummaryStats () {
        let stats: JSX.Element[] = [];
        let rawDifference = 0;
        const netBaseline = baseline.total - baseline.sequestration;

        function makeIntensityStat (unit: EmissionUnit, value: number | undefined | null) {
            return [getTextStat("Emissions Intensity", unit, ruminatiColors.data_orange, value ?? 0, false, 'emissionsIntensitySummary')]
        }

        switch (selectedData) {
            default:
                rawDifference = projectedResults.totals.net - netBaseline;
                stats = [
                    getTextStat(
                        "Avoided Emissions",
                        EmissionUnit.Total,
                        ruminatiColors.data_blue,
                        baseline.total - projectedResults.totals.total,
                        false,
                        'avoidedEmissionsSummary'
                    ),
                    getTextStat("Absorbed Emissions",
                        EmissionUnit.Total,
                        ruminatiColors.data_green,
                        projectedResults.totals.sequestration - baseline.sequestration,
                        false,
                        'absorbedEmissionsSummary'
                    ),
                ];
                break;
            case EmissionDataOption.BeefIntensity:
                rawDifference = (projectedResults.intensity.kgCo2PerKgBeefMeatSold ?? 0) - netBaseline;
                stats = makeIntensityStat(EmissionUnit.IntensityLivestock, projectedResults.intensity.kgCo2PerKgBeefMeatSold)
                break;
            case EmissionDataOption.SheepIntensity:
                rawDifference = (projectedResults.intensity.kgCo2PerKgSheepMeatSold ?? 0) - netBaseline;
                stats = makeIntensityStat(EmissionUnit.IntensityLivestock, projectedResults.intensity.kgCo2PerKgSheepMeatSold)
                break;
            case EmissionDataOption.WoolIntensity:
                rawDifference = (projectedResults.intensity.kgCo2PerKgGreasyWool ?? 0) - netBaseline;
                stats = makeIntensityStat(EmissionUnit.IntensityWool, projectedResults.intensity.kgCo2PerKgGreasyWool)
                break;
            case EmissionDataOption.GrainIntensity:
                rawDifference = (projectedResults.intensity.kgCo2PerKgGrainProduced ?? 0) - netBaseline;
                stats = makeIntensityStat(EmissionUnit.IntensityGrain, projectedResults.intensity.kgCo2PerKgGrainProduced)
                break;
            case EmissionDataOption.DairyMilkSolids:
                rawDifference = (projectedResults.intensity.kgCo2PerKgMilk ?? 0) - netBaseline;
                stats = makeIntensityStat(EmissionUnit.DairyIntensityMilkSolids, projectedResults.intensity.kgCo2PerKgMilk)
                console.log(rawDifference, projectedResults, netBaseline, stats)
                break;
            case EmissionDataOption.DairyFPCM:
                rawDifference = (projectedResults.intensity.kgCo2PerKgMilkFPCM ?? 0) - netBaseline;
                stats = makeIntensityStat(EmissionUnit.DairyIntensityFPCM, projectedResults.intensity.kgCo2PerKgMilkFPCM)
                break;
            case EmissionDataOption.DairyLiveWeight:
                rawDifference = (projectedResults.intensity.kgCo2PerKgDairyMeat ?? 0) - netBaseline;
                stats = makeIntensityStat(EmissionUnit.DairyIntensityLW, projectedResults.intensity.kgCo2PerKgDairyMeat)
                break;
        }

        return (<>
            {stats}
            {getTextStat(
                "Percentage Change",
                `${Math.abs(Math.round(rawDifference)).toString()} ${baselineUnit}`,
                ruminatiColors.data_orange,
                calculatePercentageDifferenceFromBaseline(projectedResults, baseline, selectedData),
                true,
                'percentageChangeSummary'
            )}
        </>)
    }

    function createSummarySection () {
        return <Row
            style={{
                width: "100%",
                justifyContent: "space-between",
                padding: "16px 24px 16px",
                borderTop: `1px solid ${ruminatiColors.green_3_30}`,
                borderBottom: `1px solid ${ruminatiColors.green_3_30}`,
                columnGap: '3%'
            }}
        >
            {createSummaryStats()}
        </Row>
    }

    function scrollToInitiatives () {
        initativesRef.current?.scrollIntoView({
            behavior: "smooth"
        });
    }

    return (
        <Screen pageTitle="Reductions Planner">
            {property ? (
                <FarmPageWrapper>
                    <PropertyHeader property={property}/>
                    {hasCompletedReports ? (
                        <>
                            <FarmPageContainer>
                                <Row
                                  style={{
                                    justifyContent: 'space-between',
                                    marginBottom: "32px",
                                    marginTop: "12px",
                                  }}
                                >
                                    <Heading level={3}>{property.name}</Heading>
                                    <Heading level={3}>Reduction Planner</Heading>
                                </Row>

                                {/* Reduction Section  */}
                                <Column
                                    style={{
                                        width: "100%",
                                        border: `1px solid ${ruminatiColors.green_3}`,
                                        borderRadius: "8px",
                                        rowGap: "16px",
                                        overflow: "hidden",
                                    }}
                                >
                                    <Row
                                        style={{
                                            width: "100%",
                                            justifyContent: "space-between",
                                            padding: "16px 24px 0px",
                                        }}
                                    >
                                        <Row>
                                            <DropdownSelect
                                                text={selectedData}
                                                options={graphOptions}
                                            />
                                            {selectedData ===
                                                EmissionDataOption.EmissionsData && (
                                                    <BodyText
                                                        style={{
                                                            fontStyle: "italic",
                                                            color: ruminatiColors.light_green,
                                                        }}
                                                    >
                                                        Change to view intensity
                                                    </BodyText>
                                                )}
                                        </Row>

                                        <BodyText>
                                            {formatFinancialYearStringToShortPrettyFinancialYearString(selectedYear.toString())}
                                        </BodyText>
                                    </Row>
                                    {createSummarySection()}
                                    <div style={{ width: "100%", padding: "0 24px 24px", position: "relative" }}>
                                        <ReductionGraph
                                            onYearSelect={setSelectedYear}
                                            currentYear={getCurrentFinancialYear().toString()}
                                            showTooltip={true}
                                            dataOption={selectedData}
                                            onEditPlan={(plan) => {
                                                displayPopup(
                                                    plan.initiativeType,
                                                    async (value) => {
                                                        const reductionPlan =
                                                            await updateReductionPlan(
                                                                propertyId!,
                                                                plan.id,
                                                                { initiativeType: plan.initiativeType, data: value }
                                                            );
                                                        if (reductionPlan) {
                                                            databaseStore.updateReductionPlan(reductionPlan, reductionPlan.propertyId);
                                                        }
                                                    },
                                                    plan.inputs
                                                );
                                            }}
                                            onToggledPlansChanged={(plans) => { setToggledPlans(plans) }}
                                            showTable={true}
                                            baseline={baseline}
                                            onExploreInitiatives={(category) => {
                                                const index = [
                                                    InitiativeCategory.AvoidEmissions,
                                                    InitiativeCategory.AbsorbEmissions
                                                ].indexOf(category)
                                                if (index >= 0) {
                                                    setSelectedTab(index);
                                                }
                                                scrollToInitiatives()
                                            }}
                                        />
                                        <div style={{ position: "absolute", top: 25, left: 90 }}>
                                            <GraphTag
                                                color={ruminatiColors.data_orange}
                                                label="Baseline"
                                                value={`${formatReportNumber(baseline.total - baseline.sequestration)} ${baselineUnit}`} />
                                        </div>
                                    </div>
                                </Column>

                                {/* Explore Card  */}
                                {reductionPlans?.length === 0 && (
                                    <Row
                                        style={{ width: "100%", margin: "32px 0" }}
                                    >
                                        <PlannerCard
                                            headerText="There are many ways you can improve your farm’s emissions."
                                            bodyText="Explore below to discover climate reduction
                                                      initiatives relevant to your unique farming
                                                      enterprise."
                                            buttonText="Explore Below"
                                            onClick={() => scrollToInitiatives()}
                                        />
                                    </Row>
                                )}
                            </FarmPageContainer>

                            <Tabs
                                selected={selectedTab}
                                onTabChange={(index: number) => setSelectedTab(index)}
                                tabs={[
                                    {
                                        text: initiativeCategoriesMap[InitiativeCategory.AvoidEmissions],
                                        notiCount: plansForCategory(InitiativeCategory.AvoidEmissions).length,
                                    },
                                    {
                                        text: initiativeCategoriesMap[InitiativeCategory.AbsorbEmissions],
                                        notiCount: plansForCategory(InitiativeCategory.AbsorbEmissions).length,
                                    },
                                    {
                                        text: initiativeCategoriesMap[InitiativeCategory.FutureSolutions],
                                        notiCount: plansForCategory(InitiativeCategory.FutureSolutions).length,
                                        disabled: true,
                                        subtext: "Coming Soon"
                                    },
                                ]}
                                tabWidth={`${936/3}px`}
                            />
                            <FarmPageContainer ref={initativesRef}>
                                {tabContent[selectedTab]}
                            </FarmPageContainer>
                        </>
                    ) : (
                        <FarmPageContainer>
                            <HorizontalDivider padding={"0px"} />
                            <Row style={{ width: "100%", margin: "32px 0 0px 0px" }}>
                                <PlannerCard
                                    headerText="No emission reports found."
                                    bodyText="Please complete an emission report to access the reduction planner."
                                    buttonText="Create Emissions Report"
                                    onClick={() =>
                                        navigate(`/farm/${propertyId}/create-report/`)
                                    }
                                />
                            </Row>
                        </FarmPageContainer>
                    )}
                </FarmPageWrapper>
            ) : (
                <LoadingPlaceholder />
            )}
        </Screen>
    );
}

function PlannerCard({
    headerText,
    bodyText,
    buttonText,
    onClick,
}: {
    headerText: string;
    bodyText: string;
    buttonText: string;
    onClick?: () => void;
}) {
    return (
        <CardContainer>
            <Column style={{ width: "100%", alignItems: "center" }}>
                <Heading level={4}>
                    {headerText}
                </Heading>
                <Column
                    style={{ marginTop: "5px", gap: "12px", alignItems: "center" }}
                >
                    <BodyText>
                        {bodyText}
                    </BodyText>

                    <MainButton onClick={onClick}> {buttonText}</MainButton>
                </Column>
            </Column>
        </CardContainer>
    );
}
