import { ChangeEventHandler, useEffect, useState } from "react";
import styled from "styled-components";
import { ruminatiColors } from "../../utilities/colors";

import tickImage from "/icons/tick-18.svg";
import {
    OnChangeFunction,
    DropdownOptionData,
} from "../../models/form/form_fields/form_field";

type CheckBoxGroupProps<T> = {
    name: string;
    size?: string;
    initialValue: T[];
    horizontal?: boolean;
    options: DropdownOptionData<T>[];
    onChange?: OnChangeFunction<T[]>;
    labelColor?: string;
    labelFontWeight?: string;
    labelWidth?: string;
};

type CheckBoxProps = {
    checkBoxKey: number;
    checked: boolean;
    size: string | "16px" | "18px" | "20px";
    label: string;
    labelWidth: string;
    labelColor: string;
    labelFontWeight: string;
    onChange: ChangeEventHandler<HTMLInputElement>;
    disabled?: boolean
};

// Used as enum for sizes
export const CheckBoxSize = {
    Large: "20px",
    Medium: "18px",
    Small: "16px",
};

export function CheckBox(props: CheckBoxProps) {
    function getLabelColor() {
        if (props.disabled) return ruminatiColors.green_3_50
        return props.labelColor ?? ruminatiColors.orange
    }
    return (
        <CheckBoxLabel
            style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: props.labelWidth ?? "auto",
            }}
            key={props.checkBoxKey}
            checked={props.checked}
            size={props.size ?? "18px"}
            color={getLabelColor()}
            fontWeight={props.labelFontWeight ?? "600"}
        >
            <input
                type="checkbox"
                name={props.label}
                defaultChecked={props.checked}
                onChange={props.onChange}
                disabled={props.disabled ?? false}
            />
            <p
                style={{
                    margin: "0px 0 0 8px",
                }}
            >
                {props.label}
            </p>
        </CheckBoxLabel>
    );
}

/**
 * A group of radio buttons
 * @param name the name for the form
 * @param size optional. The pixel size of the labels
 * @param initialValue the selected option initially. If empty, none will
 * be selected.
 * @param horizontal optional. if true, will show the checkboxes horizontally,
 * otherwise they will be shown vertically.
 * @param options the checkbox options to display
 * @param onChange the callback function whenever the value is changed
 * @param labelColor optional. The colour of label text.
 * @param labelFontWeight optional. The font weight of label text.
 * @param labelWidth optional. The width of label text's container. Set to
 * 100% to allow `text-overflow: ellipsis`;
 */
export default function CheckBoxGroup<T>(props: CheckBoxGroupProps<T>) {
    const [value, setValue] = useState(
        props.initialValue ? props.initialValue : []
    );

    useEffect(() => {
        setValue(props.initialValue);
    }, [props.initialValue]);

    const onChange = (valueChange: T) => {
        let newValue;

        if (value.includes(valueChange)) {
            newValue = [...value.filter((v) => v !== valueChange)];
        } else {
            newValue = [...value, valueChange];
        }

        setValue(newValue);
        if (props.onChange) {
            props.onChange(newValue);
        }
    };

    return (
        <CheckBoxWrapper direction={props.horizontal ? "row" : "column"}>
            {props.options.map((option: DropdownOptionData<T>, index) => {
                return (
                    <CheckBox
                        key={index}
                        checkBoxKey={index}
                        label={option.name}
                        checked={value.includes(option.value)}
                        size={props.size ?? "18px"}
                        labelColor={props.labelColor ?? ruminatiColors.green_3}
                        labelFontWeight={props.labelFontWeight ?? "600"}
                        labelWidth={props.labelWidth ?? "auto"}
                        onChange={() => onChange(option.value)}
                    />
                );
            })}
        </CheckBoxWrapper>
    );
}

type CheckBoxWrapperProps = {
    direction: string;
};

const CheckBoxWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    flex-direction: ${(props: CheckBoxWrapperProps) => props.direction};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

type CheckBoxLabelProps = {
    checked: boolean;
    size: string;
    color?: string;
    fontWeight?: string;
};

const CheckBoxLabel = styled.label`
    position: relative;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-family: "TTInterfaces", sans-serif;
    font-weight: ${(props: CheckBoxLabelProps) => props.fontWeight};
    font-size: ${(props: CheckBoxLabelProps) => props.size};
    line-height: 32px;

    padding: 5px 15px 5px 20px;

    cursor: pointer;

    display: flex;
    align-items: center;

    color: ${(props) => props.color ?? ruminatiColors.green_3};

    transition: 0.2s ease color;
    user-select: none;

    &::before {
        content: "";

        position: absolute;
        left: 0;

        width: 18px;
        height: 18px;
        border-radius: 4px;

        border: 1px solid currentColor;
        background-color: ${(props: CheckBoxLabelProps) =>
        props.checked ? "currentColor" : "transparent"};

        cursor: pointer;

        transition: 0.2s ease background-color;
    }

    &::after {
        content: "";

        position: absolute;
        left: 1px;
        width: 18px;
        height: 18px;
        background-image: ${(props: CheckBoxLabelProps) => props.checked ? `url(${tickImage})` : 'unset' }
    }

    &:hover {
        color: ${ruminatiColors.orange_40_opaque};
    }

    input[type="checkbox"] {
        // Hide default radio button
        appearance: none;
        margin: 0; /* Not removed via appearance */
    }
`;
