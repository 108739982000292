import { useNavigate } from "react-router-dom";

import PageHeader from "./page_header";
import { getEnterpriseTypeTag } from "../enterprise_type_tag";
import { Property } from "../../models/property_models";
import HeaderButton from "../buttons/header_button";

type PropertyHeaderProps = {
  property: Property
};

/**
 * A property header
 */
export default function PropertyHeader(props: PropertyHeaderProps) {
  const navigate = useNavigate();

    return (
      <PageHeader>
        <HeaderButton
            icon="upArrow"
            iconPos="left"
            onClick={() => navigate(`/farm/${props.property.id}`)}
        >
            Back to {props.property.name}
        </HeaderButton>
        {getEnterpriseTypeTag(props.property.propertyType, 'small', '0px')}
      </PageHeader>
    );
}
