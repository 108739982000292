import styled from "styled-components";
import { useRef, useState, useEffect } from "react";

import { Row, Column } from "../../styled_layout";
import TextButton from "../../buttons/text_button";
import { ruminatiColors } from "../../../utilities/colors";
import LoadingButton from "../../buttons/loading_button";
import { BodyText } from "../../styled_text";
import ArrayForm, { ArrayFormRef } from "../../../models/form/array_form";
import {
    FormData,
    FormRef,
    FormState,
    ForwardedRefForm,
} from "../../../models/form/form";
import { InfoCard } from "../../../components/card";
import Icon from "@/components/icon";

/**
 * The basic initiative popup component, which is a popup with a
 * scrollable body and a fixed footer. The latter contains an Apply button.
 * @param body the content of the popup.
 * @param repeatableSectionName optional. Used with {@link ArrayForm}.
 * A text button will show up if this param is set.
 * @param canSubmit optional. Whether the Apply button is enabled.
 * @param onAdd optional. Used with {@link ArrayForm}.
 **/
function BasicInitiativePopup({
    body,
    repeatableSectionName,
    canSubmit,
    onAdd,
    onSubmit,
    infoAbove
}: {
    body: JSX.Element;
    repeatableSectionName?: string;
    canSubmit?: boolean;
    onAdd?: () => void;
    onSubmit: () => Promise<void>;
    infoAbove?: JSX.Element | string
}) {
    return (
        <PopUpWrapper>
            <Column
                style={{
                    width: "800px",
                    gap: "20px",
                }}
            >
                <ScrollableBody>
                    {infoAbove && <Row style={{marginBottom: '20px'}}><InfoCard content={infoAbove}/></Row>}
                    {body}
                </ScrollableBody>

                <Column
                    style={{
                        backgroundColor: ruminatiColors.bone,
                        width: "100%",
                        borderTop: `1px solid ${ruminatiColors.effective_black_30}`,
                        paddingBottom: "24px",
                        borderRadius: "0 0 8px 8px",
                    }}
                >
                    <div style={{ height: "20px" }} />
                    {repeatableSectionName && (
                        <TextButton
                            fontSize="16px"
                            textColor={ruminatiColors.green_3}
                            hoverTextColor={ruminatiColors.orange}
                            activeTextColor={ruminatiColors.orange}
                            onClick={(e) => {
                                e.stopPropagation();
                                if (onAdd) onAdd();
                            }}
                        >
                            <Icon icon="circlePlus" />
                            <div style={{ width: "8.5px" }} />
                            <BodyText>
                                Add another{" "}
                                {repeatableSectionName.toLowerCase()}
                            </BodyText>
                        </TextButton>
                    )}
                    <div style={{ height: "20px" }} />

                    <LoadingButton
                        size="small"
                        disabled={!canSubmit}
                        onClick={onSubmit}
                        colorScheme="orange"
                    >
                        Apply
                    </LoadingButton>
                </Column>
            </Column>
        </PopUpWrapper>
    );
}

/**
 * An initiative pop-up with {@link ForwardedRefForm} built-in.
 * @param formData the {@link FormData} to be passed to the built-in form
 * @param initialFormValues the initial values of the built-in form.
 * @param onSubmit Apply button's onClick.
 **/
export function InitiativePopUp({
    formData,
    initialFormValues,
    onSubmit,
    infoAbove,
}: {
    formData: FormData<any>;
    initialFormValues: Partial<FormState>;
    onSubmit: (value: FormState) => Promise<void>;
    infoAbove?: JSX.Element | string
}) {
    // To show confirmation popup when cancelling, currently unused.
    const [, setDirty] = useState<boolean>(false);

    // To use ArrayForm's exposed functions.
    const formRef = useRef<FormRef>(null);

    // Whether form values are valid.
    const [validForm, setValidForm] = useState<boolean>(false);

    return (
        <BasicInitiativePopup
            canSubmit={validForm}
            onSubmit={() => {
                return formRef.current?.submit() ?? Promise.resolve()
            }}
            infoAbove={infoAbove}
            body={
                <ForwardedRefForm
                    data={formData}
                    initialValue={initialFormValues}
                    fieldSize="small"
                    onChange={async () => {
                        await setDirty(true);
                        setValidForm(formRef.current?.validate() ?? false);
                    }}
                    ref={formRef}
                    onSubmit={(value) => {
                        return onSubmit(value)
                    }}
                    hideSubmitButton={true}
                />
            }
        />
    );
}

/**
 * An initiative pop-up with {@link ArrayForm} built-in.
 * @param formData the {@link FormData} to be passed to the built-in form.
 * @param initialFormValues the initial values of the built-in form.
 * @param repeatableSectionName the name of the form's repeatable section.
 * @param onSubmit Apply button's onClick.
 **/
export function InitiativePopUpRepeatable({
    formData,
    initialFormValues,
    initialRepeatedValues,
    repeatableSectionName,
    onSubmit,
    infoAbove
}: {
    formData: FormData<any>;
    initialFormValues: Partial<FormState>[];
    initialRepeatedValues?: Partial<FormState>;
    repeatableSectionName: string;
    onSubmit: (value: FormState[]) => Promise<void>;
    infoAbove?: JSX.Element | string
}) {
    // To show confirmation popup when cancelling, currently unused.
    const [, setDirty] = useState<boolean>(false);

    // To use ArrayForm's exposed functions.
    const arrayFormRef = useRef<ArrayFormRef<FormState>>(null);

    // Whether form values are valid.
    const [validForm, setValidForm] = useState<boolean>(false);

    // Current form values.
    const [formValues,] = useState<Partial<any>[]>(
        initialFormValues ?? [{}]
    );

    const endRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (arrayFormRef.current) {
            setValidForm(arrayFormRef.current?.validate())
        }
    })

    return (
        <BasicInitiativePopup
            repeatableSectionName={repeatableSectionName}
            canSubmit={validForm}
            onAdd={() => {
                if (arrayFormRef.current) {
                    arrayFormRef.current.addItem(initialRepeatedValues);
                    setTimeout(() => endRef.current?.scrollIntoView({ behavior: 'smooth' }));
                }
            }}
            onSubmit={() => {
                return arrayFormRef.current?.submit() ?? Promise.resolve()
            }}
            body={
                <>
                    <ArrayForm
                        data={formData}
                        initialValue={formValues}
                        onChange={async () => {
                            await setDirty(true);
                            setValidForm(arrayFormRef.current!.validate());
                        }}
                        onSubmit={(value) => onSubmit(value)}
                        sectionName={repeatableSectionName}
                        ref={arrayFormRef}
                        hideAddButton={true}
                        hideSubmitButton={true}
                        startCollapsed={false}
                    />
                    <div ref={endRef} />
                </>
            }
            infoAbove={infoAbove}
        />
    );
}

const PopUpWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    height: 80%;
    overflow: auto;
`;

const ScrollableBody = styled.div`
    padding: 20px;
    overflow-y: auto;
    flex: 1;
    max-height: calc(100vh - 300px);
    width: 100%;
`;
