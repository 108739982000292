import { DropdownOptionData } from "./form/form_fields/form_field"

export const cattlePurchaseRegions: DropdownOptionData<number>[] = [
    {
        "value": 5,
        "name": "NSW - Northern"
    },
    {
        "value": 6,
        "name": "NSW - Southern"
    },
    {
        "value": 9,
        "name": "NSW - Pastoral"
    },
    {
        "value": 2,
        "name": "QLD - Northern"
    },
    {
        "value": 3,
        "name": "QLD - Southern"
    },
    {
        "value": 4,
        "name": "QLD - Central"
    },
    {
        "value": 8,
        "name": "SA - Southern"
    },
    {
        "value": 10,
        "name": "SA - Pastoral"
    },
    {
        "value": 7,
        "name": "VIC"
    },
    {
        "value": 11,
        "name": "WA - South West"
    },
    {
        "value": 12,
        "name": "WA - Pastoral"
    },
    {
        "value": 1,
        "name": "NT"
    },
    {
        "value": 13,
        "name": "TAS"
    },
    {
        "value": 15,
        "name": "Dairy origin"
    }
]

// Note NZ is stored seperately for now so it doesn't appear in the
// Australian list of options
export const cattlePuchaseRegionNZ: DropdownOptionData<number> = {
    "value": 14,
    "name": "NZ"
}

export const cropTypes: DropdownOptionData<number>[] = [
    {
        name: "Wheat",
        value: 1
    },
    {
        name: "Barley",
        value: 2
    },
    {
        name: "Maize",
        value: 3
    },
    {
        name: "Oats",
        value: 4
    },
    {
        name: "Rice",
        value: 5
    },
    {
        name: "Sorghum",
        value: 6
    },
    {
        name: "Triticale",
        value: 7
    },
    {
        name: "Other Cereals",
        value: 8
    },
    {
        name: "Pulses",
        value: 9
    },
    {
        name: "Tuber and Roots",
        value: 10
    },
    {
        name: "Peanuts",
        value: 11
    },
    {
        name: "Sugar Cane",
        value: 12
    },
    {
        name: "Cotton",
        value: 13
    },
    {
        name: "Hops",
        value: 14
    },
    {
        name: "Oilseeds",
        value: 15
    },
    {
        name: "Forage Crops",
        value: 16
    }
]

export const cropProductionSystems: DropdownOptionData<number>[] = [
    {
        name: "Non-irrigated crop",
        value: 1
    },
    {
        name: "Irrigated crop",
        value: 2
    },
    {
        name: "Sugar cane",
        value: 3
    },
    {
        name: "Cotton",
        value: 4
    },
    {
        name: "Horticulture",
        value: 5
    }
]


export const electrictySources: DropdownOptionData<number>[] = [
    {
        value: 1,
        name: "State Grid",
    },
    {
        value: 2,
        name: "Renewables",
    }
]

export const vegetationTypes = (region: number): DropdownOptionData<number>[] => {
    const map: { [key: number]: DropdownOptionData<number>[] } = {
        1: [{ name: "Mixed species (Environmental Plantings)", value: 1 },
        { name: "Tasmanian Blue Gum", value: 2 },
        { name: "Sydney Blue Gum", value: 3 },
        { name: "Maritime Pine", value: 4 },
        { name: "Pinus Radiata", value: 5 }],
        2: [],
        3: [],
        4: [{ name: "Mixed species (Environmental Plantings)", value: 1 }],
        5: [{ name: "Mixed species (Environmental Plantings)", value: 1 }],
        6: [],
        7: [],
        8: [{ name: "Mixed species (Environmental Plantings)", value: 1 }],
        9: [],
        10: [{ name: "Mixed species (Environmental Plantings)", value: 1 },
        { name: "Spotted Gum", value: 2 },
        { name: "Dunn's White Gum", value: 3 },
        { name: "Flooded Gum", value: 4 },
        { name: "Slash Pine", value: 5 },
        { name: "Loblolly Pine", value: 6 }],
        11: [{ name: "Mixed species (Environmental Plantings)", value: 1 },
        { name: "Sugar Gum", value: 2 },
        { name: "Tasmanian Blue Gum", value: 3 },
        { name: "Red Ironbark", value: 4 },
        { name: "Radiata Pine (low input)", value: 5 },
        { name: "Radiata Pine (high input)", value: 6 }],
        12: [{ name: "Mixed species (Environmental Plantings)", value: 1 },
        { name: "Sugar Gum", value: 2 },
        { name: "Tasmanian Blue Gum", value: 3 },
        { name: "Red Ironbark", value: 4 },
        { name: "Radiata Pine (low input)", value: 5 },
        { name: "Radiata Pine (high input)", value: 6 }],
        13: [{ name: "Mixed species (Environmental Plantings)", value: 1 },
        { name: "Sugar Gum", value: 2 },
        { name: "Tasmanian Blue Gum", value: 3 },
        { name: "Red Ironbark", value: 4 },
        { name: "Radiata Pine (low input)", value: 5 },
        { name: "Radiata Pine (high input)", value: 6 }],
        14: [{ name: "Mixed species (Environmental Plantings)", value: 1 },
        { name: "Sugar Gum", value: 2 },
        { name: "Tasmanian Blue Gum", value: 3 },
        { name: "Red Ironbark", value: 4 },
        { name: "Radiata Pine (low input)", value: 5 },
        { name: "Radiata Pine (high input)", value: 6 }],
        15: [{ name: "Mixed species (Environmental Plantings)", value: 1 },
        { name: "Sugar Gum", value: 2 },
        { name: "Tasmanian Blue Gum", value: 3 },
        { name: "Red Ironbark", value: 4 },
        { name: "Radiata Pine (low input)", value: 5 },
        { name: "Radiata Pine (high input)", value: 6 }],
        16: [{ name: "Mixed species (Environmental Plantings)", value: 1 },
        { name: "Sugar Gum", value: 2 },
        { name: "Tasmanian Blue Gum", value: 3 },
        { name: "Red Ironbark", value: 4 },
        { name: "Radiata Pine (low input)", value: 5 },
        { name: "Radiata Pine (high input)", value: 6 }],
        17: [{ name: "Mixed species (Environmental Plantings)", value: 1 },
        { name: "Tasmanian Blue Gum", value: 2 },
        { name: "Shining Gum", value: 3 },
        { name: "Radiata Pine (low input)", value: 4 },
        { name: "Radiata Pine (high input)", value: 5 }],
        18: [{ name: "Mixed species (Environmental Plantings)", value: 1 },
        { name: "Tasmanian Blue Gum", value: 2 },
        { name: "Shining Gum", value: 3 },
        { name: "Radiata Pine (low input)", value: 4 },
        { name: "Radiata Pine (high input)", value: 5 }],
        19: [{ name: "Mixed species (Environmental Plantings)", value: 1 },
        { name: "Tasmanian Blue Gum", value: 2 },
        { name: "Shining Gum", value: 3 },
        { name: "Radiata Pine (low input)", value: 4 },
        { name: "Radiata Pine (high input)", value: 5 }],
        20: [{ name: "Mixed species (Environmental Plantings)", value: 1 },
        { name: "Tasmanian Blue Gum", value: 2 },
        { name: "Shining Gum", value: 3 },
        { name: "Radiata Pine (low input)", value: 4 },
        { name: "Radiata Pine (high input)", value: 5 }],
        21: [{ name: "Mixed species (Environmental Plantings)", value: 1 },
        { name: "Tasmanian Blue Gum", value: 2 },
        { name: "Shining Gum", value: 3 },
        { name: "Radiata Pine (low input)", value: 4 },
        { name: "Radiata Pine (high input)", value: 5 }],
        22: [{ name: "Mixed species (Environmental Plantings)", value: 1 },
        { name: "Tasmanian Blue Gum", value: 2 },
        { name: "Shining Gum", value: 3 },
        { name: "Radiata Pine (low input)", value: 4 },
        { name: "Radiata Pine (high input)", value: 5 }],
        23: [{ name: "Mixed species (Environmental Plantings)", value: 1 },
        { name: "Tasmanian Blue Gum", value: 2 },
        { name: "Pinus Radiata", value: 3 }],
        24: [{ name: "Mixed species (Environmental Plantings)", value: 1 },
        { name: "Tasmanian Blue Gum", value: 2 },
        { name: "Pinus Radiata", value: 3 }],
        25: [{ name: "Mixed species (Environmental Plantings)", value: 1 },
        { name: "Tasmanian Blue Gum", value: 2 },
        { name: "Pinus Radiata", value: 3 }],
        26: [{ name: "Mixed species (Environmental Plantings)", value: 1 },
        { name: "Tasmanian Blue Gum", value: 2 },
        { name: "Pinus Radiata", value: 3 }],
        27: [{ name: "Mixed species (Environmental Plantings)", value: 1 },
        { name: "Tasmanian Blue Gum", value: 2 },
        { name: "Pinus Radiata", value: 3 }],
        28: [{ name: "Mixed species (Environmental Plantings)", value: 1 },
        { name: "Sugar Gum", value: 2 },
        { name: "Tasmanian Blue Gum", value: 3 },
        { name: "Shining Gum", value: 4 },
        { name: "Pinus Radiata (low input)", value: 5 },
        { name: "Pinus Radiata (high input)", value: 6 }],
        29: [{ name: "Mixed species (Environmental Plantings)", value: 1 },
        { name: "Sugar Gum", value: 2 },
        { name: "Tasmanian Blue Gum", value: 3 },
        { name: "Shining Gum", value: 4 },
        { name: "Pinus Radiata (low input)", value: 5 },
        { name: "Pinus Radiata (high input)", value: 6 }],
        30: [{ name: "Mixed species (Environmental Plantings)", value: 1 },
        { name: "Sugar Gum", value: 2 },
        { name: "Tasmanian Blue Gum", value: 3 },
        { name: "Shining Gum", value: 4 },
        { name: "Pinus Radiata", value: 5 }],
        31: [{ name: "Mixed species (Environmental Plantings)", value: 1 },
        { name: "Sugar Gum", value: 2 },
        { name: "Tasmanian Blue Gum", value: 3 },
        { name: "Shining Gum", value: 4 },
        { name: "Pinus Radiata", value: 5 }],
        32: [{ name: "Mixed species (Environmental Plantings)", value: 1 },
        { name: "Tasmanian Blue Gum", value: 2 },
        { name: "Shining Gum", value: 3 },
        { name: "Pinus Radiata", value: 4 }],
        33: [{ name: "Mixed species (Environmental Plantings)", value: 1 },
        { name: "Tasmanian Blue Gum", value: 2 },
        { name: "Shining Gum", value: 3 },
        { name: "Mountain Ash", value: 4 },
        { name: "Pinus Radiata", value: 5 }],
        34: [{ name: "Mixed species (Environmental Plantings)", value: 1 },
        { name: "Sugar Gum", value: 2 },
        { name: "Tasmanian Blue Gum", value: 3 },
        { name: "Shining Gum", value: 4 },
        { name: "Pinus Radiata", value: 5 }],
        35: [{ name: "Mixed species (Environmental Plantings)", value: 1 },
        { name: "Sugar Gum", value: 2 },
        { name: "Tasmanian Blue Gum", value: 3 },
        { name: "Shining Gum", value: 4 },
        { name: "Pinus Radiata", value: 5 }],
        36: [{ name: "Mixed species (Environmental Plantings)", value: 1 },
        { name: "Hoop Pine", value: 2 },
        { name: "Lemon-scented Gum", value: 3 },
        { name: "Western White Gum", value: 4 },
        { name: "Blackbutt", value: 5 },
        { name: "Pinus Hybrids", value: 6 }],
        37: [{ name: "Mixed species (Environmental Plantings)", value: 1 },
        { name: "Hoop Pine", value: 2 },
        { name: "Lemon-scented Gum", value: 3 },
        { name: "Western White Gum", value: 4 },
        { name: "Blackbutt", value: 5 },
        { name: "Pinus Hybrids", value: 6 }],
        38: [{ name: "Mixed species (Environmental Plantings)", value: 1 },
        { name: "Hoop Pine", value: 2 },
        { name: "Lemon-scented Gum", value: 3 },
        { name: "Western White Gum", value: 4 },
        { name: "Blackbutt", value: 5 },
        { name: "Pinus Hybrids", value: 6 }],
        39: [{ name: "Mixed species (Environmental Plantings)", value: 1 },
        { name: "Hoop Pine", value: 2 },
        { name: "Lemon-scented Gum", value: 3 },
        { name: "Western White Gum", value: 4 },
        { name: "Blackbutt", value: 5 },
        { name: "Pinus Hybrids", value: 6 }],
        40: [{ name: "Mixed species (Environmental Plantings)", value: 1 },
        { name: "Hoop Pine", value: 2 },
        { name: "Lemon-scented Gum", value: 3 },
        { name: "Western White Gum", value: 4 },
        { name: "Blackbutt", value: 5 },
        { name: "Pinus Hybrids", value: 6 }],
        41: [{ name: "Mixed species (Environmental Plantings)", value: 1 },
        { name: "Hoop Pine", value: 2 },
        { name: "Lemon-scented Gum", value: 3 },
        { name: "Western White Gum", value: 4 },
        { name: "Blackbutt", value: 5 },
        { name: "Pinus Hybrids", value: 6 }],
        42: [{ name: "Mixed species (Environmental Plantings)", value: 1 }],
        43: [{ name: "Mixed species (Environmental Plantings)", value: 1 }],
        44: [{ name: "Mixed species (Environmental Plantings)", value: 1 }],
        45: [{ name: "Mixed species (Environmental Plantings)", value: 1 }],
    }
    return region in map ? map[region] : []
}

export const soilTypes = (region: number): DropdownOptionData<number>[] => {
    const map: { [key: number]: DropdownOptionData<number>[] } = {
        1: [{ name: "Loams & Clays", value: 1 },
        { name: "Sandy Duplexes", value: 2 }],
        2: [],
        3: [],
        4: [{ name: "Coloured Sands", value: 1 },
        { name: "Loams & Clays", value: 2 }],
        5: [{ name: "Loams & Clays", value: 1 },
        { name: "Sandy Duplexes", value: 2 }],
        6: [],
        7: [],
        8: [{ name: "Coloured Sands", value: 1 },
        { name: "Loams & Clays", value: 2 }],
        9: [],
        10: [{ name: "Duplex", value: 1 },
        { name: "Clay & Red Loam", value: 2 }],
        11: [{ name: "Clay", value: 1 },
        { name: "Loam", value: 2 }],
        12: [{ name: "Clay", value: 1 },
        { name: "Loam", value: 2 }],
        13: [{ name: "Clay", value: 1 },
        { name: "Loam", value: 2 }],
        14: [{ name: "Clay", value: 1 },
        { name: "Loam", value: 2 }],
        15: [{ name: "Clay", value: 1 },
        { name: "Loam", value: 2 }],
        16: [{ name: "Clay", value: 1 },
        { name: "Loam", value: 2 }],
        17: [{ name: "Other Soils", value: 1 },
        { name: "Structured Earths", value: 2 }],
        18: [{ name: "Other Soils", value: 1 },
        { name: "Structured Earths", value: 2 }],
        19: [{ name: "Other Soils", value: 1 },
        { name: "Structured Earths", value: 2 }],
        20: [{ name: "Other Soils", value: 1 },
        { name: "Structured Earths", value: 2 }],
        21: [{ name: "Other Soils", value: 1 },
        { name: "Structured Earths", value: 2 }],
        22: [{ name: "Other Soils", value: 1 },
        { name: "Structured Earths", value: 2 }],
        23: [{ name: "Duplex Soils", value: 1 },
        { name: "Cracking Clays", value: 2 }],
        24: [{ name: "Duplex Soils", value: 1 },
        { name: "Cracking Clays", value: 2 }],
        25: [{ name: "Sandy Soils", value: 1 },
        { name: "Loamy Soils", value: 2 }],
        26: [{ name: "Sandy Soils", value: 1 },
        { name: "Cracking Clays", value: 2 }],
        27: [{ name: "Sandy Soils", value: 1 },
        { name: "Loamy Soils", value: 2 }],
        28: [{ name: "Calcarosols", value: 1 },
        { name: "Yellow Duplex", value: 2 }],
        29: [{ name: "Calcarosols", value: 1 },
        { name: "Yellow Duplex", value: 2 }],
        30: [{ name: "Grey Cracking Clays", value: 1 },
        { name: "Red Duplex", value: 2 }],
        31: [{ name: "Grey Cracking Clays", value: 1 },
        { name: "Red Duplex", value: 2 }],
        32: [{ name: "Red Earths", value: 1 },
        { name: "Yellow Duplex", value: 2 }],
        33: [{ name: "Non-cracking Clays", value: 1 },
        { name: "Gradational soils", value: 2 }],
        34: [{ name: "Red Duplex", value: 1 },
        { name: "Yellow Duplex", value: 2 }],
        35: [{ name: "Cracking Clays", value: 1 },
        { name: "Red Duplex", value: 2 }],
        36: [{ name: "Clays", value: 1 },
        { name: "Duplex", value: 2 }],
        37: [{ name: "Clay Gidgee", value: 1 },
        { name: "Open Downs", value: 2 }],
        38: [{ name: "Clay Gidgee", value: 1 },
        { name: "Open Downs", value: 2 }],
        39: [{ name: "Clay", value: 1 },
        { name: "Gradational soils", value: 2 }],
        40: [{ name: "Clay (Brigalo and Belah)", value: 1 },
        { name: "Duplex Woodland", value: 2 }],
        41: [{ name: "Duplex", value: 1 },
        { name: "Earths", value: 2 }],
        42: [{ name: "Kandosols", value: 1 },
        { name: "Tenosols", value: 2 }],
        43: [{ name: "Kandosols", value: 1 },
        { name: "Tenosols", value: 2 }],
        44: [{ name: "Kandosols", value: 1 },
        { name: "Tenosols", value: 2 }],
        45: [{ name: "Kandosols", value: 1 },
        { name: "Tenosols", value: 2 }],
    }
    return region in map ? map[region] : []
}

export const nzVegetationTypes = (): DropdownOptionData<number>[] => {
    return [
        { name: "Pinus Radiata", value: 1 },
        { name: "Douglas fir", value: 2 },
        { name: "Exotic softwoods", value: 3 },
        { name: "Exotic hardwoods", value: 4 },
        { name: "Indigenous forest", value: 5 }
    ]
}

export const nzCattleClasses = (): DropdownOptionData<number>[] => {
    return [
        { name: "Growing cows 0 - 1 years", value: 1 },
        { name: "Growing cows 1 - 2 years", value: 2 },
        { name: "Breeding growing cows 2 - 3 years", value: 3 },
        { name: "Breeding mature cows", value: 4 },
        { name: "Breeding bulls - mixed age", value: 5 },
        { name: "Slaughter heifers 0 - 1 years", value: 6 },
        { name: "Slaughter heifers 1 - 2 years", value: 7 },
        { name: "Slaughters steers 0 - 1 years", value: 8 },
        { name: "Slaughter steers 1 - 2 years ", value: 9 },
        { name: "Slaughter bulls 0 - 1 years", value: 10 },
        { name: "Slaughter bulls 1 - 2 years", value: 11 }
    ]
}

// Note the values/id's follow on from the NZ Cattle Classes above
export const nzSheepClasses = (): DropdownOptionData<number>[] => {
    return [
        { name: "Dry ewes", value: 12 },
        { name: "Mature breeding ewes", value: 13 },
        { name: "Growing breeding sheep", value: 14 },
        { name: "Growing non-breeding sheep", value: 15 },
        { name: "Wethers", value: 16 },
        { name: "Lambs", value: 17 },
        { name: "Rams", value: 18 },
    ]
}

export const AusSheepClasses: DropdownOptionData<number>[] = [
    {
        value: 1,
        name: "Rams"
    },
    {
        value: 2,
        name: "Wethers"
    },
    {
        value: 3,
        name: "Maiden Breeding ewes"
    },
    {
        value: 4,
        name: "Breeding ewes"
    },
    {
        value: 5,
        name: "Other ewes"
    },
    {
        value: 6,
        name: "Ewe lambs"
    },
    {
        value: 7,
        name: "Wether Lambs"
    },
    {
        value: 8,
        name: "Trade lambs and hoggets"
    },
    {
        value: 9,
        name: "Trade wethers"
    },
    {
        value: 10,
        name: "Trade ewes"
    }
]

export const AusBeefClasses: DropdownOptionData<number>[] = [
    {
        value: 1,
        name: "Bulls (1+ years)"
    },
    {
        value: 2,
        name: "Steers (<1 year)"
    },
    {
        value: 3,
        name: "Steers (1-2 years)"
    },
    {
        value: 4,
        name: "Steers (2+ years)"
    },
    {
        value: 5,
        name: "Cows (2+ years)"
    },
    {
        value: 6,
        name: "Heifers (<1 year)"
    },
    {
        value: 7,
        name: "Heifers (1-2 years)"
    },
    {
        value: 8,
        name: "Heifers (2+ years - not calving)"
    },
    {
        value: 9,
        name: "Trade Steers (2+ years)"
    },
    {
        value: 10,
        name: "Trade Steers (1-2 years)"
    },
    {
        value: 11,
        name: "Trade Steers (<1 year)"
    }
]

export const DairyClasses: DropdownOptionData<number>[] = [
    {
        value: 1,
        name: "Milking Cows"
    },
    {
        value: 2,
        name: "Heifers (1+ years)"
    },
    {
        value: 3,
        name: "Heifers (<1 year)"
    },
    {
        value: 4,
        name: "Dairy Bulls (1+ years)"
    },
    {
        value: 5,
        name: "Other Stock (<1 year)"
    },
    {
        value: 6,
        name: "Other Stock (1+ years)"
    },
]

export const mineralSupplementTypes: DropdownOptionData<string>[] = [
    {
        name: "Mineral Block",
        value: "mineral-block",
    },
    {
        name: "Weaner Block",
        value: "weaner-block",
    },
    {
        name: "Dry Season Mix",
        value: "dry-season-mix",
    }
]

export const DairyNFertilisersClasses: DropdownOptionData<string>[] = [
    {
        value: "monoammonium-phosphate",
        name: "Monoammonium Phosphate (MAP)"
    },
    {
        value: "diammonium-phosphate",
        name: "Diammonium Phosphate (DAP)"
    },
    {
        value: "urea-ammonium-nitrate",
        name: "Urea Ammonium Nitrate (UAN)"
    },
    {
        value: "ammonium-nitrate",
        name: "Ammonium Nitrate"
    },
    {
        value: "calcium-ammonium-nitrate",
        name: "Calcium Ammonium Nitrate (CAN)"
    }
]

export const dairyProductionSystems = [
    { name: "Irrigated Pasture", value: "irrigated-pasture" },
    { name: "Dryland Pasture", value: "non-irrigated-pasture" },
    { name: "Irrigated Crop", value: "irrigated-crop" },
    { name: "Dryland Crop", value: "non-irrigated-crop" }
]

export const manureManagementTypes: DropdownOptionData<string>[] = [
    {
        name: "Managed Aerobic Treatment",
        value: "managed-aerobic-treatment",
    },
    {
        name: "Unmanaged Aerobic Treatment",
        value: "unmanaged-aerobic-treatment",
    },
    {
        name: "Anaerobic Digester",
        value: "anaerobic-digester",
    },
    {
        name: "Anaerobic Shallow Lagoon",
        value: "anaerobic-shallow-lagoon",
    },
    {
        name: "Anaerobic Deep Lagoon",
        value: "anaerobic-deep-lagoon",
    }
]

export const truckTypes: DropdownOptionData<string>[] = [
    { name: "Four Deck Trailer", value: "4-deck-trailer" },
    { name: "Six Deck Trailer", value: "6-deck-trailer" },
    { name: "B-Double", value: "b-double" },
]