import { FormData } from "../../../../models/form/form";
import { getCurrentFinancialYear } from "../../../../utilities/dates";
import { InitiativePopUp } from "../initiative_popup";
import { ManageCropInputsDTO } from "../../../../models/reduction_plans_models";
import { Report } from "../../../../models/property_models";
import { SliderFormField } from "../../../../models/form/form_fields/basic_form_fields";
import { getYearField, getDefaultReductionPlanYearOptions, addExistingReductionPlanYearsToOptions } from "../initiative_helpers";
import { FieldSize } from "../../../../models/form/form_fields/form_field";

interface CropInputFormData {
    areaSown: number;
    areaSownTargetYear: number;
    fertiliser: number;
    fertiliserTargetYear: number;
    herbicidePesticide: number;
    herbicidePesticideTargetYear: number;
}

export default function ManageCropInputsPopup(props: {
    initialData?: ManageCropInputsDTO;
    compareReport?: Report;
    onSubmit: (data: ManageCropInputsDTO) => Promise<void>
}) {
    const yearOptions = getDefaultReductionPlanYearOptions()
    const existingReductionPlanYears = [
        props.initialData?.areaSown?.targetYear,
        props.initialData?.fertiliser?.targetYear,
        props.initialData?.herbicidePesticide?.targetYear,
    ].filter(v => v !== undefined) as number[]
    addExistingReductionPlanYearsToOptions(existingReductionPlanYears, yearOptions)

    let reportAreaSown: number | undefined = 0
    let reportFertiliser: number | undefined = 0
    let reportHerbicidePesticide: number | undefined = 0
    if (props.compareReport && props.compareReport?.algorithmVersion < 3.4) {
        reportAreaSown = props.compareReport?.grainInformation?.reduce((val, grain) => val + grain.areaSown, 0)
        reportFertiliser = props.compareReport?.grainInformation?.reduce((val, grain) => val + grain.mapApplied + grain.dapApplied + grain.soaApplied + grain.ureaApplied, 0)
        reportHerbicidePesticide = props.compareReport?.grainInformation?.reduce((val, grain) => val + grain.herbicidePesticideApplied, 0)
    } else if (props.compareReport && props.compareReport?.algorithmVersion > 3.3) {
        reportAreaSown = props.compareReport?.grainInformationPostV3_3?.reduce((val, grain) => val + grain.areaSown, 0)
        reportFertiliser = props.compareReport?.grainInformationPostV3_3?.reduce((val, grain) => val + grain.phosphorus + grain.potassium + grain.sulfur + grain.urea, 0)
        reportHerbicidePesticide = props.compareReport?.grainInformationPostV3_3?.reduce((val, grain) => val + grain.herbicidePesticideApplied, 0)
    }

    const fallbackData: ManageCropInputsDTO = {
        areaSown: {
            targetValue: reportAreaSown ?? 0,
            targetYear: getCurrentFinancialYear()
        },
        fertiliser: {
            targetValue: reportFertiliser ?? 0,
            targetYear: getCurrentFinancialYear(),
        },
        herbicidePesticide: {
            targetValue: reportHerbicidePesticide ?? 0,
            targetYear: getCurrentFinancialYear()
        }
    }
    const initialData: ManageCropInputsDTO = props.initialData ?? fallbackData

    const formData: FormData<CropInputFormData, ManageCropInputsDTO> = {
        fields: {
            areaSown: new SliderFormField({
                required: true,
                sliderProps: {
                    defaultRange: { min: 0, max: 1000 },
                    prevReportValue: reportAreaSown,
                    initialValue: initialData.areaSown?.targetValue ?? 0,
                    unitLabel: "ha",
                    label: "Area Sown (ha)",
                },
            }),
            areaSownTargetYear: getYearField('Year of completion', FieldSize.Full, yearOptions),
            fertiliser: new SliderFormField({
                required: true,
                sliderProps: {
                    defaultRange: { min: 0, max: 1000 },
                    prevReportValue: reportFertiliser,
                    initialValue: initialData?.fertiliser?.targetValue ?? 0,
                    label: "Fertiliser (kg/ha)",
                    unitLabel: "kg/ha",
                },
            }),
            fertiliserTargetYear: getYearField('Year of completion', FieldSize.Full, yearOptions),
            herbicidePesticide: new SliderFormField({
                required: true,
                sliderProps: {
                    defaultRange: { min: 0, max: 1000 },
                    prevReportValue: reportHerbicidePesticide,
                    initialValue: initialData?.herbicidePesticide?.targetValue ?? 0,
                    label: "Herbicide/Pesticide (L)",
                    unitLabel: "L",
                },
            }),
            herbicidePesticideTargetYear: getYearField('Year of completion', FieldSize.Full, yearOptions),
        },
        transformer: {
            in: (value) => ({
                areaSown: value.areaSown?.targetValue,
                areaSownTargetYear: value.areaSown?.targetYear,
                fertiliser: value.fertiliser?.targetValue,
                fertiliserTargetYear: value.fertiliser?.targetYear,
                herbicidePesticide: value.herbicidePesticide?.targetValue,
                herbicidePesticideTargetYear: value.herbicidePesticide?.targetYear,
            }),
            out: (value) => ({
                ...(value.areaSownTargetYear) ? {
                    areaSown: {
                        targetValue: value.areaSown ?? 0,
                        targetYear: value.areaSownTargetYear
                    }
                } : {},
                ...(value.fertiliserTargetYear) ? {
                    fertiliser: {
                        targetValue: value.fertiliser ?? 0,
                        targetYear: value.fertiliserTargetYear
                    }
                } : {},
                ...(value.herbicidePesticideTargetYear) ? {
                    herbicidePesticide: {
                        targetValue: value.herbicidePesticide ?? 0,
                        targetYear: value.herbicidePesticideTargetYear
                    }
                } : {},
            })
        }
    };
    return (
        <InitiativePopUp
            formData={formData}
            initialFormValues={initialData}
            onSubmit={(state) => props.onSubmit(state)}
        />
    );
}
