import styled from "styled-components";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ruminatiColors } from "../../utilities/colors";
import { Column } from "../styled_layout";
import { Row } from "../styled_layout";
import SubButton from "../buttons/sub_button";

import AddImage from "/icons/add-16.svg";
import RemoveImage from "/icons/remove-16.svg";
import { BodyText } from "../styled_text";

export interface Range {
    min: number;
    max: number;
}

function centreOfRange(range: Range): number {
    return range.min + (rangeDistance(range) / 2)
}

function rangeDistance(range: Range): number {
    return range.max - range.min
}

function limitRange(value: number, range: Range): number {
    return Math.max(Math.min(value, range.max), range.min)
}

function fractionRange(value: number, range: Range): number {
    return limitRange((value - range.min) / rangeDistance(range), { min: 0, max: 1 })
}

function evaluateRange(t: number, range: Range): number {
    return range.min + rangeDistance(range) * t
}

export enum SliderType {
    AbsoluteValues,
    Percentage,
    RelativeChangeOfPercentage,
    RelativeChangeOfAbsolute
}

/**
 * A range slider with bubbles displaying the current value
 * @param minValue the minimum value in the range
 * @param initialValue the initial value of the data
 * @param maxValue the maximum value in the range
 * @param label the label appearing on the top-left of the range slider.
 * @param unitLabel the label that will appear below the range slider
 * @param stepDistance the distance a click of a button will travel
 * @param onMove the callback upon movement
 */
export type SharedRangeSliderProps = {
    initialValue: number;
    prevReportValue?: number;
    defaultRange: Range;
    limits?: Partial<Range>;
    unitLabel?: string;
    label?: string;
    onMove?: (x: number) => void;
    topIndicatorPrefix?: string
};

/**
 * A range slider with bubbles displaying the current value
 * For props, see {@link RangeSliderProps}.
 */
export default function BaseRangeSlider(props: SharedRangeSliderProps & {
    sliderType: SliderType
}) {
    const containerRef = useRef<HTMLDivElement>(null);
    const [value, setValue] = useState(props.initialValue ?? props.prevReportValue);
    const [mouseDown, setMouseDown] = useState(false);

    const isPercentageBasedSlider = (props.sliderType === SliderType.RelativeChangeOfPercentage || props.sliderType === SliderType.Percentage)

    const max = Math.max(...([props.limits?.max, props.prevReportValue ? props.prevReportValue * 2 : props.defaultRange.max].filter((a) => a !== undefined) as number[]))
    const min = Math.min(...([props.limits?.min, props.prevReportValue ? 0 : props.defaultRange.min].filter((a) => a !== undefined) as number[]))

    const prevReportValueOrCentreValue = props.prevReportValue !== undefined ? props.prevReportValue : centreOfRange(props.defaultRange)

    const maxDistance = isPercentageBasedSlider ? 100 : Math.max(Math.abs(max - prevReportValueOrCentreValue), Math.abs(prevReportValueOrCentreValue - min))

    const range = isPercentageBasedSlider ? {
        min: 0,
        max: 100
    } : useMemo(() => ({ 
        min: prevReportValueOrCentreValue - maxDistance, 
        max: prevReportValueOrCentreValue + maxDistance 
    }), [prevReportValueOrCentreValue, maxDistance])

    const limits: Range = useMemo(() => ({ min: props.limits?.min ?? Math.max(range.min, 0), max: props.limits?.max ?? range.max }), [props, range])

    const stepDistance = rangeDistance(range) / 10;

    const incrementValue = () => {
        const newValue = limitRange(value + stepDistance, limits);
        setValue(newValue);
        if (props.onMove) props.onMove(newValue);
    };
    const decrementValue = () => {
        const newValue = limitRange(value - stepDistance, limits);
        setValue(newValue);
        if (props.onMove) props.onMove(newValue);
    };

    const tickSections = 10;
    const ticks = Array.from(
        { length: tickSections + 1 },
        (_, index) => (index * 100) / tickSections
    );

    const onStartDrag = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        setMouseDown(true);
        onUpdateSlider(e.pageX);

        e.stopPropagation();
        e.preventDefault();
    };

    const onStopDrag = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        setMouseDown(false);
        e.stopPropagation();
        e.preventDefault();
    };

    const [throttler, setThrottler] = useState(false);

    const onUpdateSlider = useCallback((pageX: number) => {
        if (throttler) return
        if (containerRef.current != null) {
            const container = containerRef.current as HTMLElement;

            const containerLeft = container.getBoundingClientRect().left;
            const containerWidth = container.getBoundingClientRect().width;

            const mouseLeft = Math.min(
                Math.max(pageX, containerLeft),
                containerLeft + containerWidth
            );

            // If initial value === 0, disable new value's calculation
            // until the mouse pointer has moved to/is at least half-way
            // through the slider's container.
            const percentage = (mouseLeft - containerLeft) / containerWidth;

            let newValue = evaluateRange(percentage, range)
            newValue = limitRange(newValue, limits)
            setValue(newValue);
            if (props.onMove) props.onMove(newValue);
        }

        setThrottler(true);

        setTimeout(() => setThrottler(false), 1000 / 30);
    }, [limits, props, range, throttler]);

    const onMouseMove = useCallback((e: MouseEvent) => {
        if (mouseDown) onUpdateSlider(e.pageX)
    }, [mouseDown, onUpdateSlider]);

    const onMouseUp = useCallback((e: MouseEvent) => {
        setMouseDown(false);
        e.stopPropagation();
        e.preventDefault();
    }, []);

    useEffect(() => {
        window.addEventListener('mousemove', onMouseMove);
        window.addEventListener('mouseup', onMouseUp);

        return () => {
            window.removeEventListener('mousemove', onMouseMove);
            window.removeEventListener('mouseup', onMouseUp);
        }
    }, [onMouseUp, onMouseMove])

    function computeRelativePositionInRange (val: number): string {
        return `${fractionRange(val, range) * 100}%`
    }

    function computeRelativeDifferenceBetweenValues (prevValue: number, newValue: number) {
        const position1 = fractionRange(prevValue, range) * 100
        const position2 = fractionRange(newValue, range) * 100
        return `${Math.abs(position1 - position2)}%`
    }

    function computeChangeLabel (prevValue: number | undefined, newValue: number): string | undefined {
        if (prevValue === undefined) return undefined
        if (props.sliderType === SliderType.AbsoluteValues) {
            if (prevValue === 0) return undefined
            const difference = ((newValue - prevValue) * 100) / prevValue 
            return `${Math.round(difference)}%`
        } else if (props.sliderType === SliderType.Percentage) {
            const difference = newValue - prevValue
            return `${Math.round(difference)}%`
        } else if (props.sliderType === SliderType.RelativeChangeOfPercentage) {
            const difference = ((newValue - prevValue) / prevValue) * 100
            return `Relative ${difference < 0 ? 'Decrease' : 'Increase'}: ${Math.round(difference)}%`
        }
        return undefined
    }

    const changeLabel = computeChangeLabel(props.prevReportValue, value)

    return (
        <Column style={{ width: "100%" }}>
            <Row style={{ width: "100%", justifyContent: 'flex-start', marginBottom: '4px' }}>
                <BodyText style={{ textAlign: "start" }}>
                    {" "}
                    {props.label ?? ""}
                </BodyText>{" "}
            </Row>
            <Row style={{ width: "100%" }}>
                <SubButton
                    colorScheme="green"
                    padding="0px"
                    onClick={() => decrementValue()}
                >
                    <img src={RemoveImage} alt="Decrement Range" />
                </SubButton>
                <Column
                    style={{
                        width: "100%",
                        position: "relative",
                        margin: "0px 4px",
                    }}
                    ref={containerRef}
                    onMouseDown={onStartDrag}
                    onMouseUp={onStopDrag}
                    onMouseMove={(e) => { if (mouseDown) { onUpdateSlider(e.pageX); } }}
                    onClick={(e) => {
                        setMouseDown(true);
                        onUpdateSlider(e.pageX);
                        setMouseDown(false);
                    }}
                >
                    <Row style={{ height: "20px" }}>
                        
                        {/* Prev Report Label in green */}
                        {(props.prevReportValue !== undefined && props.sliderType === SliderType.AbsoluteValues) &&
                            <InitialLabel 
                                style={{ left: computeRelativePositionInRange(props.prevReportValue) }}
                            >
                                {Math.round(prevReportValueOrCentreValue)}
                            </InitialLabel>
                        }

                        {/* If a Percentage-based Slider show the % labels */}
                        {props.sliderType !== SliderType.AbsoluteValues &&
                            ticks.filter(t => t > 0 && t < 100).map((t) => {
                                return <InitialLabel style={{ left: t + "%" }}>{Math.round(t)}%</InitialLabel>
                            })
                        }
                  
                        {/* New Value Indicator - Orange box
                            Only visible if change has occured from prev report value
                        */}
                        {value !== props.prevReportValue &&
                            <IndicatorLabel
                             style={{ left: computeRelativePositionInRange(value) }}
                            >
                                <LabelText> 
                                    {props.topIndicatorPrefix && props.topIndicatorPrefix}
                                    {Math.round(value)}{isPercentageBasedSlider && '%'}
                                </LabelText>
                            </IndicatorLabel>
                        }
                        
                    </Row>
                    <Row style={{ width: "100%", height: "20px" }}>
                        <RangeContainer>
                            <RangeScale />
                            {ticks.map((tick, index) => (
                                <RangeTick
                                    style={{
                                        left: tick + "%",
                                        opacity: 1
                                            // tick > diffStart && tick < diffEnd
                                            //     ? 0
                                            //     : 1,
                                    }}
                                    key={index}
                                />
                            ))}

                            {/* Vertical Solid Orange Line
                                Only visible if Value is not the same as Prev Report Value
                            */}
                            {value !== props.prevReportValue &&
                                <RangeIndicator
                                    style={{
                                        left: computeRelativePositionInRange(value),
                                        backgroundColor: ruminatiColors.orange
                                    }}
                                />
                            }

                            {/* Horizontal Orange Line from Prev Report Value to Current Value */}
                            {props.prevReportValue !== undefined && 
                                <DifferenceIndicator
                                    style={{
                                        left: computeRelativePositionInRange(props.prevReportValue < value ? props.prevReportValue : value),
                                        width: computeRelativeDifferenceBetweenValues(props.prevReportValue, value),
                                    }}
                                />
                            }

                            {/* Previous Report Indicator - Solid Green Line
                                Only visible if Prev Report Value defined
                            */}
                            {props.prevReportValue !== undefined &&
                                <RangeIndicator
                                    style={{
                                        left: computeRelativePositionInRange(props.prevReportValue),
                                        backgroundColor: ruminatiColors.green_3
                                    }}
                                />
                            }
                        </RangeContainer>
                    </Row>
                    <Row style={{ height: "20px" }}>
                        {(props.sliderType !== SliderType.Percentage && props.unitLabel) &&
                            <InitialLabel>{props.unitLabel}</InitialLabel>
                        }
                        {(props.prevReportValue !== undefined && props.prevReportValue !== value) && changeLabel && (
                            <IndicatorLabel
                                style={{ left: computeRelativePositionInRange(value) }}
                            >
                                <LabelText>
                                    {changeLabel}
                                </LabelText>
                            </IndicatorLabel>
                        )}
                    </Row>
                </Column>
                <SubButton
                    colorScheme="green"
                    padding="0px"
                    onClick={() => incrementValue()}
                >
                    <img src={AddImage} alt="Increment Range" />
                </SubButton>
            </Row>
        </Column>
    );
}

const RangeScale = styled.div`
    height: 1px;
    flex-grow: 1;
    background-color: ${ruminatiColors.green_3_30};
`;

const RangeTick = styled.div`
    width: 1px;
    height: 8px;
    position: absolute;
    background-color: ${ruminatiColors.green_3_30};
`;

const RangeContainer = styled(Row)`
    height: 20px;
    flex-grow: 1;
`;

const RangeIndicator = styled.div`
    position: absolute;
    width: 4px;
    height: 20px;

    background-color: ${ruminatiColors.orange};
    transform: translate(-50%, 0);
`;

const IndicatorLabel = styled.div`
    position: absolute;
    padding: 2px 4px;
    border-radius: 4px;

    color: #ffffff;
    background-color: ${ruminatiColors.orange};
    transform: translate(-50%, 0);
`;

const DifferenceIndicator = styled.div`
    height: 4px;
    position: absolute;
    background-color: ${ruminatiColors.orange};
`;

const LabelText = styled.p`
    font-family: "Approach", sans-serif;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    letter-spacing: 1%;
    color: #ffffff;
    margin: 0;
`;

const InitialLabel = styled(LabelText)`
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    color: ${ruminatiColors.green_3};
`;
