import styled from "styled-components";
import { BodyText } from "../styled_text";
import { ruminatiColors } from "../../utilities/colors";
import {useMap} from 'react-map-gl/maplibre';
import Icon, { IconType } from "../icon";
import { Row, Column } from "../styled_layout";
import { MouseEventHandler } from "react";

export default function ZoomButtonOverlay () {
  const {myMapA} = useMap();

  const zoomOut = () => { myMapA?.zoomOut() }
  const zoomIn = () => { myMapA?.zoomIn() }
  const padding = "8px 10px"
  const renderZoomButton = (icon: IconType, onClick: MouseEventHandler) => {
    return (
      <Column className="zoom-btn" style={{cursor: 'pointer', transition: '0.3s', height: '100%'}} onClick={onClick}>
        <Row style={{padding: padding, height: '100%'}}>
          <Icon icon={icon}/>
        </Row>
      </Column>
    )
  }

  return (
      <ZoomButtonOverlayWrapper>
          {renderZoomButton('plus-small', zoomIn)}
          {renderZoomButton('minus', zoomOut)}
          <Column style={{padding: padding}}>
            <BodyText>Zoom</BodyText>
          </Column>         
      </ZoomButtonOverlayWrapper>
  )
}

const ZoomButtonOverlayWrapper = styled(Row)`
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 10;
    height: 42px;
    align-items: center;

    background-color: ${ruminatiColors.bone};
    border: 1px solid ${ruminatiColors.dark_green};
    border-radius: 8px;
    overflow: hidden;

    .zoom-btn:hover {
      background-color: ${ruminatiColors.green_3_10};
      svg path {
        fill: ${ruminatiColors.dark_green};
      }
    }
`;

