import { useNavigate, Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { useState, useEffect, useMemo } from "react";
import { checkActionCode, applyActionCode } from "firebase/auth";
import { FirebaseError } from "firebase/app";
import { useRollbar } from "@rollbar/react";

import { convertUnknownErrortoStringable } from "@/utilities/errors";
import Screen from "./screen";
import Heading from "../components/heading";
import { ParagraphText } from "../components/styled_text";
import { Illustration } from "../components/styled_layout";
import { setUserEmailVerified } from "../services/auth_service";
import OutlineButton from "../components/buttons/outline_button";
import LoadingPlaceholder from "../components/loading_placeholder";
import { useAuthStore } from "../state/auth_store";
import { useFirebaseIdentityPlatform } from "../utilities/identityPlatform";
import { sendVerificationEmail } from "../services/auth_service";

function useQuery () {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

/**
 * @returns the Actions Screen component
 */
export default function IdentityActionsScreen() {
  const navigate = useNavigate()
  const query = useQuery()
  const authStore = useAuthStore()
  const rollbar = useRollbar()

  const [performingAction, setPerformingAction] = useState<boolean | undefined>(true);
  const [errorText, setErrorText] = useState<{
    title: string
    message: string,
    btnText?: string,
    link?: string,
    btnOnClick?: () => void
  } | undefined>(undefined);

  // N.B. copied from verify_email_screen.tsx
  // TODO: dry this up
  type EmailState = "unsent" | "sending" | "sent" | "error";
  const [status, setStatus] = useState<EmailState>("unsent");
  const sendVerify = async () => {
    setStatus("sending");
    try {
        if (authStore.user?.email) {
            await sendVerificationEmail(authStore.user.email);
        }
        setStatus("sent");
    } catch (err) {
        setStatus("error");
    }

    // Rate limit sending emails
    setTimeout(() => setStatus("unsent"), 5000);
  };

  const actionMode = query.get("mode") ?? undefined
  const oobCode = query.get("oobCode") ?? undefined
  const identityPlatformApiKey = query.get("apiKey")
  const firebaseAuth = useFirebaseIdentityPlatform(identityPlatformApiKey)

  function findErrorMessageString (unknownErrorEvent: unknown): string {
    if (typeof unknownErrorEvent === "string") {
      return unknownErrorEvent
    } else if (unknownErrorEvent instanceof FirebaseError) {
      return unknownErrorEvent.message
    } else if (unknownErrorEvent instanceof Error && unknownErrorEvent.message) {
      return unknownErrorEvent.message
    } else if (unknownErrorEvent instanceof Error && unknownErrorEvent.name) {
      return unknownErrorEvent.name
    }  
    return ""
  }

  useEffect(() => {
    const handleOOBActions = async () => {
      if (!actionMode || !oobCode || !firebaseAuth || !authStore.user) {
        if (!actionMode) {
          setErrorText({
            title: 'Unexpected Error',
            message: "We couldn't recognise what you were trying to do."
          })
          rollbar.error('OOB error - No action mode', {
            oobCode
          })
        } else if (!oobCode) {
          if (actionMode === 'verifyEmail') {
            setErrorText({
              title: 'Unable to verify your Email address',
              message: 'The required information to complete this process appears to be missing.',
              btnText: 'Retry verification',
              link: '/verifyEmail'
            })
            rollbar.error('OOB error - No oobCode for verifyEmail', {
              actionMode,
            })
          } else if (actionMode === 'resetPassword') {
            setErrorText({
              title: 'Unable to update password',
              message: 'The required information to complete this process appears to be missing.',
              btnText: 'Retry password reset',
              link: '/forgotpassword'
            })
            rollbar.error('OOB error - No oobCode for resetPassword', {
              actionMode,
            })
          }
        } else if (!authStore.user) {
          setErrorText({
            title: 'Unexpected Error',
            message: "We couldn't match this action to your user account."
          })
          rollbar.error('OOB error - No user', {
            actionMode,
            oobCode
          })
        } else {
          // no firebaseAuth, somehow?
          setErrorText({
            title: "Unexpected error",
            message: "We couldn't recognise what you were trying to do."
          })
          rollbar.error('OOB error - No firebaseAuth', {
            actionMode,
            oobCode
          })
        }
        setPerformingAction(false)
        return
      }
      
      try {
        const actionCodeInfo = await checkActionCode(firebaseAuth, oobCode)

        if (actionCodeInfo.data.email !== authStore.user.email) {
          setErrorText({
            title: "Unexpected error",
            message: "It looks like you aren't authorised to perform this action. Please ensure that you are logged in with the same user email address that you're trying to verify the email address for."
          })
          rollbar.error('OOB error - Email Mismatch', {
            actionCodeInfo,
            actionMode,
            oobCode
          })
          setPerformingAction(false)
          return
        }

        if (actionMode === 'verifyEmail') {
          if (authStore.user.emailVerified) {
            // Catch if the user already has a verified email address
            // Perhaps they've clicked the link in the email again
            navigate(`/enterprises`)
            return
          } else {
            try {
              if (actionCodeInfo.operation !== 'VERIFY_EMAIL') {
                rollbar.error('OOB error - Email Verification Mismatch', {
                  actionCodeInfo,
                  actionMode,
                  oobCode
                })
                // setErrorText({
                //   title: "Unexpected error",
                //   message: "We couldn't recognise what you were trying to do."
                // })
                // setPerformingAction(false)
                // return
              }
              await applyActionCode(firebaseAuth, oobCode)
              // TODO: if setUserEmailVerified errors, possible user was verified with Identity Platform but not with our database... some kind of regular check for mis-matches like this? 
              const user = await setUserEmailVerified()
              if (user && authStore.user) {
                authStore.user.emailVerified = true
                navigate(`/verifyEmail`)
                return
              }
            } catch (err) {
              setErrorText({
                title: 'Unable to verify your Email address',
                // TODO: may be some messages we don't want to display to users? could just show the generic error message?
                message: findErrorMessageString(err)
              })
              rollbar.error('OOB error - Email Verification Failed', {
                errorDetails: convertUnknownErrortoStringable(err),
                actionCodeInfo,
                actionMode,
                oobCode
              })
              setPerformingAction(false)
            }
          }
        } else if (actionMode === 'resetPassword') {
          if (actionCodeInfo.operation !== 'PASSWORD_RESET') {
            rollbar.error('OOB error - Password Reset Mismatch', {
              actionCodeInfo,
              actionMode,
              oobCode
            })
            // // generic error in this case
            // setErrorText({
            //   title: "Unexpected error",
            //   message: "We couldn't recognise what you were trying to do."
            // })
            // setPerformingAction(false)
            // return
          }
          navigate(`/resetPassword?oobCode=${oobCode}&apiKey=${identityPlatformApiKey}`)
          return

        } else if (!['verifyEmail', 'resetPassword'].includes(actionMode)) {
          throw Error('Action Mode seems incorrect')
        }
      } catch (err) {

        // if the error is an invalid action code from Firebase, likely expired link
        if (err instanceof FirebaseError && err.code === 'auth/invalid-action-code') {
          // Catch if the user already has a verified email address
          // Perhaps they've clicked the link in the email again
          if (actionMode === 'verifyEmail') {
            if (authStore.user.emailVerified) {
              navigate(`/enterprises`)
              return
            }
          }

          rollbar.error('OOB error - Invalid action code', {
            errorDetails: convertUnknownErrortoStringable(err),
            actionMode,
            oobCode
          })
          setErrorText({
            title: "Link expired",
            message: "The email verification link you used has expired. Please try again.",
            btnText: 'Resend email with link',
            btnOnClick: sendVerify
          })
          setPerformingAction(false)
        } else {
          setErrorText({
            title: "Unexpected error",
            message: findErrorMessageString(err)
          })
          rollbar.error('OOB error - Unknown', {
            errorDetails: convertUnknownErrortoStringable(err),
            actionMode,
            oobCode
          })
          setPerformingAction(false)
          return
        }
      }
    }

    handleOOBActions()
  }, [actionMode, oobCode, navigate, firebaseAuth, authStore.user, identityPlatformApiKey]);

    return (
        <Screen>
          <IdentityActionWrapper>
          {
            performingAction ? <>
              <LoadingPlaceholder />
            </> :
            errorText ? 
              <>
                <Heading level={2}>{errorText.title}</Heading>
                <ParagraphText>{errorText.message}</ParagraphText>
                {
                  errorText.btnOnClick
                  ? (
                    <div style={{marginTop: "40px"}}>
                      <OutlineButton
                        width="200px"
                        onClick={errorText.btnOnClick}
                        disabled={status === 'sending' || status === 'sent'}
                      >
                        {status === 'sending' ? 'Sending...' : status === 'sent' ? 'Sent!' : status === 'error' ? 'Error sending email' : errorText.btnText ?? 'Restart'}
                      </OutlineButton>
                    </div>
                  ) : (
                    <Link to={errorText.link ?? '/'} style={{marginTop: "40px"}}>
                      <OutlineButton width="200px">{errorText.btnText ?? 'Restart'}</OutlineButton>
                    </Link>
                  )
                }
              </>
            : undefined
          }
            <Illustration
                src="/images/feeding.png"
                alt="Farmer feeding two brown cows"
            />
          </IdentityActionWrapper>
        </Screen>
    );
}

const IdentityActionWrapper = styled.div`
    flex-grow: 1;

    width: 100%;
    min-height: 507px;

    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;

    padding: 112px 0 0 0;
`;