import { PropsWithChildren } from "react";
import { Collapse, ConfigProvider } from "antd";

import { ruminatiColors } from "../utilities/colors";
import Icon from "./icon";
import { Column, Row } from "./styled_layout";
import TextButton from "./buttons/text_button";

type CollapsableWrapperProps = {
    id: string;
    label: string;
    border?: boolean;
    startCollapsed?: boolean;
    showDelete?: boolean;
    headerRightContent?: JSX.Element
    onDelete?: () => void;
    onToggle?: () => void;
}

export default function CollapsableWrapper(props: PropsWithChildren<CollapsableWrapperProps>) {
    
    function createRemoveButton () {
    return <Row>
      {props.headerRightContent ? props.headerRightContent : undefined}
      {props.showDelete ?
      <Column style={{paddingRight: '20px'}}>
          <TextButton
              fontSize="12px"
              lineHeight="14px"
              textColor={ruminatiColors.red}
              hoverTextColor={ruminatiColors.orange}
              activeTextColor={ruminatiColors.orange}
              onClick={(e) => {
                  e.stopPropagation()
                  if (props.onDelete) props.onDelete()
              }}
          >
              <Icon icon="bin" />
              Remove
          </TextButton>
      </Column> : undefined
      }
      </Row>
    }

    return (
      <ConfigProvider
      theme={{
        components: {
          Collapse: {
            headerBg: ruminatiColors.bone,
            contentBg: ruminatiColors.bone,
            contentPadding: '0px 0px',
            colorBorder: ruminatiColors.green_3,
            colorTextHeading: ruminatiColors.green_3,
            fontFamily: '"TTInterfaces", sans-serif',
            motionDurationMid: '0.3s',
            motionEaseInOut: 'ease'
          }
        }
      }}>
      <Collapse
      bordered={props.border ?? true}
      expandIconPosition="end"
      size="large"
      style={{
        width: '100%'
      }}
      items={
        [
          {
            key: props.id,
            label: props.label,
            children: props.children,
            extra: createRemoveButton(),
          }
        ]
      }
      expandIcon={(panelProps) => {
        return <div 
          style={{ 
            transform: panelProps.isActive ? "translateY(3px)" : "rotate(-90deg) translateY(1px)", 
            transition: "0.3s ease",
            cursor: 'pointer'
          }}>
          <Icon icon="chevronDown" fillColor={ruminatiColors.green_3} />
        </div>
      }}
      onChange={props.onToggle}
      defaultActiveKey={props.startCollapsed ? [] : [props.id]}
      />
    </ConfigProvider>
    );
}



