import { DataRequestType } from "./vision_invitation";

/**
 * A Vision Organisation
 * @typedef {object} VisionOrganisationDTO
 * @property {string} visionOrgId.required - The organisations unique identifier.
 * @property {string} visionOrgName.required - The organisations name
 */
export type VisionOrganisationDTO = {
  visionOrgId: string
  visionOrgName: string
  visionOrgIcon?: string;
}

/**
 * A Vision Organisation
 */
export type VisionOrganisationBasicDTO = {
  id: string
  name: string
  icon_small_url?: string;
  logo_url?: string
  supportLinkViaRegisterFlow?: boolean
  requireClientReferenceIdDuringRegister?: boolean
}

export enum LinkedFarmStatus {
  SHARED_BY_OWNER = "shared",
  ACCEPTED_BY_ORGANISATION = "accepted",
  UNLINKED_BY_ORGANISATION = "unlinked_by_org",
  UNLINKED_BY_OWNER = "unlinked_by_owner",
  REMOVED_BY_ORGANISATION = "removed_by_org"
}

/**
 * A DTO containing the basic information about a linked farm
 * @typedef {object} LinkedFarmDTO
 * @property {string} id - the id of the link
 * @property {string} visionOrgId - The id of the vision organisation
 * @property {string} propertyId - The id of the property
 * @property {number} partnershipRequestSent - A timestamp for when the link was requested by the farm user
 * @property {number} partnershipRequestAccepted - A timestamp for when the link was accepted by the vision organisation
 */
export type LinkedFarmDTO = {
  id: string;
  visionOrgId: string;
  propertyId: string;
  partnershipRequestSent: string;
  partnershipRequestAccepted: string | null;
  propertyName: string;
  visionOrgName: string;
  status: LinkedFarmStatus;
  dateUnlinked: string | null;
}

/**
 * A DTO containing the required information to initiate number of Linked Farms
 * @typedef {object} CreateLinkedFarmsDTO
 */
export type CreateLinkedFarmsDTO = {
  linkedFarms: CreateLinkedFarmDTO[]
}

/**
 * A DTO containing the required information to initiate a Farm Linkage
 * @typedef {object} CreateLinkedFarmDTO
 * @property {string} visionOrgId - The id of the vision organisation
 * @property {string} propertyId - The ids of the property to share
 */
export type CreateLinkedFarmDTO = {
  visionOrgId: string
  propertyId: string
  shareType: DataRequestType
}

export type CreateLinkShareAll = {
  visionOrgId: string
  userId: string
}

export type LinkShareAllDTO = {
  id: string
  visionOrgId: string
  userId: string
  created: string
  requiredUntil: string | null
}