import styled from "styled-components";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import dayjs from 'dayjs'

import Screen from "../screen";
import Heading from "../../components/heading";
import { BodyText } from "../../components/styled_text";
import EmptyPlaceholder from "../../components/empty_placeholder";

import { LinkedFarmDTO } from "../../models/vision";
import { Column, Row } from "../../components/styled_layout";
import Icon from "../../components/icon";
import { usePopupStore } from "../../state/popup_store";
import { usePartnerStore } from "../../state/partners";
import NotificationCard from "../../components/cards/notification_card";
import { useAuthStore } from "../../state/auth_store";
import { saveUserPreference } from "../../services/auth_service";
import { UserPreferences } from "../../models/api_models";
import CorporateInviteCard from "@/components/cards/corporate_invite";
import { useDatabaseStore } from "@/state/database_store";

export enum CorporatePartnerPopupId {
    ConnectPartner = 'connect-corporate-partner',
    NoPartners = 'no-corporate-partners',
    ShareDetails = 'share-details',
    RemoveShareAll = 'remove-share-all',
    RemoveLinkedEnterprise = 'remove-linked-enterprise'
}

/**
 * The corporate partner screen, which shows the current linkages
 * @returns the Corporate Partner Screen component
 */
export default function CorporatePartnersScreen() {
    const partnerStore = usePartnerStore()
    const popupStore = usePopupStore()
    const authStore = useAuthStore()
    const databaseStore = useDatabaseStore()
    
    const [searchParams] = useSearchParams()
    const inviteIdQuery = searchParams.get("inviteId") || undefined

    const invitations = partnerStore.invitations ?? []
    const linkedFarms = partnerStore.linkedFarms ?? []
    const shareAlls = partnerStore.shareAlls ?? []
    const sortedLinkages = sortNewestToOldestFarms(linkedFarms)

    const user = authStore.user

    const [dismissedCorporatePartners, setDismissedCorporatePartners] = useState<boolean>(user?.userPreferences?.notifications?.dismissedCorporatePartners ?? false)

    useEffect(() => {
        if (inviteIdQuery &&
            popupStore.popupStack.find(p => p.id === CorporatePartnerPopupId.ConnectPartner) === undefined
        ) {
            const invite = invitations.find(i => i.id === inviteIdQuery)
            if (invite) {
                popupStore.addPopup(CorporatePartnerPopupId.ConnectPartner, undefined, {
                    shareAll: true,
                    inviteId: inviteIdQuery,
                    visionOrgName: invite.visionOrgName
                })
            }
        }
    })

    const saveNotificationPreference = async () => {
        try {
            if (!user) return
            setDismissedCorporatePartners(true)
            const modifiedUserPreferences: UserPreferences = {
                ...user.userPreferences,
                notifications: {
                    ...user.userPreferences?.notifications,
                    dismissedCorporatePartners: true
                }
            }
            const response = await saveUserPreference(modifiedUserPreferences);
            if (response) authStore.signIn(response)
        } catch (err) {
            console.log(err)
        }
    };

    return (
        <Screen pageTitle="Corporate Partners">
            <CorporatePartnerWrapper>
                <CorporatePartnerContainer>
                    {dismissedCorporatePartners === false &&
                        <Row style={{marginBottom: '20px'}}>
                            <NotificationCard
                                title={"Connecting with Corporate Partners"}
                                description={"Ruminati lets you share Emissions Report results, as well as Reduction Plans, from each of your enterprises with Corporate Partners. To get started, a Corporate Partner will need to send a request before you are able to share your data with them."}
                                contentArea={
                                    <Row style={{justifyContent: 'flex-start', width: '100%'}}>
                                        <Icon icon="info-circle" />
                                        <BodyText style={{paddingLeft: '10px'}}>
                                            A Corporate Partner will need to approve your data sharing request before data will be shared between both parties.
                                        </BodyText>
                                    </Row>}
                                onClick={saveNotificationPreference}
                            />
                        </Row>
                    }
                    <Row style={{ marginBottom: "20px", justifyContent: 'left' }}>
                        <Column style={{ alignItems: "start" }}>
                            <Heading level={3}>Corporate Partners</Heading>
                        </Column>
                    </Row>

                    <Row style={{ marginBottom: "80px" }}>
                        <Column style={{ width: "100%" }}>
                            {invitations.length === 0 ?
                                <EmptyPlaceholder>
                                    No Corporate Partners have requested your data yet.
                                </EmptyPlaceholder>
                                :
                                invitations.map(invitation => {
                                    const existingOrgLinkedEnterprises = sortedLinkages.filter(l => l.visionOrgId === invitation.visionOrgId)
                                    const orgShareAll = shareAlls.find(share => share.visionOrgId === invitation.visionOrgId)
                                    return <CorporateInviteCard
                                        key={invitation.id}
                                        invite={invitation}
                                        linkedEnterprises={existingOrgLinkedEnterprises}
                                        shareAll={orgShareAll}
                                        propertyCount={databaseStore.properties?.length ?? 0}
                                        showInviteDate={true}
                                    />
                                })
                            }
                        </Column>
                    </Row>
                </CorporatePartnerContainer>
            </CorporatePartnerWrapper>
        </Screen>
    );
}

function sortNewestToOldestFarms (unsortedLinkedFarms: LinkedFarmDTO[]): LinkedFarmDTO[] {
    return unsortedLinkedFarms.sort((a, b) => {
        if (dayjs(a.partnershipRequestSent).isBefore(dayjs(b.partnershipRequestSent))) return 1
        return -1
    });
}

const CorporatePartnerWrapper = styled.div`
    display: flex;
    align-items: center;

    flex-direction: column;

    width: 100%;
    flex-grow: 1;

    padding-top: 60px;
`;

const CorporatePartnerContainer = styled.div`
    width: 100%;
    max-width: 936px;
    text-align: left;
`;