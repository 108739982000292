import {
    Dispatch,
    ForwardedRef,
    forwardRef,
    useCallback,
    useEffect,
    useState,
} from "react";
import styled from "styled-components";
import LoadingButton from "../../components/buttons/loading_button";
import { ErrorText } from "../../components/styled_text";
import { FieldElement } from "./form_fields/form_field";
import { IconType } from "../../components/icon";
import { Centre } from "../../components/styled_layout";
import { useImperativeHandle } from "react";
import { flushSync } from "react-dom";
import { FormFields } from "./form_fields/form_fields";

export type FieldID = string | number | symbol;
export type FormState = { [key: FieldID]: any };

export interface FormDataTransformer<T extends FormState, U> {
    in: (value: U) => Partial<T>;
    out: (value: T) => U;
}

export interface FormData<T extends FormState, U = T> {
    fields: { [I in keyof T]: FieldElement<T[I]> };
    transformer?: FormDataTransformer<T, U>;
}

interface FormProps<T extends FormState, U = T> {
    data: FormData<T, U>;
    initialValue: Partial<U>;
    onSubmit: (value: U) => Promise<void>;
    onChange?: (value: U) => Promise<void>;
    error?: string;
    width?: string;
    submitText?: string;
    buttonWidth?: string;
    buttonSize?: "small" | "medium" | "large";
    fieldSize?: "small" | "large";
    rowGapSize?: string;
    hideSubmitButton?: boolean;
}

export interface FormRef {
    validate: () => boolean;
    getState: () => FormState;
    submit: () => Promise<void>;
}

export function Form<T extends FormState, U extends FormState = T>(
    props: FormProps<T, U>
): JSX.Element {
    const transformInput = useCallback(
        (value: U): Partial<T> => {
            if (props.data.transformer) {
                return props.data.transformer.in(value)
            }
            return value as any;
        },
        [props.data]
    );

    const transformOutput = (value: T): U => {
        if (props.data.transformer) {
            return props.data.transformer!.out(value);
        }
        return value as any;
    };

    const [formState, setFormState]: [T, Dispatch<T>] = useState(transformInput(props.initialValue as U) as T);

    useEffect(() => {
        setFormState(transformInput(props.initialValue as U) as T)
    }, [props.initialValue, transformInput]);

    const state = {
        get: formState,
        set: (state: T) => {
            setFormState(state)
            if (props.onChange) {
                props.onChange(transformOutput(state))
            }
        },
        index: 0,
    };

    const validate = (): boolean => {
        return Object.keys(props.data.fields).reduce(
        (prev, curr) =>
            prev &&
            (
                (!props.data.fields[curr]!.required(state) && formState[curr] === undefined) 
                || props.data.fields[curr]!.validate(formState[curr], state)
            ),
        true
    );
    };

    return (
        <FormWrapper
            onSubmit={(e) => {
                e.preventDefault();
            }}
            style={{
                rowGap: props.rowGapSize ? props.rowGapSize : props.fieldSize === "small" ? "14px" : "16px",
                columnGap: '8px',
                ...(props.width !== undefined ? { width: props.width } : {}),
            }}
        >
            <FormFields
                data={props.data}
                state={state}
                getValue={(field) => formState[field]}
                onChange={(field, value) => {
                    const newState = {
                        ...formState,
                        [field]: value,
                    };
                    setFormState(newState);
                    if (props.onChange !== undefined) {
                        return props.onChange(transformOutput(newState as T));
                    }
                }}
                fieldSize={props.fieldSize} />

            {props.error && (
                <Centre style={{ padding: "8px 0 8px 0" }}>
                    <ErrorText>{props.error}</ErrorText>
                </Centre>
            )}

            {!props.hideSubmitButton && (
                <Centre
                    style={{
                        marginTop: "16px",
                    }}
                >
                    <LoadingButton
                        size={props.buttonSize ?? "large"}
                        disabled={!validate()}
                        onClick={() => {
                            return props.onSubmit(transformOutput(formState as T))
                        }}
                        width={props.buttonWidth}
                    >
                        {props.submitText ?? "Submit"}
                    </LoadingButton>
                </Centre>
            )}
        </FormWrapper>
    );
}

/**
 * {@link Form} with {@link ForwardedRef}.
 * There is probably a better way to expose the necessary functions without
 * {@link useRef} and {@link useImperativeHandle}, this is
 * just a temporary work-around since there are currently too many
 * components implementing {@link Form}.
 * @todo refactor.
 */
export function FormWithRef<T extends FormState, U extends FormState = T>(
    props: FormProps<T, U>,
    ref: ForwardedRef<FormRef>
): JSX.Element {
    const transformInput = useCallback(
        (value: U): Partial<T> => {
            if (props.data.transformer) {
                return props.data.transformer.in(value)
            }
            return value as any;
        },
        [props.data]
    );

    const transformOutput = (value: T): U => {
        if (props.data.transformer) {
            return props.data.transformer!.out(value);
        }
        return value as any;
    };

    const [formState, setFormState]: [T, Dispatch<T>] = useState(transformInput(props.initialValue as U) as T);

    // N.B. seemed to be causing issues with the form state not being updated when changing property type to crops
    // useEffect(() => {
    //     setFormState(transformInput(props.initialValue as U) as T)
    // }, [props.initialValue, transformInput]);

    const state = {
        get: formState,
        set: (state: T) => {
            setFormState(state)
            if (props.onChange) {
                props.onChange(transformOutput(state))
            }
        },
        index: 0,
    };

    const onSubmit = () => {
        return props.onSubmit(transformOutput(formState as T))
    }

    const validate = (): boolean => {
        return Object.keys(props.data.fields).reduce(
            (prev, curr) =>
                prev &&
                ((!props.data.fields[curr]!.required(state) &&
                    formState[curr] === undefined) ||
                    props.data.fields[curr]!.validate(formState[curr], state)),
            true
        );
    };

    // Exposes the functions needed for other components to implement
    // this form without the built-in Add and Submit buttons.
    useImperativeHandle(ref, () => ({
        validate: (): boolean => validate(),
        getState: (): FormState => transformOutput(formState),
        submit: (): Promise<void> => onSubmit(),
    }));

    return (
        <FormWrapper
            onSubmit={(e) => {
                e.preventDefault();
            }}
            style={{
                rowGap: "8px",
                ...(props.width !== undefined ? { width: props.width } : {}),
            }}
        >
            <FormFields
                data={props.data}
                state={state}
                getValue={(field) => formState[field]}
                onChange={(field, value) => {
                    const newState = {
                        ...formState,
                        [field]: value,
                    };
                    setTimeout(() => {
                        flushSync(() => {
                            setFormState(newState);
                        });
                        if (props.onChange !== undefined) {
                            return props.onChange(transformOutput(newState as T))
                        }
                    })
                }}
                fieldSize={props.fieldSize} />

            {props.error && (
                <Centre style={{ padding: "8px 0 8px 0" }}>
                    <ErrorText>{props.error}</ErrorText>
                </Centre>
            )}

            {!props.hideSubmitButton && (
                <Centre
                    style={{
                        marginTop: "8px",
                    }}
                >
                    <LoadingButton
                        size={props.buttonSize ?? "large"}
                        disabled={!validate()}
                        onClick={onSubmit}
                        width={props.buttonWidth}
                    >
                        {props.submitText ?? "Submit"}
                    </LoadingButton>
                </Centre>
            )}
        </FormWrapper>
    );
}

export const ForwardedRefForm = forwardRef(FormWithRef);

export type SelectOption = {
    value: string;
    text: string;
    icon?: IconType; // Icon to show to the left of text
};

const FormWrapper = styled.form`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    > * {
        margin-top: 4px;
        margin-bottom: 4px;
    }
`;
