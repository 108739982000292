import { DropdownFormField, TextFormField, NumberFormField, RadioBoxFormField } from "../../../models/form/form_fields/basic_form_fields"
import { SeparatorField, LabelField } from "../../../models/form/form_fields/decoration_form_fields"
import { State, ConcatMethod, FieldSize, NumberType } from "../../../models/form/form_fields/form_field"
import { SeasonalInputFormField } from "../../../models/form/form_fields/seasonal_input_fields"
import { ReportFormPage } from "../../../models/form/form_page"
import { AusBeefClasses, AusSheepClasses } from "../../../models/form_options"
import { LivestockClassAUS, LivestockCattleMiscAUS, SeasonalFigures, LivestockSheepMiscAUS, ReportLivestock } from "../../../models/report"
import { ReportFormPageId, applyNumberTransformerToObject, TransformType, inputPercent, outputPercent } from "../../../utilities/forms"
import DSEButton from "../../../components/buttons/dse_button";
import { DSEUnit, convertDSEtoAE, convertAEtoDSE } from "../../../utilities/dse";
import { formatNumber } from "../../../utilities/functions";
import LiveweightGainCalculator from "@/components/buttons/liveweight_gain_calculator"
import { HelpDrawerExplanatoryContentText } from "@/components/HelpDrawer"

export function cattleClassesAus(reportFinancialYear: number): ReportFormPage<LivestockClassAUS> {
  return {
      id: ReportFormPageId.LivestockCattleClassAus,
      title: "Cattle",
      repeatableConfig: {
          repeatableSectionName: "Cattle Class",
          repeatableSectionCallback: (formData: Partial<LivestockClassAUS>) => {
              const cattleClass = AusBeefClasses.find(c => c.value === formData.classId)
              const ccName = cattleClass ? cattleClass?.name : 'Cattle Class'
              return `${ccName}${formData.name ? ' - ' + formData.name : ''}`
          }
      },
      image: "cattle-purchases.png",
      formData: {
          fields: {
              classId: new DropdownFormField({
                  required: true,
                  label: 'Cattle Class',
                  placeholder: "Cattle class",
                  options: AusBeefClasses,
                  concatMethod: ConcatMethod.StringList,
                  size: FieldSize.Half
              }),
              name: new TextFormField({
                  label: "Mob Name",
                  size: FieldSize.Half,
                  required: false
              }),
              headcount: new SeasonalInputFormField({
                  title: 'Average headcount for season',
                  numberType: NumberType.Int,
                  financialYear: reportFinancialYear
              }),
              liveweight: new SeasonalInputFormField({
                  title: 'Average liveweight for season',
                  numberType: NumberType.Float,
                  allUnits: 'kg/hd',
                  financialYear: reportFinancialYear,
                  singleValidator: (value) => value !== undefined && value <= 1500
              }),
              liveweightGain: new SeasonalInputFormField({
                  title: 'Average daily liveweight gain',
                  numberType: NumberType.Float,
                  allUnits: 'kg/hd/day',
                  financialYear: reportFinancialYear,
                  singleValidator: (value) => value !== undefined && value <= 3,
                  additionalAction: (state: State<LivestockClassAUS>) => {
                    return <LiveweightGainCalculator state={state} />
                  }
              }),
          }
      },
      helpDrawerContent: {
        title: "Adding Cattle",
        content: (
            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                <HelpDrawerExplanatoryContentText style={{ fontWeight: 700 }}>Scope 1</HelpDrawerExplanatoryContentText>
                <HelpDrawerExplanatoryContentText>Use this page to add your cattle information by class. Click on the ‘Add Cattle Class’ button again to add any additional classes you need.</HelpDrawerExplanatoryContentText>
                <HelpDrawerExplanatoryContentText><strong>Ruminati Tip:</strong> Once you’ve entered average liveweight and headcount, use the ‘Calculate’ button to automatically calculate the Average Daily Liveweight Gain for each class.</HelpDrawerExplanatoryContentText>
            </div>
        ),
        videoLink: "https://www.youtube.com/embed/iGRl3jVui7Y?si=uLst4R6CT9-6_9KF&loop=1&rel=0"
      }
  }
}

export function cattleMiscAus(reportFinancialYear: number): ReportFormPage<LivestockCattleMiscAUS> {
  return {
      id: ReportFormPageId.LivestockCattleMiscAus,
      title: "Calving",
      image: "cattle-purchases.png",
      formData: {
          fields: {
              percentageCalving: new SeasonalInputFormField({
                  title: 'Percentage of cows calving',
                  numberType: NumberType.Int,
                  allUnits: '%',
                  financialYear: reportFinancialYear
              })
          },
          transformer: {
              in: (value) => ({
                  ...value,
                  percentageCalving: applyNumberTransformerToObject(value.percentageCalving, TransformType.InputPercent) as SeasonalFigures
              }),
              out: (value) => ({
                  ...value,
                  percentageCalving: applyNumberTransformerToObject(value.percentageCalving, TransformType.OutputPercent) as SeasonalFigures
              })
          }
      },
      helpDrawerContent: {
        title: "Tracking Calving",
        content: (
            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                <HelpDrawerExplanatoryContentText style={{ fontWeight: 700 }}>Scope 1</HelpDrawerExplanatoryContentText>
                <HelpDrawerExplanatoryContentText>Enter the percentage of calves born in each season on this page. If there are none, make sure to enter 0 into the field.</HelpDrawerExplanatoryContentText>
            </div>
        ),
        videoLink: "https://www.youtube.com/embed/zHPBzWfB6R8?si=aCrBaqGC8mauWX1n&loop=1&rel=0"
      }
  }
}

export function sheepClassesAus(reportFinancialYear: number): ReportFormPage<LivestockClassAUS> {
  return {
      id: ReportFormPageId.LivestockSheepClassAus,
      title: "Sheep",
      repeatableConfig: {
          repeatableSectionName: "Sheep Class",
          repeatableSectionCallback: (formData: LivestockClassAUS) => {
              const sheepClass = AusSheepClasses.find(c => c.value === formData.classId)
              const scName = sheepClass ? sheepClass?.name : 'Sheep Class'
              return `${scName}${formData.name ? ' - ' + formData.name : ''}`
          }
      },
      image: "sheep-purchases.png",
      formData: {
          fields: {
              classId: new DropdownFormField({
                  required: true,
                  label: 'Sheep Class',
                  placeholder: "Sheep class",
                  options: AusSheepClasses,
                  concatMethod: ConcatMethod.StringList,
                  size: FieldSize.Half
              }),
              name: new TextFormField({
                  label: "Mob Name",
                  size: FieldSize.Half,
                  required: false
              }),
              headcount: new SeasonalInputFormField({
                  title: 'Average headcount for season',
                  numberType: NumberType.Int,
                  financialYear: reportFinancialYear
              }),
              liveweight: new SeasonalInputFormField({
                  title: 'Average liveweight for season',
                  numberType: NumberType.Float,
                  allUnits: 'kg/hd',
                  financialYear: reportFinancialYear,
                  singleValidator: (value) => value !== undefined && value <= 150
              }),
              liveweightGain: new SeasonalInputFormField({
                  title: 'Average daily liveweight gain',
                  numberType: NumberType.Float,
                  allUnits: 'kg/hd/day',
                  financialYear: reportFinancialYear,
                  singleValidator: (value) => value !== undefined && value <= 1,
                  additionalAction: (state: State<LivestockClassAUS>) => {
                    return <LiveweightGainCalculator state={state} />
                  }
              }),
          }
      },
      helpDrawerContent: {
        title: "Adding Sheep",
        content: (
            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                <HelpDrawerExplanatoryContentText style={{ fontWeight: 700 }}>Scope 1</HelpDrawerExplanatoryContentText>
                <HelpDrawerExplanatoryContentText>Use this page to add your sheep information by class. Click on the ‘Add Sheep Class’ button again to add any additional classes you need.</HelpDrawerExplanatoryContentText>
                <HelpDrawerExplanatoryContentText><strong>Ruminati Tip:</strong> Once you’ve entered average liveweight and headcount, use the ‘Calculate’ button to automatically calculate the Average Daily Liveweight Gain for each class.</HelpDrawerExplanatoryContentText>
            </div>
        ),
        videoLink: "https://www.youtube.com/embed/gBv2kysKD_Q?si=HDVJ308snqc2bGFv&loop=1&rel=0"
      }
  }
}

export function sheepMiscAus(reportFinancialYear: number): ReportFormPage<LivestockSheepMiscAUS> {
  return {
      id: ReportFormPageId.LivestockSheepMiscAus,
      title: "Lambing & Wool",
      image: "sheep-purchases.png",
      formData: {
          fields: {
            proportionEwesLambing: new SeasonalInputFormField({
                title: 'Proportion of Ewes Lambing',
                allUnits: '%',
                financialYear: reportFinancialYear
            }),  
            seasonalLambingRates: new SeasonalInputFormField({
                  title: 'Seasonal Lamb Marking Rates',
                  numberType: NumberType.Int,
                  allUnits: '%',
                  financialYear: reportFinancialYear
              }),
              productionSeperator: new SeparatorField(),
              productionLabel: new LabelField({
                  label: "Wool Production Information"
              }),
              greasyWoolKgs: new NumberFormField({
                  required: true,
                  concatMethod: ConcatMethod.Total,
                  label: "Greasy Wool Produced",
                  unit: "kg",
                  size: FieldSize.Third,
              }),
              cleanWoolYieldPercentage: new NumberFormField({
                  required: true,
                  concatMethod: ConcatMethod.Total,
                  label: "Clean Wool Yield",
                  unit: "%",
                  size: FieldSize.Third,
              }),
              numberSheepShorn: new NumberFormField({
                  required: true,
                  concatMethod: ConcatMethod.Total,
                  label: "Number sheep shorn",
                  size: FieldSize.Third,
              }),
          },
          transformer: {
              in: (value) => ({
                  ...value,
                  seasonalLambingRates: applyNumberTransformerToObject(value.seasonalLambingRates, TransformType.InputPercent) as SeasonalFigures,
                  proportionEwesLambing: applyNumberTransformerToObject(value.proportionEwesLambing, TransformType.InputPercent) as SeasonalFigures,
                  cleanWoolYieldPercentage: inputPercent(value.cleanWoolYieldPercentage),
              }),
              out: (value) => ({
                  ...value,
                  seasonalLambingRates: applyNumberTransformerToObject(value.seasonalLambingRates, TransformType.OutputPercent) as SeasonalFigures,
                  proportionEwesLambing: applyNumberTransformerToObject(value.proportionEwesLambing, TransformType.OutputPercent) as SeasonalFigures,
                  cleanWoolYieldPercentage: outputPercent(value.cleanWoolYieldPercentage),
              })
          }
      },
      helpDrawerContent: {
        title: "Adding Lambing & Wool Data",
        content: (
            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                <HelpDrawerExplanatoryContentText style={{ fontWeight: 700 }}>Scope 1</HelpDrawerExplanatoryContentText>
                <HelpDrawerExplanatoryContentText>Enter your lambing and wool data on this page, including your seasonal lamb marking rates, percentage of ewes lambing and wool production information. If your answer is zero, make sure to enter 0 into the field.</HelpDrawerExplanatoryContentText>
            </div>
        ),
        videoLink: "https://www.youtube.com/embed/wXvtphCpdms?si=puYu0ejv_8Tg90ci&loop=1&rel=0"
      }
  }
}

export const livestock = (hasCattle: boolean, hasSheep: boolean): ReportFormPage<{ beef?: number; sheep?: number; units: string; }, ReportLivestock> => ({
    id: ReportFormPageId.Livestock,
    title: "Livestock",
    image: "livestock.png",
    formData: {
        fields: {
            units: new RadioBoxFormField({
                required: true,
                label: "What is your preferred measurement?",
                options: [
                    {
                        name: "DSE",
                        value: DSEUnit.DSE.toString(),
                    },
                    {
                        name: "AE",
                        value: DSEUnit.AE.toString(),
                    },
                ],
            }),
            ...(hasCattle
                ? {
                    beef: new NumberFormField({
                        required: true,
                        label: (state) =>
                            state.get.units === DSEUnit.AE
                                ? "Beef AE"
                                : "Beef DSE",
                        tip: "Please enter the annual average number of beef AE or DSE on your farm. If you don't know, click 'calculate now' to use our simple calculator.",
                        children: (state) => (
                            <DSEButton
                                type="beef"
                                unit={
                                    state.get.units === DSEUnit.AE
                                        ? "ae"
                                        : "dse"
                                }
                                onSubmit={(val) => {
                                    val = formatNumber(val, {
                                        maxDecimalSigFig: 5,
                                    });
                                    state.set({
                                        ...state.get,
                                        beef: val,
                                    });
                                }}
                            />
                        ),
                    }),
                }
                : {}),
            ...(hasSheep
                ? {
                    sheep: new NumberFormField({
                        required: true,
                        label: (state) =>
                            state.get.units === DSEUnit.AE
                                ? "Sheep AE"
                                : "Sheep DSE",
                        tip: "Please enter the annual average number of sheep AE or DSE on your farm. If you don't know, click 'calculate now' to use our simple calculator.",
                        children: (state) => (
                            <DSEButton
                                type="sheep"
                                unit={
                                    state.get.units === DSEUnit.AE
                                        ? "ae"
                                        : "dse"
                                }
                                onSubmit={(val) => {
                                    val = formatNumber(val, {
                                        maxDecimalSigFig: 5,
                                    });
                                    state.set({
                                        ...state.get,
                                        sheep: val,
                                    });
                                }}
                            />
                        ),
                    }),
                }
                : {}),
        },
        transformer: {
            in: (value) => {
                const units = value.beefUnits ?? DSEUnit.DSE;
                return {
                    beef:
                        units === DSEUnit.AE
                            ? convertDSEtoAE(value.beefDSE)
                            : value.beefDSE,
                    sheep:
                        units === DSEUnit.AE
                            ? convertDSEtoAE(value.sheepDSE)
                            : value.sheepDSE,
                    units: units,
                };
            },
            out: (value) => {
                return {
                    sheepDSE:
                        value.units === DSEUnit.DSE
                            ? value.sheep ?? 0
                            : convertAEtoDSE(value.sheep ?? 0),
                    beefDSE:
                        value.units === DSEUnit.DSE
                            ? value.beef ?? 0
                            : convertAEtoDSE(value.beef ?? 0),
                    sheepUnits: value.units,
                    beefUnits: value.units,
                };
            },
        },
    },
});
  