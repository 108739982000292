import { trimDecimalPlaces } from "./numbers";

export enum TransformType {
  InputPercent = 'inputPercent',
  OutputPercent = 'outputPercent'
}

export const inputPercent = (value?: number): number | undefined => {
  return value !== undefined ? trimDecimalPlaces(value * 100) : undefined
}

export const outputPercent = (value?: number): number => {
  return value !== undefined ? value / 100 : 0;
}

// Handle multi-percentage outputs, where not all values may be provided
export const outputSplitPercent = <T extends Record<string, number | undefined>>(value: T | undefined, count: number, key: keyof T): number | undefined => {
  const total = value ? Object.keys(value).reduce<number>((total, k) => total + (value[k] ?? 0), 0) : 0;

  const filled = value ? Object.keys(value).reduce<number>((total, k) => total + (value[k] !== undefined ? 1 : 0), 0) : 0;
  const unfilled = count - filled;

  return (value && value[key] !== undefined) ? (value[key] ?? 0) / 100 : ((100 - total) / unfilled) / 100
}

export function applyNumberTransformerToObject(input: { [key: string]: number | undefined }, transformType: TransformType): { [key: string]: number | undefined } | undefined {
  if (input === undefined) return undefined
  const output = { ...input }
  for (const [key, value] of Object.entries(input)) {
    switch (transformType) {
      case TransformType.InputPercent:
        output[key] = inputPercent(value)
        break;
      case TransformType.OutputPercent:
        output[key] = outputPercent(value)
        break;
    }
  }
  return output
}

export enum ReportFormPageId {
  GrainInfo = "grainInformation",
  GrainInfoPost3_3 = "grainInformationPostV3_3",
  SupplementaryFeed = "supplementaryFeedInformation",
  SheepPurchases = "sheepPurchasesInformation",
  CattlePurchases = "cattlePurchasesInformation",
  Trees = "treesInformation",
  Livestock = "livestockInformation",
  LivestockCattleClassAus = "livestockInformationCattleClassAus",
  LivestockCattleMiscAus = "livestockInformationCattleMiscAus",
  LivestockSheepClassAus = "livestockInformationSheepClassAus",
  LivestockSheepMiscAus = "livestockInformationSheepMiscAus",
  livestockInformationCattleNZ = "livestockInformationCattleNZ",
  livestockInformationSheepNZ = "livestockInformationSheepNZ",
  Consumables = "consumablesInformation",
  Pasture = "pastureInformation",
  Production = "productionInformation",
  Rations = "rationsInformation",
  FeedlotCohorts = "feedlotCohortsInformation",
  EnergySources = "consumablesInformation",
  Refrigerants = "refrigerantsInformation",
  Methane = "methaneInformation",
  ProcessorProduction = "processorProductionInformation",
  PurchasedGoods = "purchasedGoodsTonnesCO2EInformation",
  MineralSupplements = "mineralSupplementsInformation",
  WaterUse = "processorWaterUseInformation",
  EffluentManagement = "effluentManagementInformation",
  DairyLivestock = "dairyCattleClassInformation",
  ManureManagement = "dairyManureManagementInformation",
  DairyProductionSystemInputs = "dairyProductionSystemInputsInformation",
  DairyNFertilisers = "dairyNFertilisers",
  DairyTransport = "dairyTransportInformation",
  DairyProduction = "dairyProductionInformation",
  ReportDairyProduction = "dairyProduction"
}
