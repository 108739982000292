import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { Provider as RollbarProvider, ErrorBoundary as RollbarErrorBoundary } from '@rollbar/react';
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import ReactGA from "react-ga4";
import Hotjar from '@hotjar/browser'
import "maplibre-gl/dist/maplibre-gl.css";

import ScrollToTop from "./hooks/scrollToTop";

import ForgotPasswordScreen from "./screens/forgot_password_screen";
import FarmsScreen from "./screens/farms";
import FarmScreen from "./screens/farm/farm";
import FarmEditScreen from "./screens/farm-edit";
import FormScreen from "./screens/form/form_screen";
import CreateReportScreen from "./screens/create_report";
import ReductionPlannerScreen from "./screens/reduction_planner/reduction_planner_screen";

import LoginScreen from "./screens/login_screen";
import SignUpScreen from "./screens/sign_up_screen";
import VerifyEmailScreen from "./screens/verify_email_screen";
import CheckEmailVerificationScreen from "./screens/check_verification_screen";
import GetStartedScreen from "./screens/get_started_screen";

import ReportScreen from "./screens/report/report_screen";
import PopupManager from "./components/popup/popup_manager";

import { ScreenInnerWrapper } from "./components/styled_layout";

import { Chart, ArcElement } from "chart.js";
import AuthGuard from "./guards/AuthGuard";
import VouchersGuard from "./guards/VouchersGuard";
import PropertyGuard from "./guards/PropertyGuard";
import { getFromStorage, getTokens, userKey } from "./services/auth_service";
import { useEffect, useState } from "react";
import { useAuthStore } from "./state/auth_store";
import { User } from "./models/auth_models";
import { GoogleOAuthProvider } from "@react-oauth/google";
import CorporatePartnersScreen from "./screens/corporate_partners/corporate_partners";
import ReportGuard from "./guards/ReportGuard";
import IntegrationsGuard from "./guards/IntegrationsGuard";
import AgriWebbFarmsGuard from "./guards/AgriWebbFarmsGuard";
import ReductionPlanGuard from "./guards/ReductionPlanGuard";
import AccountDetailsScreen from "./screens/account/account_screen";
import AccountRefreshScreen from "./screens/account/account_refresh_screen";
import PasswordResetScreen from "./screens/reset_password";
import IdentityActionsScreen from "./screens/identity_actions";
import SubscriptionGuard from "./guards/SubscriptionGuard";
import PropertyTreesGuard from "./guards/PropertyTrees";
import ErrorScreen from "./screens/error";
import { getEnvFromUrl } from "./utilities/env";
import CorporatePartnersGuard from "./guards/CorporatePartnersGuard";
import { AddPartnerPageRedirection } from "./screens/corporate_partners/add_page_redirection";
import { SummaryPageRedirection } from "./screens/form/summary_page_redirection";
import SummaryScreen from "./screens/form/summary_screen";
import VisionOrgGuard from "./guards/VisionOrgGuard";
import queryClient from "./utilities/queryClient";

function App() {
    const envName = getEnvFromUrl()

    if (envName === 'production') {
        const TRACKING_ID = "G-495QMNXP6S";
        ReactGA.initialize(TRACKING_ID);
    }

    Chart.register(ArcElement);

    const authStore = useAuthStore();

    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const user = getLoggedInUser();
        if (user && !authStore.loggedIn) {
            authStore.signIn(user);
        }
        setLoading(false);
    }, [authStore]);

    const rollbarConfig = {
        hostBlockList: ['localhost'],
        accessToken: 'ddbed3424ef84d5396ff2975aa4346b2',
        captureUncaught: true,
        captureUnhandledRejections: true,
        autoInstrument: {
            log: false
        },
        payload: {
          person: {
            id: authStore.user?.uid ?? 'n/a',
            email: authStore.user?.email ?? 'n/a'
          },
          environment: envName,
          client: {
            javascript: {
              code_version: '1.0.0',
              source_map_enabled: true
            }
          }
        }
    }
    const HotjarID = import.meta.env.REACT_APP_HOTJAR_ID
    if (envName !== 'local' && HotjarID) {
        Hotjar.init(parseInt(HotjarID), 6)
    }
    const GoogleAuthId = import.meta.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID

    return loading ? (
        <ScreenInnerWrapper />
    ) : (
        <GoogleOAuthProvider
            clientId={GoogleAuthId}
        >
            <RollbarProvider
              config={rollbarConfig}
            >
                <QueryClientProvider client={queryClient}>
                    <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-left" />
                    <Router>
                        <RollbarErrorBoundary
                        fallbackUI={ErrorScreen}
                        >
                            <ScrollToTop />
                            <PopupManager />
                            <Routes>
                                <Route
                                    path="/account"
                                    element={
                                        <AuthGuard>
                                            <VouchersGuard>
                                                <IntegrationsGuard>
                                                    <CorporatePartnersGuard>
                                                        <AccountDetailsScreen />
                                                    </CorporatePartnersGuard>
                                                </IntegrationsGuard>
                                            </VouchersGuard>
                                        </AuthGuard>
                                    }
                                />

                                <Route
                                    path="/account/subscription"
                                    element={
                                        <Navigate to="/account?tab=subscription"/>
                                    }
                                />

                                <Route
                                    path="/account/refresh"
                                    element={
                                        <AuthGuard>
                                            <AccountRefreshScreen />
                                        </AuthGuard>
                                    }
                                />

                                <Route
                                    path="/login"
                                    element={
                                        <AuthGuard blockAuthenticated>
                                            <LoginScreen />
                                        </AuthGuard>
                                    }
                                />

                                <Route
                                    path="/register"
                                    element={
                                        <AuthGuard blockAuthenticated>
                                            <VisionOrgGuard>
                                                <SignUpScreen />
                                            </VisionOrgGuard>
                                        </AuthGuard>
                                    }
                                />

                                <Route
                                    path="/verifyEmail"
                                    element={
                                        <AuthGuard allowUnverified>
                                            <VerifyEmailScreen />
                                        </AuthGuard>
                                    }
                                />

                                <Route
                                    path="/checkVerification"
                                    element={
                                        <AuthGuard allowUnverified>
                                            <CheckEmailVerificationScreen />
                                        </AuthGuard>
                                    }
                                />

                                <Route
                                    path="/forgotpassword"
                                    element={
                                        <AuthGuard blockAuthenticated>
                                            <ForgotPasswordScreen />
                                        </AuthGuard>
                                    }
                                />

                                <Route
                                    path="/identity_actions"
                                    element={
                                        <AuthGuard>
                                            <IdentityActionsScreen />
                                        </AuthGuard>
                                    }
                                />

                                <Route
                                    path="/resetpassword"
                                    element={
                                        <PasswordResetScreen />
                                    }
                                />

                                <Route
                                    path="/enterprises"
                                    element={
                                        <AuthGuard>
                                            <PropertyGuard>
                                                <ReportGuard>
                                                    <CorporatePartnersGuard>
                                                        <FarmsScreen />
                                                    </CorporatePartnersGuard>
                                                </ReportGuard>
                                            </PropertyGuard>
                                        </AuthGuard>
                                    }
                                />

                                <Route
                                    path="/farms"
                                    element={
                                        <Navigate to="/enterprises"/>
                                    }
                                />

                                <Route
                                    path="/getstarted"
                                    element={
                                        <AuthGuard>
                                            <IntegrationsGuard>
                                                <AgriWebbFarmsGuard>
                                                    <GetStartedScreen />
                                                </AgriWebbFarmsGuard>
                                            </IntegrationsGuard>
                                        </AuthGuard>
                                    }
                                /> 

                                <Route
                                    path="/farm/:propertyId"
                                    element={
                                        <AuthGuard>
                                            <PropertyGuard>
                                                <ReportGuard>
                                                    <ReductionPlanGuard>
                                                        <PropertyTreesGuard>
                                                            <FarmScreen />
                                                        </PropertyTreesGuard>
                                                    </ReductionPlanGuard>
                                                </ReportGuard>
                                            </PropertyGuard>
                                        </AuthGuard>
                                    }
                                />

                                <Route
                                    path="/farm/:propertyId/create-report"
                                    element={
                                        <AuthGuard>
                                            <PropertyGuard>
                                                <ReportGuard>
                                                    <IntegrationsGuard>
                                                        <CreateReportScreen />
                                                    </IntegrationsGuard>
                                                </ReportGuard>
                                            </PropertyGuard>
                                        </AuthGuard>
                                    }
                                />

                                <Route
                                    path="/farm/:propertyId/edit"
                                    element={
                                        <AuthGuard>
                                            <PropertyGuard>
                                                <IntegrationsGuard>
                                                    <AgriWebbFarmsGuard>
                                                        <FarmEditScreen />
                                                    </AgriWebbFarmsGuard>
                                                </IntegrationsGuard>
                                            </PropertyGuard>
                                        </AuthGuard>
                                    }
                                />

                                <Route
                                    path="/farm/:propertyId/reductionPlanner"
                                    element={
                                        <AuthGuard>
                                            <SubscriptionGuard>
                                                <PropertyGuard>
                                                    <ReportGuard>
                                                        <ReductionPlanGuard>
                                                            <PropertyTreesGuard>
                                                                <ReductionPlannerScreen />
                                                            </PropertyTreesGuard>
                                                        </ReductionPlanGuard>
                                                    </ReportGuard>
                                                </PropertyGuard>
                                            </SubscriptionGuard>
                                        </AuthGuard>
                                    }
                                />

                                <Route
                                    path="/form/:reportId/summary"
                                    element={
                                        <AuthGuard>
                                            <PropertyGuard>
                                                <ReportGuard>
                                                    <SummaryScreen />
                                                </ReportGuard>
                                            </PropertyGuard>
                                        </AuthGuard>
                                    }
                                />
    
                                <Route
                                    path="/form/:reportId/:formId"
                                    element={
                                        <AuthGuard>
                                            <PropertyGuard>
                                                <ReportGuard>
                                                    <PropertyTreesGuard>
                                                        <FormScreen />
                                                    </PropertyTreesGuard>
                                                </ReportGuard>
                                            </PropertyGuard>
                                        </AuthGuard>
                                    }
                                />

                                <Route
                                    path="/summary/:reportId"
                                    element={
                                        <SummaryPageRedirection />
                                    }
                                />

                                <Route
                                    path="/report/:reportId"
                                    element={
                                        <AuthGuard>
                                            <PropertyGuard>
                                                <ReportGuard>
                                                    <ReportScreen />
                                                </ReportGuard>
                                            </PropertyGuard>
                                        </AuthGuard>
                                    }
                                />

                                <Route
                                    path="/partners"
                                    element={
                                        <AuthGuard>
                                            <PropertyGuard>
                                                <CorporatePartnersGuard>
                                                    <CorporatePartnersScreen />
                                                </CorporatePartnersGuard>
                                            </PropertyGuard>
                                        </AuthGuard>
                                    }
                                />

                                <Route
                                    path="/partners/add"
                                    element={<AddPartnerPageRedirection/>}
                                />

                                <Route
                                    path="/*"
                                    element={
                                        <AuthGuard>
                                            <Navigate to="/farms" />
                                        </AuthGuard>
                                    }
                                />
                            </Routes>
                        </RollbarErrorBoundary>
                    </Router>
                </QueryClientProvider>
            </RollbarProvider>
        </GoogleOAuthProvider>
    );
}

/**
 * Attempts to read the logged in user id
 * @returns the user id of the user if logged in
 */
function getLoggedInUser(): User | undefined {
    const userString = getFromStorage(userKey);
    if (userString) {
        try {
            const user = JSON.parse(userString);
            user.timestamp = user?.timestamp ?? Date.UTC(0, 0); // Set default date to 0

            const tokens = getTokens();
            if (tokens.refreshToken) {
                return user as User;
            }
        } catch (e) {
            return undefined;
        }
    }

    return undefined;
}

export default App;
