import { Column, Row } from "../../../components/styled_layout";
import { MultiInputField } from "../../../components/form/multi_input_field"
import { BodyText } from "../../../components/styled_text"
import { formatNumber, formatReportNumber } from "../../../utilities/functions"
import { FieldSize, InputFormField, InputFormFieldArgs, State, TypeOrFunctionOfState } from "./form_field"

import { ruminatiColors } from "../../../utilities/colors";

export type PercentageFieldOption = {
    key: string
    label: string
}

export type PercentageFieldSeperatorOption = {
    break: boolean
}

export class PercentageSplitFormField extends InputFormField<{ [key: string]: number | undefined }> {
    title: string | undefined; // If set to undefined, title won't show
    total: number | undefined; // If set to undefined, total won't be used (and unit values won't be used either)
    _units: string;
    options: (PercentageFieldOption | PercentageFieldSeperatorOption)[];
    columnWidth: FieldSize
    _additionalAction?: TypeOrFunctionOfState<JSX.Element>

    get _realOptions(): PercentageFieldOption[] {
        return this.options.filter(o => !('break' in o)) as PercentageFieldOption[]
    }

    get count(): number {
        return this._realOptions.length;
    }

    get _default(): { [key: string]: undefined } {
        return Object.fromEntries(this._realOptions.map((e) => [e.key, undefined]));
    }

    get _defaultExpanded(): { [key: string]: number } {
        return Object.fromEntries(this._realOptions.map((e) => [e.key, 100 / this.count]));
    }

    constructor(
        args: InputFormFieldArgs<{ [key: string]: number | undefined }> & {
            options: (PercentageFieldOption | PercentageFieldSeperatorOption)[],
            title?: string,
            total?: number,
            units: string,
            columnWidth?: FieldSize
            additionalAction?: TypeOrFunctionOfState<JSX.Element>
        }
    ) {
        super(args);
        this.title = args.title;
        this.options = args.options;
        this.total = args.total;
        this._units = args.units;
        this.columnWidth = args.columnWidth ?? FieldSize.Quarter
        this._additionalAction = args.additionalAction
    }


    _unfilled = (values?: { [key: string]: number | undefined }): number => {
        return values ? this._realOptions.reduce<number>((total, o) => total + (values[o.key] !== undefined ? 0 : 1), 0) : this.count
    }

    _total = (values?: { [key: string]: number | undefined }): number => {
        return values ? this._realOptions.reduce<number>((total, o) => total + (values[o.key] ?? 0), 0) : 0
    }

    _expand = (values?: { [key: string]: number | undefined }): { [key: string]: number } => {
        if (values) {
            const remaining = 100 - this._total(values)
            return Object.fromEntries(this._realOptions.map((o) => {
                if (values[o.key] === undefined) return [o.key, Math.max(0, Math.min(100, remaining / this._unfilled(values)))]
                return [o.key, values[o.key]!]
            }))
        }
        return this._defaultExpanded
    }

    _unitValue = (value?: number): string => {
        if (this.total === undefined) return "";
        if (value === undefined) return "";
        return `${formatReportNumber(this.total * value / 100, { maxDecimalSigFig: 2 })} ${this._units}/year`
    }

    validate(values?: { [key: string]: number | undefined }): boolean {
        if (this._unfilled(values) === 0) return this._total(values) === 100;
        return this._total(values) <= 100;
    }

    transforms = (values?: { [key: string]: string | undefined }): { [key: string]: number | undefined } => {
        if (values) {
            return Object.fromEntries(Object.keys(values).map((k) => {
                const value = values[k]
                if (value !== undefined) {
                    const num = parseInt(value)
                    if (!Number.isNaN(num)) return [k, num]
                }
                return [k, undefined]
            }));
        }
        return this._default;
    }

    displayValues = (values?: { [key: string]: number | undefined }): { [key: string]: string | undefined } => {
        if (values) {
            return Object.fromEntries(Object.keys(values).map((k) => {
                const value = values[k];
                if (value !== undefined) {
                    return [k, formatNumber(value, { maxDecimalPlaces: 2 }).toString()]
                }
                return [k, undefined];
            }))
        }
        return this._default
    }

    placeholders = (values?: { [key: string]: number | undefined }): { [key: string]: string } => {
        const valid = this.validate(values);
        const expanded = this._expand(values);
        const expandedDisplay = this.displayValues(expanded);

        return Object.fromEntries(this._realOptions.map((o) => {
            if (!valid) return [o.key, " "];
            return [o.key, `${expandedDisplay[o.key]}`];
        }));
    };

    _calculateActualUse = (values?: { [key: string]: number | undefined }): { [key: string]: string } => {
        const expanded = this._expand(values);
        return Object.fromEntries(this._realOptions.map((o) => {
            return [o.key, this._unitValue(expanded[o.key])];
        }))
    }

    additionalAction(state: State<any>) {
        return this._additionalAction && this._additionalAction instanceof Function ? this._additionalAction(state) : undefined;
    }

    render(
        _id: string,
        onChange: (value?: { [key: string]: number | undefined }) => void,
        state: State<any>,
        value?: { [key: string]: number | undefined },
        _error?: boolean,
        size?: "large" | "small"
    ): JSX.Element {
        return <>
            {this.title !== undefined && 
            <Row 
            style={{
                alignContent: 'center',
                marginBottom: '6px'
            }}>
                <Column style={{width: '50%', alignItems: 'flex-start'}}>
                    <BodyText
                        style={{
                            fontSize: "16px",
                            fontWeight: 500,
                        }}
                    >
                        {this.title}
                    </BodyText>
                </Column>
                <Column style={{width: '50%', alignItems: 'flex-end'}}>
                    {this.total !== undefined &&
                        <BodyText
                            style={{
                                color: ruminatiColors.light_green,
                                fontSize: "14px",
                                fontWeight: 500,
                            }}
                        >
                            You reported a total of {formatReportNumber(this.total)} {this._units.replace('/year', ' for the year')}
                        </BodyText>
                    }
                    {this._additionalAction !== undefined ? this.additionalAction(state) : undefined }                
                </Column>
            </Row>
            }
            <MultiInputField
                keys={this.options.map((o, i) => {
                    if ('break' in o) return `seperator_${i}`
                    return o.key
                })}
                values={this.displayValues(value)}
                onChange={(values, _dp) => {
                    onChange(this.transforms(values))
                }}
                unit="%"
                placeholders={this.placeholders(value)}
                error={!this.validate(value)}
                labelsAbove={Object.fromEntries(this._realOptions.map((o) => [o.key, o.label]))}
                labelsInside={this._calculateActualUse(value)}
                size={size}
                columnWidth={this.columnWidth}
                state={state}
                errorString=" "
            />
        </>
    }
}