import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { ConcatMethod, FieldElement, InputFormField } from "../models/form/form_fields/form_field";
import { Report } from "../models/property_models";
import { ruminatiColors } from "../utilities/colors";
import MainButton from "./buttons/main_button";
import Heading from "./heading";
import { Row } from "./styled_layout";
import ValuesList, { FieldValue } from "./values_list";
import { useAuthStore } from "../state/auth_store";
import { SubscriptionType } from "../models/subscription_model";
import { formatNumber, formatReportNumber } from "../utilities/functions";
import { SelectFormField } from "../models/form/form_fields/basic_form_fields";
import { PercentageSplitFormField, PercentageFieldOption } from "../models/form/form_fields/percentage_split_form_field";
import { FormFieldWrapper } from "../models/form/form_fields/form_field_wrappers";
import { MultiInputFormField } from "../models/form/form_fields/multi_input_form_field";
import { SeasonalInputFormField } from "../models/form/form_fields/seasonal_input_fields";
import { Card } from "./card";
import OutlineButton from "./buttons/outline_button";
import { ParagraphText } from "./styled_text";
import { ReportFormPage } from "../models/form/form_page";
import { ReportFormPageId } from "../utilities/forms";

/**
 * A section of the summary page to show a single page's data
 * @param page the form page to show
 */
export default function SummarySection(props: {
    page: ReportFormPage<any>;
    report: Report;
}) {
    const navigate = useNavigate();

    const isPrime = useAuthStore().user?.subscription?.type === SubscriptionType.PRIME;

    const productionBaseSection = (
        <>
        <Row style={{ justifyContent: "space-between" }}>
            <Heading level={3}>{props.page.title}</Heading>
        </Row>
        <Row style={{ justifyContent: "center", marginBottom: '40px', flexWrap: 'wrap' }}>
            <ParagraphText style={{fontSize: '14px', marginBottom: '10px'}}>
                Get access to your Intensity Metrics by providing additional
                production information. <br/> This requires upgrading to Ruminati PRIME.
            </ParagraphText>
            <MainButton
                colorScheme="orange"
                size="medium"
                onClick={() => navigate('/account?tab=subscription')}>
                Upgrade Now
            </MainButton>
        </Row>
        </>
    );

    const renderSummaryValues = (summaryValues: FieldValue[]) => {
        return (
            <>
                <Row style={{ alignItems: "center", justifyContent: "space-between" }}>
                    <Heading level={3}>{props.page.title}</Heading>
                    <OutlineButton
                        height="40px"
                        fontSize="14px"
                        width="140px"
                        activeColor={ruminatiColors.green_3}
                        onClick={() => navigate(`/form/${props.report.id}/${props.page.id}`)}>
                        {pageCompleted ? "Edit Information" : "Add your data"}
                    </OutlineButton>
                </Row>
                <Row style={{ alignItems: "center", justifyContent: "space-between" }}>
                    <SummaryValuesWrapper>
                        {summaryValues.every((v) => v === emptySummaryValue) ? (
                            <Heading level={4}>No {props.page.title.toLowerCase()} data.</Heading>
                        ) : (
                            <ValuesList values={summaryValues} makeNumericValuePrettyString={true}/>
                        )}
                    </SummaryValuesWrapper>
                </Row>
                <Row>
                    {props.page.image && (
                        <SummaryImageWrapper>
                            <img src={`/images/${props.page.image}`} alt={props.page.title} />
                        </SummaryImageWrapper>
                    )}
                </Row>
            </>
        );
    }

    const pageCompleted: boolean =
        props.report !== undefined &&
        props.report.completedSections.includes(props.page.id);

    let summaryValues: FieldValue[] = [];
    if (pageCompleted) {
        summaryValues = pageToSummaryRows(props.page, props.report);
    }

    return (
        <Card style={{ marginBottom: "16px", padding: '24px 24px 0px 24px' }}>
            {!pageCompleted && (props.page.id === ReportFormPageId.Production && !isPrime) ? (
                productionBaseSection
            ) : (
                renderSummaryValues(summaryValues)
            )}
        </Card>
    );
}

const emptySummaryValue = {
    name: "",
    value: "",
};

const pageToSummaryRows = (formPage: ReportFormPage<any, any>, report: Report) => {
    const page = (report as any)[formPage.id];

    if (formPage.repeatableConfig !== undefined && Array.isArray(page) && page.length === 0) {
        return [];
    }

    const transformer = formPage.formData.transformer;
    const getState = (pageData: any) => (transformer !== undefined ? transformer.in(pageData) : pageData);

    return Object.keys(formPage.formData.fields).flatMap((field) => {
        const formField = formPage.formData.fields[field];
        const getValue = (pageData: any) => getState(pageData)[field];
        return fieldToSummaryRows(formField!, page, getState, getValue);
    });
}

const fieldToSummaryRows = (formField: FieldElement<any>, page: any, getState: (pageData: any) => any, getValue: (pageData: any) => any): FieldValue[] => {
    if (formField instanceof InputFormField) {
        return inputFieldtoSummaryRow(formField, page, getState, (pageData) => getValue(pageData));
    } else if (formField instanceof FormFieldWrapper) {
        return Object.keys(formField.data.fields).flatMap((subField) => {
            const subFormField = formField.data.fields[subField];
            return fieldToSummaryRows(subFormField, page, getState, (pageData: any) => getValue(pageData)[subField]);
        });
    }

    return [];
}

const inputFieldtoSummaryRow = (formField: InputFormField<any>, page: any, getState: (pageData: any) => any, getValue: (pageData: any) => any): FieldValue[] => {
    let returnValue: FieldValue | FieldValue[] = { name: '', value: undefined };
    let state;

    if ((page as any[]).length >= 1) {
        for (let i = 0; i < page.length; i++) {
            const newReturnValue = getInputFieldDisplayValue(formField, getValue(page[i]), getState(page[i]), i);

            const combineValues = (name: any, total: any, curr: any): FieldValue => {
                let newName = name;
                if (formField._concatMethod === ConcatMethod.StringList) {
                    let value = (total + ',\n' + curr);
                    if (value.length > 200) {
                        value = (value as string).substring(0, 50) + "...";
                    }
                    return { name, value };
                } else if (formField._concatMethod === ConcatMethod.Average) {
                    if (!(name as string).startsWith("Average")) {
                        newName = "Average " + name;
                    }
                    return { name: newName, value: ((total * i) + curr) / (i + 1) };
                } else if (formField._concatMethod === ConcatMethod.Total) {
                    if (!(name as string).startsWith("Total")) {
                        newName = "Total " + name;
                    }
                    return { name: newName, value: total + curr };
                } else if (formField._concatMethod === ConcatMethod.Boolean) {
                    if (total !== curr) {
                        return { name, value: 'Both' };
                    }
                }
                return { name, value: curr };
            };

            if (i === 0) {
                returnValue = newReturnValue;
            } else {
                if (Array.isArray(returnValue)) {
                    returnValue = returnValue.map((singleReturn, index) => {
                        return combineValues(singleReturn.name, singleReturn.value, (newReturnValue as FieldValue[])[index]!.value);
                    });
                } else {
                    returnValue = combineValues(returnValue.name, returnValue.value, (newReturnValue as FieldValue).value);
                }
            }
        }
    } else {
        returnValue = getInputFieldDisplayValue(formField, getValue(page), getState(page), 0);
    }

    if (formField instanceof PercentageSplitFormField) {
        return (Array.isArray(returnValue) ? returnValue : [returnValue]).filter(v => v.value).map((v) => {
            const percentageValue = v.value;
            if (percentageValue === undefined) return {
                name: v.name,
                value: 'N/A'
            }
            if (typeof percentageValue === 'string') {
                return {
                    name: v.name,
                    value: 'N/A'
                }
            }
            // Show the actual value (not a %)
            // const totalString = formField.total !== undefined ? ` (${formatNumber(formField.total * percentageValue / 100, { maxDecimalPlaces: 2 })} ${formField.units})` : "";
            return {
                name: v.name,
                value: `${formatNumber(percentageValue, { maxDecimalPlaces: 2 })} %`,
            };
        });
        
    } else if (formField instanceof SeasonalInputFormField) {
        const data = getValue(page)
        if (data === undefined) return []
        return [{
            name: formField.title ?? '',
            value: Object.keys(data).map(season => {
                return `${data[season]}${formField._unit}`
            }).join(', ')
        }] ; 
    } else if (formField instanceof MultiInputFormField) {
        // return (Array.isArray(returnValue) ? returnValue : [returnValue]).map((v) => {
        //     const subValue = v.value;
        //     const units = `${formField.units}`;
        //     return {
        //         name: v.name,
        //         value: `${formatNumber(subValue, { maxDecimalPlaces: 2 })} ${units}`
        //     };
        // });
        return []; // Currently we just ignore MultiInputFormFields, uncomment above to add
    } else if (!Array.isArray(returnValue)) {
        state = getState(page);
        const unitString = `${formField.unit({
            get: state,
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            set: () => { },
            index: 0,
        })}`;
        if (!returnValue.value) {
            return []
        } else if (typeof returnValue.value === 'string') {
            return [returnValue]
        }
        const valueString = Number.isFinite(returnValue.value) ? 
            formatReportNumber(returnValue.value, { maxDecimalPlaces: 2 }) 
            : returnValue.value;
        returnValue.value = unitString !== "" ? `${valueString} ${unitString}` : valueString;
        return [returnValue];
    }
    return returnValue;
};

const getInputFieldDisplayValue = (formField: InputFormField<any>, value: any, state: any, index: number) => {
    const name = formField.summaryLabel({
        get: state,
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        set: () => { },
        index,
    });
    const returnValue = { name, value };

    if (formField instanceof PercentageSplitFormField) {
        return (formField.options.filter(o => 'key' in o) as PercentageFieldOption[]).map((o) => {
            const percentageValue = value[o.key];
            return {
                name: `${formField.summaryLabel(state)} ${o.label}`,
                value: percentageValue,
            };
        });
    } else if (formField instanceof SelectFormField) {
        if (Array.isArray(formField.options)) {
            returnValue.value = formField.options.find((option) => option.value === value)?.name;
        }
    } else if (formField instanceof MultiInputFormField) {
        return formField.options.map((o) => {
            const subValue = value[o.key];
            return {
                name: `${formField.summaryLabel(state)} ${o.label}`,
                value: subValue,
            };
        });
    }

    return returnValue;
}

const SummaryImageWrapper = styled.div`
    flex: 1 1 0;
    align-self: center;
    img {
        width: 80%;
        max-width: 464px;
        height: 232px;
        object-fit: scale-down;
        display: block;
        margin: 0 auto 0 auto;
    }
`;

const SummaryValuesWrapper = styled.div`
    flex: 1 1 0;
    box-sizing: border-box;
    padding: 8px 0;
    flexDirection: "column";
`;