import { EmissionDataOption } from "../components/graph/reduction_graph";
import EmissionUnit from "../models/emission-unit";
import { Property, Report, ReportResults } from "../models/property_models";
import { SbGAFImportResults } from "./sbGaf";
import { ConsumableAllocation } from "@/models/report";

export function getCompletedReports(reports: Report[]): Report[] {
    return reports.filter((r) => r.complete);
}

export function getLatestCompletedReport(
    reports: Report[]
): Report | undefined {
    const completedReports = getCompletedReports(reports);
    if (completedReports.length === 0) return undefined;
    let indexOfLatest = 0
    completedReports.forEach((r, i) => {
        if (r.financialYear > completedReports[indexOfLatest].financialYear) {
            indexOfLatest = i
        }
    })
    return completedReports[indexOfLatest]
}

export function getEarliestCompletedReport(
    reports: Report[]
): Report | undefined {
    const completedReports = getCompletedReports(reports);
    if (completedReports.length === 0) return undefined;
    let indexOfEarliest = 0
    completedReports.forEach((r, i) => {
        if (r.financialYear < completedReports[indexOfEarliest].financialYear) {
            indexOfEarliest = i
        }
    })
    return completedReports[indexOfEarliest]
}

export function getPreviousCompletedReport(
    reports: Report[],
    report: Report | undefined
): Report | undefined {
    if (report === undefined) return undefined;
    const completedReports = getCompletedReports(reports);
    if (completedReports.length === 1) return undefined

    let indexOfPrevious = Infinity
    completedReports.forEach((r, i) => {
        if (r.financialYear < report.financialYear) {
            const diff = r.financialYear - report.financialYear
            if (diff < indexOfPrevious) indexOfPrevious = i
        }
    })

    if (indexOfPrevious === Infinity) return undefined
    return completedReports[indexOfPrevious];
}

export function populateReportFromParsedSbGaf(
    report: Report,
    sbGafResults: SbGAFImportResults,
    isPrime: boolean,
    property: Property
) {
    // If the property doesn't have cattle or sheep then bail early
    if (!property.cattleFarm && !property.sheepFarm) {
        return
    }
    if (property.cattleFarm) {
        report.livestockInformationCattleClassAus = sbGafResults.livestockInformationCattleClassAus
        report.livestockInformationCattleMiscAus = sbGafResults.livestockInformationCattleMiscAus
        report.cattlePurchasesInformation = sbGafResults.purchasedLivestock.cattle;
        report.completedSections.push("livestockInformationCattleClassAus", 'livestockInformationCattleMiscAus', 'cattlePurchasesInformation')
    }

    if (property.sheepFarm) {
        report.livestockInformationSheepClassAus = sbGafResults.livestockInformationSheepClassAus
        report.livestockInformationSheepMiscAus = sbGafResults.livestockInformationSheepMiscAus
        report.sheepPurchasesInformation = sbGafResults.purchasedLivestock.sheep;
        report.completedSections.push("livestockInformationSheepClassAus", 'livestockInformationSheepMiscAus', 'sheepPurchasesInformation')
    }

    report.pastureInformation = {
        urea: Math.round(sbGafResults.pasture.urea),
        singleSuperphosphate: Math.round(
            sbGafResults.pasture.singleSuperphosphate
        ),
        lime: Math.round(sbGafResults.pasture.lime),
        otherNitrogen: Math.round(sbGafResults.pasture.otherNitrogen),
        glyphosate: Math.round(sbGafResults.pasture.glyphosate),
        pesticide: Math.round(sbGafResults.pasture.pesticide),
    };
    report.completedSections.push("pastureInformation")

    report.consumablesInformation = {
        diesel: Math.round(sbGafResults.consumables.diesel),
        petrol: Math.round(sbGafResults.consumables.petrol),
        percentRenewables: Math.round(
            sbGafResults.consumables.percentRenewables
        ),
        electricityUsage: Math.round(sbGafResults.consumables.electricityUsage),
    };

    report.supplementaryFeedInformation = {
        grain: Math.round(sbGafResults.supplementaryFeed.grain),
        cottonSeed: Math.round(sbGafResults.supplementaryFeed.cottonSeed),
        hay: Math.round(sbGafResults.supplementaryFeed.hay),
    };

    if (isPrime) {
        report.productionInformation = {
            cattleLiveweightSoldKgs: sbGafResults.productionInformation.cattleLiveweightSoldKgs,
            sheepLiveweightSoldKgs: sbGafResults.productionInformation.sheepLiveweightSoldKgs,
            consumablesAllocation: {
                petrolDiesel: {},
                electricity: {}
            },
        }

        const isBeefOnly = property.cattleFarm && !property.sheepFarm && !property.grainFarm
        const isSheepOnly = !property.cattleFarm && property.sheepFarm && !property.grainFarm
        const isBeefAndSheep = property.cattleFarm && property.sheepFarm && !property.grainFarm
        const isBeefAndSheepAndGrain = property.cattleFarm && property.sheepFarm && !property.grainFarm
        const isBeefAndGrain = property.cattleFarm && !property.sheepFarm && property.grainFarm
        const isSheepAndGrain = !property.cattleFarm && property.sheepFarm && property.grainFarm

        const evenAllocation = isBeefAndSheep ? 0.33 : isBeefAndSheepAndGrain ? 0.25 : isBeefAndGrain || isSheepAndGrain ? 0.5 : 0
        const petrolDieselAllocation: ConsumableAllocation = {}
        const electricityAllocation: ConsumableAllocation = {}
        if (property.cattleFarm) {
            petrolDieselAllocation.cattle = isBeefOnly ? 1 : evenAllocation
            electricityAllocation.cattle = isBeefOnly ? 1 : evenAllocation
        }
        if (property.sheepFarm) {
            petrolDieselAllocation.wool = isSheepOnly ? 0.5 : evenAllocation
            petrolDieselAllocation.sheep = isSheepOnly ? 0.5 : evenAllocation
            electricityAllocation.wool = isSheepOnly ? 0.5 : evenAllocation
            electricityAllocation.sheep = isSheepOnly ? 0.5 : evenAllocation
        }
        if (property.grainFarm) {
            petrolDieselAllocation.grain = evenAllocation
            electricityAllocation.grain = evenAllocation
        }

        report.productionInformation.consumablesAllocation = {
            petrolDiesel: petrolDieselAllocation,
            electricity: electricityAllocation
        }
    }
}

export const getReportOfYear = (
    reports: Report[],
    year: number
): Report | undefined => reports.find((r) => r.financialYear === year);

export const getReportsOfProperty = (
    reports: Report[],
    propertyId: string
): Report[] | [] => reports.filter((r) => r.propertyId === propertyId) ?? [];

export type BaselineType = {
    total: number,
    sequestration: number,
    results: ReportResults | null,
};

export const getBaseline = (
    reports: Report[],
    selectedData: EmissionDataOption,
): BaselineType => {
    const completedReports: Report[] = getCompletedReports(reports);

    let results;
    if (completedReports.length === 1) {
        results = completedReports[0]?.results;
    } else if (completedReports.length >= 1) {
        const earliestReport = getEarliestCompletedReport(completedReports)
        if (earliestReport) results = earliestReport.results;
    }

    if (results === undefined) {
        return {
            total: 0,
            sequestration: 0,
            results: null,
        };
    }
    switch (selectedData) {
        default:
            return {
                total: results.totals.total,
                sequestration: results.totals.sequestration,
                results: results,
            };
        case EmissionDataOption.BeefIntensity:
            return {
                total: results.intensity.kgCo2PerKgBeefMeatSold ?? 0,
                sequestration: 0,
                results: results,
            };
        case EmissionDataOption.SheepIntensity:
            return {
                total: results.intensity.kgCo2PerKgSheepMeatSold ?? 0,
                sequestration: 0,
                results: results,
            };
        case EmissionDataOption.WoolIntensity:
            return {
                total: results.intensity.kgCo2PerKgGreasyWool ?? 0,
                sequestration: 0,
                results: results,
            };
        case EmissionDataOption.GrainIntensity:
            return {
                total: results.intensity.kgCo2PerKgGrainProduced ?? 0,
                sequestration: 0,
                results: results,
            };
        case EmissionDataOption.DairyFPCM:
            return {
                total: results.intensity.kgCo2PerKgMilkFPCM ?? 0,
                sequestration: 0,
                results: results,
            };
        case EmissionDataOption.DairyLiveWeight:
            return {
                total: results.intensity.kgCo2PerKgDairyMeat ?? 0,
                sequestration: 0,
                results: results,
            };
        case EmissionDataOption.DairyMilkSolids:
            return {
                total: results.intensity.kgCo2PerKgMilk ?? 0,
                sequestration: 0,
                results: results,
            };
    }
}

export const getPercentageChange = (
    results: ReportResults,
    baseline: BaselineType,
    selectedData: EmissionDataOption,
) => {
    const netBaseline = baseline.total - baseline.sequestration;
    let value = 0;

    switch (selectedData) {
        default:
            value = results.totals.net;
            break;
        case EmissionDataOption.BeefIntensity:
            value = results.intensity.kgCo2PerKgBeefMeatSold ?? 0;
            break;
        case EmissionDataOption.SheepIntensity:
            value = results.intensity.kgCo2PerKgSheepMeatSold ?? 0;
            break;
        case EmissionDataOption.WoolIntensity:
            value = results.intensity.kgCo2PerKgGreasyWool ?? 0;
            break;
        case EmissionDataOption.GrainIntensity:
            value = results.intensity.kgCo2PerKgGrainProduced ?? 0;
            break;
        case EmissionDataOption.DairyFPCM:
            value = results.intensity.kgCo2PerKgMilkFPCM ?? 0;
            break;
        case EmissionDataOption.DairyLiveWeight:
            value = results.intensity.kgCo2PerKgDairyMeat ?? 0;
            break;
        case EmissionDataOption.DairyMilkSolids:
            value = results.intensity.kgCo2PerKgMilk ?? 0;
            break;
    }

    const netDifference = value - netBaseline;
    if (netBaseline === 0) {
        return 0;
    }
    return Math.round((netDifference / netBaseline) * 100);
}

export const getBaselineUnit = (selectedData: EmissionDataOption) => {
    switch (selectedData) {
        default:
            return EmissionUnit.Total;
        case EmissionDataOption.BeefIntensity:
            return EmissionUnit.IntensityLivestock;
        case EmissionDataOption.SheepIntensity:
            return EmissionUnit.IntensityLivestock;
        case EmissionDataOption.WoolIntensity:
            return EmissionUnit.IntensityWool;
        case EmissionDataOption.GrainIntensity:
            return EmissionUnit.IntensityGrain;
        case EmissionDataOption.DairyFPCM:
            return EmissionUnit.DairyIntensityFPCM;
        case EmissionDataOption.DairyLiveWeight:
            return EmissionUnit.DairyIntensityLW;
        case EmissionDataOption.DairyMilkSolids:
            return EmissionUnit.DairyIntensityMilkSolids;
    }
}