import { BBox, FeatureCollection, MultiPolygon, Feature, Polygon } from "geojson";
import { useState, useRef } from "react";

import { kml } from "@tmcw/togeojson";
import { coordEach } from "@turf/meta";
// @ts-expect-error not picking up typings?
import geojsonFlatten from 'geojson-flatten';
import JSZip from 'jszip';
// @ts-expect-error not picking up typings?
import ShpToGeoJson from 'shp-to-geojson/dist/shp-to-geojson.browser.js'

import { GetStartedStep } from "../../screens/get_started_screen";
import {
    convertFeatureCollectionToMultiPolygon,
    calculateBoundingBoxFromGeojson,
    coordsAreInValidRange,
    convertPolygonsToMultiPolygon
} from "../../utilities/geojson-helpers";
import AreaSelect from "./area_select";
import { Column, Row, Wrapper } from "../styled_layout";
import { BaseText, ButtonText, BodyText } from "../styled_text";
import { ruminatiColors } from "../../utilities/colors";
import { ParsingStep } from '../filedrop';
import Icon from '../icon'
import InstructionOverlay from "../maps/instruction_overlay";
import SmallButton from "../buttons/small_button";
import { MapProvider } from "react-map-gl";
import SearchBox from "../maps/search_box";

import { HelpDrawerAndTab, HelpDrawerContentWrapper, HelpDrawerExplanatoryContentContainer, HelpDrawerExplanatoryContentText, HelpDrawerExplanatoryContentTitle, HelpDrawerVideoContainer } from "../HelpDrawer";

enum GetStartedMapStep {
    Search,
    Select,
    Modify
}

type StepAndEnum = {
    instructions: {
        title: string
        content: JSX.Element
    }
    enum: GetStartedMapStep
    footer?: JSX.Element
}
const steps: StepAndEnum[] = [
    {
        instructions: {
            title: 'Step 1 of 3',
            content: <BodyText>Search for your address, suburb or region</BodyText>
        },
        enum: GetStartedMapStep.Search
    },
    {
        instructions: {
            title: 'Step 2 of 3',
            content: <>
                <BodyText><span style={{fontWeight: 700}}>Click</span> on each section of your property boundaries until you’ve selected all of your land. <br/>If you make a mistake click on a section again to remove it.</BodyText>
            </>
        },
        enum: GetStartedMapStep.Select
    },
    {
        instructions: {
            title: 'Step 3 of 3',
            content: <BodyText>If required, refine your boundaries with the “Draw”, "Edit", or “Remove” tools above.</BodyText>
        },
        enum: GetStartedMapStep.Modify
    }
]

type GetStartedMapProps = {
    step: GetStartedStep;
    agriwebbFarmId?: string;
    initialGeom?: MultiPolygon;
    setPropertyBoundary: (property: MultiPolygon | undefined) => void;
    onSubmit: () => void;
    hideUpload?: boolean;
};

export default function GetStartedMap(props: GetStartedMapProps) {
    const [step, setStep] = useState<GetStartedMapStep>(props.initialGeom ? GetStartedMapStep.Select : GetStartedMapStep.Search);
    const selectedStep = steps.find(s => s.enum === step)

    const [suggestedBounds, setSuggestedBounds] = useState<BBox | undefined>(undefined);

    const [parsingStep, setParsingStep] = useState<ParsingStep>(ParsingStep.NotYetStarted);

    const parseShpFile = async (file: File) => {
        let shp = null
        if (file.name.includes('.zip')) {
            const zip = await JSZip.loadAsync(file)
            const fileNames = Object.keys(zip.files)
            const shpFiles = fileNames.filter(f => f.includes('.shp'))
            if (shpFiles.length === 0) throw Error('Could not find valid file in zip')
            const buffer = await zip.files[shpFiles[0]].async("arraybuffer")
            shp = new ShpToGeoJson({
                arraybuffers: {
                    shpBuffer: buffer
                }
            })
        } else if (file.name.includes('.shp')) {
            const ab = await file.arrayBuffer()
            shp = new ShpToGeoJson({
                arraybuffers: {
                    shpBuffer: ab
                }
            })
        }
        return shp.getGeoJson()
    }

    const parseKmlFile = async (file: File) => {
        const kmlText = await file.text()
        const xmlParser = new DOMParser();
        const asXML = xmlParser.parseFromString(kmlText, "text/xml")
        const gj = kml(asXML)
        return geojsonFlatten(gj)
    }

    const handleDroppedFile = async (file: File) => {
        try {
            let geojson: null | FeatureCollection<Polygon | MultiPolygon> | Feature<MultiPolygon | Polygon> = null
            if (file.name.includes('.geojson') || file.name.includes('.json')) {
                const txt = await file.text()
                geojson = JSON.parse(txt)
            } else if (file.name.includes('.kml')) {
                geojson = await parseKmlFile(file)
            } else if (file.name.includes('.zip') || file.name.includes('.shp')) {
                geojson = await parseShpFile(file)
            }
            if (geojson === null) {
                throw new Error('Could not import file')
            }
            if (!coordsAreInValidRange(geojson)) {
                setParsingStep(ParsingStep.Error)
                return
            }

            if ('features' in geojson) geojson.features = geojson.features.filter((f) => f.geometry.type === 'Polygon' || f.geometry.type === 'MultiPolygon')

            // Strip out any z/height values
            coordEach(geojson, function (currentCoord) {
                if (currentCoord.length > 2) currentCoord.length = 2
            })
            let mp: MultiPolygon | null = null
            if (geojson.type === 'FeatureCollection') {
                mp = convertFeatureCollectionToMultiPolygon(geojson)
            } else if (geojson.type === 'Feature') {
                if (geojson.geometry.type === 'MultiPolygon') mp = geojson.geometry
                if (geojson.geometry.type === 'Polygon') mp = convertPolygonsToMultiPolygon([geojson.geometry])
            }
            if (mp) {
                props.setPropertyBoundary(mp)
                setSuggestedBounds(calculateBoundingBoxFromGeojson(geojson))
            }
            setParsingStep(ParsingStep.Successful)
            setStep(GetStartedMapStep.Modify)
        } catch (err) {
            console.log(err)
            setParsingStep(ParsingStep.Error)
        }
    }

    const inputRef = useRef<HTMLInputElement>(null)
    async function getFile() {
        if (inputRef === null || inputRef.current === null) return
        inputRef.current.click()
        inputRef.current.addEventListener("change", () => {
            if (inputRef.current === null || inputRef.current.files === null) return
            if (inputRef.current.files.length > 0) handleDroppedFile(inputRef.current.files[0])
        });
    }

    function retryUpload() {
        setParsingStep(ParsingStep.NotYetStarted)
    }

    function instructionFooter (): JSX.Element | undefined {
        if (step === GetStartedMapStep.Search) return undefined
        else if (step === GetStartedMapStep.Select) {
            return <Row style={{justifyContent: 'flex-start'}}>
                <Column style={{marginRight: '5px'}}>
                    <SmallButton 
                    colorScheme="outline"
                    onClick={() => setStep(GetStartedMapStep.Modify)}
                    >
                        <>Draw my own</>
                    </SmallButton>
                </Column>
                <Column style={{marginRight: '5px'}}>
                    <SmallButton 
                    colorScheme="green"
                    // disabled={propertyBoundary === undefined}
                    onClick={() => setStep(GetStartedMapStep.Modify)}
                    >
                        <>Continue</>
                    </SmallButton>
                </Column>
                </Row>
        }
        else if (step === GetStartedMapStep.Modify) {
            return <Row style={{justifyContent: 'flex-start'}}>
                <Column style={{marginRight: '5px'}}>
                    <SmallButton 
                    colorScheme="outline"
                    onClick={() => {
                        setStep(GetStartedMapStep.Select)
                    }}
                    >
                        <>Go back</>
                    </SmallButton>
                </Column>
                <Column>
                    <SmallButton 
                    colorScheme="green"
                    disabled={props.initialGeom === undefined}
                    onClick={() => props.onSubmit()}
                    >
                        <>Done</>
                    </SmallButton>
                </Column>
            </Row>
        }
    }

    return (
        <MapProvider>
             {props.hideUpload !== true ?
                <>
                    <Wrapper style={{
                        marginBottom: '10px',
                        display: "flex",
                        justifyContent: "center",
                    }}>
                        {parsingStep === ParsingStep.NotYetStarted && (
                            <>
                                <ButtonText
                                    onClick={getFile}
                                    style={{
                                        fontSize: '15px',
                                        color: ruminatiColors.dark_green,
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}>
                                    <span style={{marginRight: "6px"}}>Already have a map file for your farm?</span> 
                                    <span style={{marginTop: "2px"}}><Icon icon="cloud-upload"/></span>
                                    <span style={{marginLeft: "6px", borderBottom: `1px solid ${ruminatiColors.dark_green}`}}>Upload here</span>
                                    <input type="file" id="fileUploader" ref={inputRef} style={{ display: "none" }} accept=".zip,.kml,.kmx,.json,.shp,.geojson" />
                                </ButtonText>
                            </>
                        )}
                        {parsingStep === ParsingStep.Successful && (
                            <BaseText
                                style={{
                                    fontSize: '15px',
                                    color: ruminatiColors.dark_green,
                                }}>
                                Existing property boundary successfully loaded!
                            </BaseText>
                        )}
                        {parsingStep === ParsingStep.Error && (
                            <>
                                <BaseText
                                    style={{
                                        fontSize: '15px',
                                        color: ruminatiColors.dark_green,
                                    }}>
                                    Could not import that file sorry
                                </BaseText>
                                <BaseText
                                    style={{
                                        fontSize: '15px',
                                        color: ruminatiColors.dark_green,
                                        cursor: 'pointer',
                                        marginLeft: '10px',
                                        fontWeight: 800
                                    }}
                                    onClick={retryUpload}
                                >Retry</BaseText></>
                        )}
                    </Wrapper>
                </>
                : undefined
            }
            <Row style={{ position: "relative", width: "100%" }}>
                <Column style={{width: '100%'}}>
                    <AreaSelect
                        height={464}
                        initialGeom={props.initialGeom}
                        suggestedBounds={suggestedBounds}
                        onPropertyBoundaryChanged={(property) => {
                            if (step === GetStartedMapStep.Search) {
                                setStep(GetStartedMapStep.Select)
                            }
                            props.setPropertyBoundary(property)
                        }}
                        showEditOverlay={step === GetStartedMapStep.Modify}
                    />

                    <SearchBox 
                      onSelect={() => {
                        setStep(GetStartedMapStep.Select)
                      }}
                    />

                    <InstructionOverlay 
                        title={(selectedStep as StepAndEnum).instructions.title}
                        content={(selectedStep as StepAndEnum).instructions.content}
                        footer={instructionFooter()}
                    />
                </Column>               
            </Row>
            <HelpDrawerAndTab
                renderContent={() => {
                    return (
                        <HelpDrawerContentWrapper>
                            <HelpDrawerExplanatoryContentContainer>
                                <HelpDrawerExplanatoryContentTitle>Mapping Your Property</HelpDrawerExplanatoryContentTitle>
                                <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                    <HelpDrawerExplanatoryContentText>
                                        Map your property on this page. Enter your address then add in your property boundaries. Click to select parcels of land, or click again to remove them. You can also edit your boundaries using the drawing tool.
                                    </HelpDrawerExplanatoryContentText>
                                    <HelpDrawerExplanatoryContentText>
                                        <strong>Ruminati Tip:</strong> If you already have a map file you can upload it using the link above the map window.
                                    </HelpDrawerExplanatoryContentText>
                                </div>
                            </HelpDrawerExplanatoryContentContainer>
                            <HelpDrawerVideoContainer>
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/fkKBPlsI4jY?si=pzDnfUbAJ-eQ9z70&loop=1&rel=0" title="Ruminati Help" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                            </HelpDrawerVideoContainer>
                        </HelpDrawerContentWrapper>
                    )
                }}
            />
        </MapProvider>
    );
}
