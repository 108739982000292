import ReactGA from "react-ga4";
import dayjs from "dayjs";
import styled from "styled-components";

import DseBeefCalculator from './dse/beef-calculator'
import DseSheepCalculator from './dse/sheep-calculator'
import { convertDSEtoAE } from "../../utilities/dse";
import { getEnvFromUrl } from "../../utilities/env";

export type DSECalculatorType =
    | "beef-dse"
    | "beef-ae"
    | "sheep-dse"
    | "sheep-ae";

export function isCalculatorType(val: string) {
    return ["beef-dse", "beef-ae", "sheep-dse", "sheep-ae"].includes(val);
}

const isDSE = (type: DSECalculatorType): boolean =>
    type === "beef-dse" || type === "sheep-dse";

const isBeef = (type: DSECalculatorType): boolean =>
    type === "beef-ae" || type === "beef-dse";

const isAE = (type: DSECalculatorType): boolean =>
    type === "beef-ae" || type === "sheep-ae";


function sendGoogleAnalyticsEvent (startTime: number, dseType: string) {
    const timeTaken = dayjs(Date.now()).diff(startTime, 'second')
    if (getEnvFromUrl() === 'production') {
        ReactGA.event({
            category: "dse_calculator",
            action: dseType,
            value: timeTaken,
            nonInteraction: true,
        });
    }
}

/**
 * The DSE Calculator form, intended to be shown inside a popup
 */
export default function DSECalculator({
    type,
    onSubmit,
    metadata
}: {
    type: DSECalculatorType;
    onSubmit: (val: number) => void;
    metadata: any;
}) {
    const startTime = Date.now()
    return (
        <div style={{ padding: "32px" }}>
        <ScrollableBody>
            {isBeef(type) ?
                <DseBeefCalculator
                    onSubmit={(dse: number) => {
                        onSubmit(isDSE(type) ? dse : convertDSEtoAE(dse))
                        sendGoogleAnalyticsEvent(startTime, type)
                    }}
                    metadata={metadata}
                    isAE={isAE(type)}
                /> :
                <DseSheepCalculator
                    onSubmit={(dse: number) => {
                        onSubmit(isDSE(type) ? dse : convertDSEtoAE(dse))
                        sendGoogleAnalyticsEvent(startTime, type)
                    }}
                    metadata={metadata}
                    isAE={isAE(type)}
                />
            }
            </ScrollableBody>
        </div>
    );
}

const ScrollableBody = styled.div`
    padding: 20px;
    overflow-y: auto;
    flex: 1;
    max-height: calc(100vh - 300px);
    width: 100%;
`;