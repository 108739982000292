import { FormData } from "../../../../models/form/form";
import { getCurrentFinancialYear } from "../../../../utilities/dates";
import { InitiativePopUp } from "../initiative_popup";
import { ManageLivestockClassesProductionDTO } from "../../../../models/reduction_plans_models";
import { Report } from "../../../../models/property_models";
import { useDatabaseStore } from "../../../../state/database_store";
import { SliderFormField } from "../../../../models/form/form_fields/basic_form_fields";
import { getYearField, getDefaultReductionPlanYearOptions, addExistingReductionPlanYearsToOptions } from "../initiative_helpers";
import { FieldSize } from "../../../../models/form/form_fields/form_field";

interface LivestockFormData {
    cattleHeadcountChange?: number;
    beefProduction?: number;
    beefTargetYear?: number;
    sheepHeadcountChange?: number;
    sheepProduction?: number;
    sheepTargetYear?: number;
}

export default function ManageLiveStockClassProductionPopUp(props: {
    initialData?: ManageLivestockClassesProductionDTO;
    compareReport?: Report;
    onSubmit: (data: ManageLivestockClassesProductionDTO) => Promise<void>
}) {
    const properties = useDatabaseStore((state) => state.properties);
    const property = (properties ?? []).find((p) => p.id === props.compareReport?.propertyId);

    const yearOptions = getDefaultReductionPlanYearOptions()
    const existingReductionPlanYears = [
        props.initialData?.cattleHeadcountChange?.targetYear,
        props.initialData?.sheepHeadcountChange?.targetYear,
    ].filter(v => v !== undefined) as number[]
    addExistingReductionPlanYearsToOptions(existingReductionPlanYears, yearOptions)

    const initialData: ManageLivestockClassesProductionDTO = {
        cattleHeadcountChange: props.initialData?.cattleHeadcountChange ?? {
            targetValue: 0,
            targetYear: getCurrentFinancialYear()
        },
        beefProduction: props.initialData?.beefProduction ?? props.compareReport?.productionInformation?.cattleLiveweightSoldKgs ?? 0,
        sheepHeadcountChange: props.initialData?.sheepHeadcountChange ?? {
            targetValue: 0,
            targetYear: getCurrentFinancialYear()
        },
        sheepProduction: props.initialData?.sheepProduction ?? props.compareReport?.productionInformation?.sheepLiveweightSoldKgs ?? 0
    }

    const formData: FormData<LivestockFormData, ManageLivestockClassesProductionDTO> = {
        fields: {
            ...(property?.cattleFarm
                ? {
                  cattleHeadcountChange: new SliderFormField({
                        required: true,
                        sliderProps: {
                            defaultRange: { min: -100, max: 100 },
                            limits: { min: -100, max: 100 },
                            prevReportValue: 0,
                            initialValue: initialData.cattleHeadcountChange ? initialData.cattleHeadcountChange?.targetValue * 100 : 0,
                            unitLabel: '%',
                            label: 'Average Cattle Headcount Change',
                        },
                    }),
                    beefProduction: new SliderFormField({
                        required: true,
                        sliderProps: {
                            defaultRange: { min: 0, max: 100000000000000 },
                            prevReportValue: props.compareReport?.productionInformation?.cattleLiveweightSoldKgs ?? 0,
                            initialValue: initialData.beefProduction ?? 0,
                            unitLabel: "kg liveweight",
                            label: "Total Beef Sales (kg liveweight)",
                        },
                    }),
                    beefTargetYear: getYearField('Year of completion', FieldSize.Full, yearOptions),
                } : {}),
            ...(property?.sheepFarm
                ? {
                    sheepHeadcountChange: new SliderFormField({
                        required: true,
                        sliderProps: {
                            defaultRange: { min: -100, max: 100 },
                            limits: { min: -100, max: 100 },
                            prevReportValue: 0,
                            initialValue: initialData.sheepHeadcountChange ? initialData.sheepHeadcountChange?.targetValue * 100 : 0,
                            unitLabel: '%',
                            label: 'Average Sheep Headcount Change',
                        },
                    }),
                    sheepProduction: new SliderFormField({
                        required: true,
                        sliderProps: {
                            defaultRange: { min: 0, max: 100000000000000 },
                            prevReportValue: props.compareReport?.productionInformation?.sheepLiveweightSoldKgs ?? 0,
                            initialValue: initialData.sheepProduction ?? 0,
                            unitLabel: "kg liveweight",
                            label: "Total Sheep Meat Sales (kg liveweight)",
                        },
                    }),
                    sheepTargetYear: getYearField('Year of completion', FieldSize.Full, yearOptions),
                } : {})
        },
        transformer: {
            in: (value) => {
                return {
                    cattleHeadcountChange: value.cattleHeadcountChange ? (value.cattleHeadcountChange.targetValue * 100) : 0,
                    beefProduction: value.beefProduction ?? 0,
                    beefTargetYear: value.cattleHeadcountChange?.targetYear,
                    sheepHeadcountChange: value.sheepHeadcountChange ? (value.sheepHeadcountChange.targetValue * 100) : 0,
                    sheepProduction: value.sheepProduction ?? 0,
                    sheepTargetYear: value.sheepHeadcountChange?.targetYear,                   
                }
            },
            out: (value) => ({
                 ...value.beefTargetYear && value.cattleHeadcountChange ? {
                    cattleHeadcountChange: {
                        targetValue: value.cattleHeadcountChange ? (value.cattleHeadcountChange / 100) : 0,
                        targetYear: value.beefTargetYear
                    },
                    beefProduction: value.beefProduction ?? 0,
                } : {},
                ...value.sheepTargetYear && value.sheepHeadcountChange !== 0 ? {
                    sheepHeadcountChange: {
                        targetValue: value.sheepHeadcountChange ? (value.sheepHeadcountChange / 100) : 0,
                        targetYear: value.sheepTargetYear
                    },
                    sheepProduction: value.sheepProduction ?? 0,
                } : {}
            })
        }
    };
    const isSheepAndBeef = property?.cattleFarm && property.sheepFarm
    const typeText = `${property?.cattleFarm ? 'Beef' : ''}${isSheepAndBeef ? ' or ' : ''}${property?.sheepFarm ? 'Sheep' : ''}`
    return (
        <>
        <InitiativePopUp
            formData={formData}
            initialFormValues={initialData}
            onSubmit={(state) => props.onSubmit(state)}
            infoAbove={`Modifying Total ${typeText} Sales won't change your Total Emissions, it will however effect your Emissions Intensity metrics.`}
        />
    </>);
}
