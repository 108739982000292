import { FeatureCollection, Polygon, MultiPolygon } from 'geojson';
import { CreatePropertyDTO, Property, Report } from '../models/property_models';
import { ReportTrees } from '../models/report'
import { baseUrl, deleteHttp, get, patch, post } from './api_service';
import { getTokens } from './auth_service';
import { CountryCode } from '../utilities/countries';

export const baseGeoUrl = `${baseUrl}/geo`;
export const basePropertyUrl = `${baseUrl}/properties`;
export const baseReportUrl = `${baseUrl}/reports`;

export type PlaceSuggestionPrediction = {
    description: string
    place_id: string 
} 

type PlaceSuggestionResponse = {
    status: string
    predictions: PlaceSuggestionPrediction[]
} 

type GoogleLatLong = {
    lat: number
    lng: number
}

export type Place = {
    address_components: {
        long_name: string
        short_name: string,
        types: string[]
    }[],
    adr_address: string
    formatted_address: string,
    geometry: {
        location: GoogleLatLong,
        viewport: {
            northeast: GoogleLatLong,
            southwest: GoogleLatLong
        }
    },
    icon: string
    icon_background_color: string,
    icon_mask_base_uri: string
    name: string
    place_id: string,
    reference: string,
    types: string[],
    url: string,
    utc_offset: number,
    vicinity: string
}

type PlaceResult = {
    html_attributions: [],
    result: Place
    status: string
}

/**
 * Retrieves the suggestions for a particular place
 * @param value the current text input
 * @returns a promise resolving with suggestions
 */
export async function getPlaceSuggestions(value: string, country: CountryCode): Promise<PlaceSuggestionPrediction[]> {

    const response: PlaceSuggestionResponse | null = await post(`${baseGeoUrl}/places_autocomplete`, {
        input: value,
        country
    }, getTokens().token);

    if (response && "predictions" in response) {
        if (response.predictions.length > 3) return response.predictions.slice(0, 3)
        return response.predictions;
    }

    return [];
}

/**
 * Retrieves the location for a a particular place
 * @param value the id for a place 
 * @returns a promise resolving with the location
 */
export async function getPlace(value: string): Promise<Place | undefined> {
    const response: PlaceResult | null = await post(`${baseGeoUrl}/get_place`, { id: value }, getTokens().token);

    if (response && "result" in response) {
        return response.result;
    }

    return undefined;
}

/**
 * Retrieves the bounds for a a particular place
 * @param lat the latitude of the click
 * @param lng the longitude of the click
 * @returns a promise resolving with the bounds
 */
export async function getPropertyBoundary(lat: number, lng: number, country: CountryCode): Promise<FeatureCollection<Polygon|MultiPolygon> | undefined> {
    const response: FeatureCollection<Polygon|MultiPolygon> | null = await post(`${baseGeoUrl}/get_property_boundary`, { lat, lng, country }, getTokens().token);

    if (response) {
        return response;
    }

    return undefined;
}

/**
 * Creates a property on the backend
 * @param property the property data to create
 * @returns a promise that resolves with the new property created
 */
export async function createProperty(property: CreatePropertyDTO): Promise<Property | undefined> {
    const response = await post(`${basePropertyUrl}`, property, getTokens().token);
    return Property.fromJSON(response) ?? undefined;
}

/**
 * Updates a property
 * @param propertyId the id of the property to update
 * @param updates the property data to update
 * @returns a promise that resolves with the updated property
 */
export async function updateProperty(propertyId: string, updates: CreatePropertyDTO): Promise<Property | undefined> {
    const response = await patch(`${basePropertyUrl}/${propertyId}`, updates, getTokens().token);
    return Property.fromJSON(response) ?? undefined
}

/**
 * Creates a property on the backend
 * @param property the property data to create
 * @returns a promise resolving to a boolean of success
 */
export async function createReport(propertyId: string, financialYear: number): Promise<Report | undefined> {
    const response = await post(`${baseReportUrl}`, {
        propertyId: propertyId,
        financialYear: financialYear
    }, getTokens().token);

    return response ? Report.fromJSON(response) ?? undefined : undefined;
}

/**
 * Return a list of the user's properties
 * @returns a promise resolving to a list of properties
 */
export async function getProperties(): Promise<Property[]> {
    const response = await get(`${basePropertyUrl}/`, getTokens().token);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const out: Property[] = []
    if (!response.properties) return out
    response.properties.forEach((p: any) => {
        const property = Property.fromJSON(p)
        if (property) out.push(property)
    });
    return out
}

/**
 * Return an individual property
 * @returns a promise resolving to a property or null
 */
export async function getProperty(propertyId: string): Promise<Property | null> {
    const response = await get(`${basePropertyUrl}/${propertyId}`, getTokens().token);
    if (response) return Property.fromJSON(response)
    return null
}

/**
 * Deletes a single property in the database
 * @param propertyId the id of the report to delete
 * @returns a promise resolving with the boolean success
 */
export async function deleteProperty(propertyId: string): Promise<boolean> {
    const response = await deleteHttp(`${basePropertyUrl}/${propertyId}`, getTokens().token);
    return !!response;
}

/**
 * Return an individual property
 * @returns a promise resolving to a property or null
 */
export async function getPropertyTrees(propertyId: string): Promise<ReportTrees[] | null> {
    const response = await get(`${basePropertyUrl}/${propertyId}/trees`, getTokens().token);
    if (response) return response
    return null
}

/**
 * Return a list of the user's properties
 * @returns a promise resolving to a list of properties
 */
export async function getReports(): Promise<Report[]> {
    const response = await get(`${baseReportUrl}/`, getTokens().token);
    return (response ?? []).map((r: any) => Report.fromJSON(r));
}

/**
 * Deletes a single report in the database
 * @param reportId the id of the report to delete
 * @returns a promise resolving with the boolean success
 */
export async function deleteReport(reportId: string): Promise<boolean> {
    const response = await deleteHttp(`${baseReportUrl}/${reportId}`, getTokens().token);

    return !!response;
}

/**
 * Updates the report with the given data
 * @param reportId the id of the report to update
 * @returns a promise resolving with the boolean success
 */
export async function patchReport(reportId: string, data: Partial<Report>): Promise<Report | undefined> { // TODO: fix types here
    const response = await patch(`${baseReportUrl}/${reportId}`, data, getTokens().token);
    return Report.fromJSON(response) ?? undefined;
}